import useStudio from "@/data/studio-react/useStudio";
import { ArrowPathIcon, HashtagIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Customer } from "types";

interface BidderNumberPickerProps {
  customer: Customer | null;
  className?: string;
}

/**
 * BidderNumberPicker component allows operators to manage a customer's bidder number.
 *
 * Features:
 * - Delete a bidder number
 * - Generate a random bidder number (sticky between sales)
 * - Manually enter a bidder number
 * - Validation to ensure the number isn't already in use
 */
export function BidderNumberPicker({
  customer,
  className = "",
}: BidderNumberPickerProps) {
  const { setBidderNumber, checkBidderNumberIsUnique } = useStudio();
  const attemptRef = useRef(0);

  const [loading, setLoading] = useState(false);
  const [localBidderNumber, setLocalBidderNumber] = useState<number | null>(
    customer?.bidderNumber || null
  );
  const [isFocused, setIsFocused] = useState(false);

  // Determine if the local value differs from the stored value
  const isDirty = localBidderNumber !== customer?.bidderNumber;

  // Update local state when customer changes and we're not in the middle of editing
  useEffect(() => {
    if (!loading && !isFocused && customer) {
      setLocalBidderNumber(customer.bidderNumber || null);
    }
  }, [customer, loading, isFocused]);

  // Handle changes to the input field
  const handleChange = (value: string) => {
    if (value === "") {
      setLocalBidderNumber(null);
      return;
    }

    // Only allow numeric values
    const intValue = parseInt(value);
    if (isNaN(intValue)) return;

    // Only allow max of 5 digits
    if (intValue > 99999) return;

    setLocalBidderNumber(intValue);
  };

  // Save the bidder number
  const saveBidderNumber = async () => {
    if (!customer || !isDirty) return;

    setLoading(true);
    try {
      if (localBidderNumber !== null) {
        // Check if the bidder number is unique before saving
        if (await checkBidderNumberIsUnique(localBidderNumber)) {
          await setBidderNumber(customer.id, {
            bidderNumber: localBidderNumber,
          });
        } else {
          setLocalBidderNumber(customer.bidderNumber || null);
        }
      } else {
        // Clear the bidder number
        await setBidderNumber(customer.id, {
          bidderNumber: null,
        });
      }
    } catch (e) {
      console.error("Error updating bidder number:", e);
    } finally {
      setLoading(false);
    }
  };

  // Generate a new bidder number
  const generateBidderNumber = async () => {
    if (!customer) return;

    setLoading(true);
    try {
      const number = await setBidderNumber(customer.id, {
        attempt: attemptRef.current,
      });
      attemptRef.current++;
      setLocalBidderNumber(number);
    } catch (e) {
      console.error("Error generating bidder number:", e);
    } finally {
      setLoading(false);
    }
  };

  // Handle key press events
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.currentTarget.blur();
      saveBidderNumber();
    }
  };

  if (!customer) return null;

  return (
    <div
      className={classNames(
        "rounded-md bg-gray-50 p-3 group relative",
        className
      )}
    >
      <h3 className="text-sm font-medium text-gray-500 mb-2">
        <span className="flex items-center">
          <HashtagIcon className="mr-2 h-4 w-4 text-martEye-500" />
          Bidder Number
        </span>
      </h3>
      <div className="relative">
        <input
          type="text"
          className={classNames(
            "block w-full rounded-md border-0 py-1.5 px-3 text-gray-900",
            "ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-martEye-500",
            "sm:text-sm sm:leading-6 bg-gray-50",
            loading ? "bg-gray-100 cursor-wait" : ""
          )}
          placeholder="No bidder number"
          value={localBidderNumber === null ? "" : localBidderNumber.toString()}
          disabled={loading}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
            saveBidderNumber();
          }}
          onKeyDown={handleKeyDown}
        />
        <button
          type="button"
          className={classNames(
            "absolute right-2 top-1/2 transform -translate-y-1/2 p-1 rounded-full hover:bg-gray-200 transition-colors",
            loading ? "cursor-wait" : "cursor-pointer"
          )}
          onClick={generateBidderNumber}
          disabled={loading}
          title="Generate bidder number"
        >
          <ArrowPathIcon
            className={classNames(
              "h-4 w-4 text-gray-400",
              loading ? "animate-spin" : "hover:text-martEye-600"
            )}
            aria-hidden="true"
          />
        </button>
      </div>
      <p className="mt-1 text-xs text-gray-500">{isDirty ? "" : ""}</p>
    </div>
  );
}
