import React from "react";
import { PhotoView } from "react-photo-view";
import { Media } from "types";
import { mediaVariantSelector } from "@/data/media/media.helpers";
import "react-photo-view/dist/react-photo-view.css";
import { Spinner } from "../Buttons";

export function MediaViewerWrapper({
  children,
  media,
  mediaList,
  currentIndex = 0,
}: {
  media: Media;
  children: JSX.Element;
  mediaList?: Media[];
  currentIndex?: number;
}) {
  const selectImageVariant = mediaVariantSelector(media, "compressed");
  const largeVariant = mediaVariantSelector(media, "large");

  if (!mediaList || mediaList.length === 0) {
    return (
      <PhotoView src={largeVariant?.url || selectImageVariant?.url || ""}>
        {children}
      </PhotoView>
    );
  }

  return (
    <>
      {mediaList.map((m, index) => {
        const isImage =
          m?.fileType?.includes("png") ||
          m?.fileType?.includes("jpg") ||
          m?.fileType?.includes("jpeg") ||
          m?.fileType?.includes("heic") ||
          m?.fileType?.includes("heif");

        let variant = mediaVariantSelector(m, isImage ? "large" : "compressed");

        return (
          <PhotoView
            key={m.url}
            width={window.innerWidth}
            height={window.innerHeight}
            render={({ attrs }) => {
              return (
                <div
                  className="flex justify-center items-center"
                  style={{
                    ...attrs.style,
                  }}
                >
                  <div
                    className="flex items-center justify-center h-full w-full p-10"
                    style={{
                      height: window.innerHeight * 0.8,
                      width: window.innerWidth * 0.8,
                    }}
                  >
                    {variant?.isProcessing && (
                      <div className="text-white flex flex-col items-center justify-center gap-2">
                        <Spinner />
                        Media is processing
                      </div>
                    )}
                    {variant?.type === "image" && !variant?.isProcessing && (
                      <img
                        src={variant?.url}
                        alt={m.fileName}
                        className="w-full h-full object-contain"
                      />
                    )}
                    {variant?.type === "video" && !variant?.isProcessing && (
                      <>
                        <video controls>
                          <source src={variant?.url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </>
                    )}
                  </div>
                </div>
              );
            }}
          >
            {currentIndex === index ? children : undefined}
          </PhotoView>
        );
      })}
    </>
  );
}
