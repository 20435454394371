import { serverTimestamp } from "firebase/firestore";
import { Customer, ShortCustomerDetails } from "types";

/**
 * Returns the customer name, defaulting to a fallback if not available
 * @param customer
 * @returns The customer name as a `string`
 */

export function getDisplayName(customer: Customer | null): string;
export function getDisplayName(
  customer: Customer | null,
  defaultValue: string | null
): string | null;
export function getDisplayName(
  customer: Customer | null,
  defaultValue: string | null = "MISSINGNO"
): string | null {
  if (!customer) {
    return defaultValue;
  }

  if (customer.displayName) {
    return customer.displayName.trim();
  }

  return defaultValue;
}

export function toShortCustomerDetails(
  customer: Customer | null
): ShortCustomerDetails {
  if (!customer) {
    return {
      isSet: false,
    };
  }

  let result = {
    isSet: true,
    id: customer.id,
    copiedInAt: serverTimestamp(),
    accountNumber: customer.accountNumber,
    ...(customer.bidderNumber && {
      bidderNumber: customer.bidderNumber,
    }),

    displayName: getDisplayName(customer),
    avatar: customer.photoURL ?? null,
  };

  return result;
}
