import { DocumentSpec, InvoicePDFProps } from "../../_importDocs";

// JS PDF
import { jsPDF } from "jspdf";
import { Market, SuperType } from "types";

// Markets
import defaultInvoice from "./markets/default";
import nortonandbrooksbankInvoice from "./markets/nortonandbrooksbank";

const InvoiceDoc: DocumentSpec = {
  // must be unique
  id: "invoice",
  objectType: "invoice",

  // displayed to the user
  name: "Invoice",

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    let { invoice, marketId } = props;
    let invoiceNumber =
      invoice.status === "draft" ? "Draft" : invoice.invoiceNumber;
    let filename = `invoice-${marketId}-${invoiceNumber}`;
    filename = filename.replace(/ /g, "-").replace(/[^a-zA-Z0-9-]/g, "");
    return `${filename}`;
  },

  // return true if this document is available for a market to generate
  isAvailableFor: (market: Market, superTypes: SuperType[]) => {
    return true;
  },
  // the document template
  jsPDF: (props: InvoicePDFProps) => {
    let { market } = props;

    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      compress: true,
      unit: "mm",
      format: "a4",
    });

    switch (market.id) {
      case "nortonandbrooksbank":
        return nortonandbrooksbankInvoice(doc, props);

      default:
        return defaultInvoice(doc, props);
    }
  },
};

export default InvoiceDoc;
