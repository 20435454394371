/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    "./app/**/*.{js,ts,jsx,tsx}", // Note the addition of the `app` directory.
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./components/**/*.{js,ts,jsx,tsx}",
    "./node_modules/react-tailwindcss-datepicker/dist/index.esm.js",
    "./node_modules/tailwind-datepicker-react/dist/**/*.js",
  ],
  safelist: [
    {
      pattern: /grid-(cols|rows)-\d+/,
    },
    {
      pattern: /(col|row)-(start|span)-\d+|col-span-full/,
    },
  ],
  theme: {
    extend: {
      zIndex: {
        60: "60",
        70: "70",
        80: "80",
        90: "90",
        100: "100",
      },
      screens: {
        xs: "480px",
        "2xl": "1440px",
        "3xl": "1600px",
        HD: "1280px",
        FHD: "1920px",
        "4K": "3840px",
        uw: "3440px",
      },

      fontFamily: {
        sans: ["Inter", "sans-serif"],
        displayBoard: ["Jockey One", "sans-serif"],
      },
      colors: {
        navbg: "#162F27",
        navTabActivebg: "#294F43",
        navTabIconActiveRostrum: "#497D5C",
        navTabIconActive: "#DFF3E0",
        navTabIcon: "#A9DA75",
        headerbg: "#0E1513",
        activetabbg: "#DFF3E0",
        tabbg: "#283A35",
        tabhoverbg: "#3C504A",
        pitchPay: {
          mint: "#00E9A8",
          "mint-dark": "#08B785",
          green: "#0F5744",
          forest: "#093F30",
          "forest-dark": "#143528",
        },
        martEye: {
          DEFAULT: "#4BA36A",
          50: "#F3FFF4",
          100: "#dff3e0",
          200: "#a9da75",
          300: "#79dba0",
          400: "#4ba36a",
          500: "#294F43",
          600: "#162f27",
          700: "#0e1513",
        },
        danger: {
          DEFAULT: "#ffe5e5",
          100: "#ffeaea",
          200: "#ffe5e5",
          300: "#ff6d6d",
          400: "#de696a",
          500: "#f44336",
          600: "#5b1e1e",
        },
        warning: {
          DEFAULT: "#ffb800",
          500: "#ffb800",
          200: "#FFFDC9",
          50: "#FFFEF1",
        },

        yellow: {
          DEFAULT: "#FFF27E",
          500: "#FFF27E",
          600: "#F0E154",
        },
        grey: {
          DEFAULT: "#efefef",
          100: "#efefef",
          200: "#cbcbcb",
          300: "#a3b0ad",
          400: "#989898",
          500: "#616161",
        },
      },
    },
  },
  plugins: [
    require("@tailwindcss/forms"),
    require("tailwind-scrollbar"),
    require("@tailwindcss/container-queries"),
  ],
};
