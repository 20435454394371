import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { Timestamp } from "firebase/firestore";
import { useEffect, useReducer, useState } from "react";
import {
  Customer,
  Invoice,
  Payment,
  Payout,
  Transaction,
  TxnReferenceType,
} from "../../../../types";

export interface Entry {
  id: string;
  date: Timestamp;
  amountInCents: number | null;
  balanceInCents?: number | null;
  description: string;
  link: string | null;
  type: TxnReferenceType | "statement";
  lotSummary?: string; // Summary of lots sold for invoices and statements
  paymentSource?: "pitchpay" | "bank" | "cash" | "cheque"; // Source of the payment
  paymentMethod?: "cheque" | "BACS" | "pitch" | "cash" | "card"; // Method of payment

  resource: Invoice | Payment | Payout | null;
  transactions: Transaction[] | null;
  isCorrect: boolean; // if the transactions match the resource
}

interface UseLedgerDataProps {
  customer: Customer | null;
  marketId: string;
}

interface LedgerData {
  entries: Entry[];
  isLoading: boolean;
  error: Error | null;
}

export function useLedgerData({
  customer,
  marketId,
}: UseLedgerDataProps): LedgerData {
  let [entries, setEntries] = useState<Entry[]>([]);
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [error, setError] = useState<Error | null>(null);

  let currentBalance = useCurrentBalance(marketId, customer?.id);

  let ledgerEntries = useLedgerEntries(marketId, customer?.id);

  useEffect(() => {
    // This simulates an API call to fetch the data
    let loadData = async () => {
      try {
        setIsLoading(true);

        // In a real implementation, this would be replaced with an actual API call
        // using the customer ID and market ID to fetch the relevant data
        // For now, we'll use a timeout to simulate network delay
        await new Promise((resolve) => setTimeout(resolve, 300));

        // Use the dummy data - cast to Entry[] type to ensure type safety
        // setEntries(dummyData.entries as Entry[]);
        setError(null);
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("Unknown error occurred")
        );
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [customer?.id, marketId]); // Re-fetch when customer or market changes

  return {
    entries,
    isLoading,
    error,
  };
}

function useCurrentBalance(
  marketId: string | null,
  customerId: string | null | undefined
) {
  let cxTrdReceivable =
    useStudioStream("balance", marketId, {
      account: customerId && `${customerId}:asset:trade receivable`,
    }) || 0;

  let cxTrdPayable =
    useStudioStream("balance", marketId, {
      account: customerId && `${customerId}:liability:trade payable`,
    }) || 0;

  let customerBalances =
    cxTrdReceivable &&
    cxTrdPayable &&
    !cxTrdReceivable.loading &&
    !cxTrdPayable.loading
      ? {
          tradeReceivable: cxTrdReceivable.data!,
          tradePayable: cxTrdPayable.data!,
        }
      : null;

  let currentBalance =
    (customerBalances?.tradeReceivable ?? 0) +
    (customerBalances?.tradePayable ?? 0);

  return currentBalance;
}

/*
 * Here we create the entries but we also do a validation check, ensuring that the transactions match the invoices / payments / payouts.
 *
 * Load in first 500 transactions and use those to set the to/from dates
 *
 * Then load in the matching invoices / payments / payouts for those dates
 *
 * Match up the transactions to the their resources.
 *
 * If after everything is loaded there are:
 *  - transactions missing resources
 *  - resources missing transactions
 *  - Sum total of transactions that doesn't match the resource amount
 *
 * Then we show an (IFAM) error to the user. This can serve as an ongoing visual check that everything is in sync
 *
 * Note there are valid Transactions that don't have a matching resource:
 *  - Starting balance
 *  - Adjustments
 *  -
 *
 */
export function useLedgerEntries(
  marketId: string | null,
  customerId: string | null | undefined
) {
  let [state, dispatch] = useReducer(reducer, initialState);

  let txnsInfo = useStudioStream("transactions", marketId, {
    owner: customerId,
    count: 500,
    // offset:  todo
    accountName: ["trade payable", "trade receivable"],
  });

  useEffect(() => {
    dispatch({
      type: "set",
      item: { txns: txnsInfo.data, hasLoadedTransactions: txnsInfo.hasLoaded },
    });
  }, [txnsInfo.data, txnsInfo.loading, txnsInfo.hasLoaded]);

  let shouldLoad = state.from && state.to;

  let invoicesInfo = useStudioStream(
    "invoices-info",
    shouldLoad ? marketId : null,
    {
      customerUid: customerId,
      from: state.from,
      to: state.to,
    }
  );

  useEffect(() => {
    dispatch({
      type: "set",
      item: {
        invoices: invoicesInfo.data,
        hasLoadedInvoices: invoicesInfo.hasLoaded,
      },
    });
  }, [invoicesInfo.data, invoicesInfo.loading, invoicesInfo.hasLoaded]);

  let paymentsInfo = useStudioStream(
    "payments-info",
    shouldLoad ? marketId : null,
    {
      customerUid: customerId,
      from: state.from,
      to: state.to,
    }
  );

  useEffect(() => {
    dispatch({
      type: "set",
      item: {
        payments: paymentsInfo.data,
        hasLoadedPayments: paymentsInfo.hasLoaded,
      },
    });
  }, [paymentsInfo.data, paymentsInfo.loading, paymentsInfo.hasLoaded]);

  let payoutsInfo = useStudioStream(
    "payouts-info",
    shouldLoad ? marketId : null,
    {
      customerUid: customerId,
      from: state.from,
      to: state.to,
    }
  );

  useEffect(() => {
    dispatch({
      type: "set",
      item: {
        payouts: payoutsInfo.data,
        hasLoadedPayouts: payoutsInfo.hasLoaded,
      },
    });
  }, [payoutsInfo.data, payoutsInfo.loading, payoutsInfo.hasLoaded]);

  return state.entries;
}

const initialState: LedgerState = {
  entries: [],
  hasLoadedTransactions: false,
  hasLoadedInvoices: false,
  hasLoadedPayments: false,
  hasLoadedPayouts: false,
  invoices: [],
  payments: [],
  payouts: [],
  txns: [],
  from: null,
  to: null,

  batches: {},
};

interface InvoiceBatchType {
  key: string;
  txns: Transaction[];
  object: Invoice | null;
  type: "invoice";
  amountInCents: number;
}

interface PaymentBatchType {
  key: string;
  txns: Transaction[];
  object: Payment | null;
  type: Extract<TxnReferenceType, "payment">;
  amountInCents: number;
}

interface PayoutBatchType {
  key: string;
  txns: Transaction[];
  object: Payout | null;
  type: Extract<TxnReferenceType, "payout">;
  amountInCents: number;
}

interface NonObjectBatchType {
  key: string;
  txns: Transaction[];
  type: Omit<TxnReferenceType, "invoice" | "payment" | "payout">;
  amountInCents: number;
}

type BatchType =
  | InvoiceBatchType
  | PaymentBatchType
  | PayoutBatchType
  | NonObjectBatchType;

interface LedgerState {
  entries: Entry[];

  hasLoadedTransactions: boolean;
  hasLoadedInvoices: boolean;
  hasLoadedPayments: boolean;
  hasLoadedPayouts: boolean;

  invoices: Invoice[];
  payments: Payment[];
  payouts: Payout[];
  txns: Transaction[];

  batches: {
    [key: string]: BatchType;
  };

  from: Timestamp | null;
  to: Timestamp | null;
}

interface LedgerAction {
  type: "set";
  item: Partial<LedgerState>;
}
export function reducer(state: LedgerState, action: LedgerAction): LedgerState {
  let newState = { ...state };
  switch (action.type) {
    case "set":
      newState = { ...state, ...action.item };
      break;
  }

  // Work out the dates
  if (newState.txns.length > 0) {
    let dates = newState.txns
      .map((t) => t.createdAt as Timestamp)
      .sort((a, b) => a.toDate().getTime() - b.toDate().getTime());

    let from = dates[0];
    let to = dates[dates.length - 1];

    // Pad the dates by 1 day
    newState.from = Timestamp.fromDate(
      new Date(from.toDate().getTime() - 24 * 60 * 60 * 1000)
    );
    newState.to = Timestamp.fromDate(
      new Date(to.toDate().getTime() + 24 * 60 * 60 * 1000)
    );
  } else {
    newState.from = null;
    newState.to = null;
  }

  // Create the batches
  newState.batches = {};

  // get or create a batch
  let b = (type: TxnReferenceType, id: string) => {
    let key = `${type}:${id}`;
    if (!newState.batches[key]) {
      let batch: BatchType = {
        key,
        txns: [],
        type: type as any,
        amountInCents: 0,
      };

      if (type === "invoice") {
        (batch as InvoiceBatchType).object = null;
      } else if (type === "payment") {
        (batch as PaymentBatchType).object = null;
      } else if (type === "payout") {
        (batch as PayoutBatchType).object = null;
      }

      newState.batches[key] = batch;
    }
    return newState.batches[key];
  };

  for (let txn of newState.txns) {
    let batch = b(txn.referenceType, txn.referenceId);
    batch.txns.push(txn);
    batch.txns.sort(
      (a, b) => a.createdAt.toDate().getTime() - b.createdAt.toDate().getTime()
    );
  }

  for (let invoice of newState.invoices) {
    let batch = b("invoice", invoice.id) as InvoiceBatchType;
    if (batch.object) {
      throw new Error(`Invoice batch ${invoice.id} already has an object`);
    }
    batch.object = invoice;
    batch.amountInCents += invoice.finalTotalInCents;
  }

  for (let payment of newState.payments) {
    let batch = b("payment", payment.id) as PaymentBatchType;
    if (batch.object) {
      throw new Error(`Payment batch ${payment.id} already has an object`);
    }
    batch.object = payment;
    batch.amountInCents += payment.amountInCents;
  }

  for (let payout of newState.payouts) {
    let batch = b("payout", payout.id) as PayoutBatchType;
    if (batch.object) {
      throw new Error(`Payout batch ${payout.id} already has an object`);
    }
    batch.object = payout;
    batch.amountInCents += payout.amountInCents;
  }

  // Create the entries from the batches
  let entries: Entry[] = [];
  for (let batch of Object.values(newState.batches)) {
    let hasTxns = batch.txns.length > 0;
    let hasObject = "object" in batch && batch.object;

    // we have all we need to build the line and verifiy if correct
    if (hasTxns) {
      let startingTxn = batch.txns[0];
      let tradePayableSum = 0;
      let tradeReceivableSum = 0;
      let tradePayableBalance = 0;
      let tradeReceivableBalance = 0;

      for (let t of batch.txns) {
        if (t.accountName === "trade payable") {
          tradePayableSum += t.amountInCents;
          // The last transaction has the balance and the txns are in order
          tradePayableBalance = t.currentBalanceInCents;
        }
        if (t.accountName === "trade receivable") {
          tradeReceivableSum += t.amountInCents;
          tradeReceivableBalance = t.currentBalanceInCents;
        }
      }

      let amountInCents = tradePayableSum + tradeReceivableSum;
      let balanceInCents = tradePayableBalance + tradeReceivableBalance;

      if (hasObject) {
        let b = batch as InvoiceBatchType | PaymentBatchType | PayoutBatchType;

        let isCorrect = false;
        let type: Entry["type"] = b.type;
        if (b.type === "invoice") {
          // Could be a statement
          if (b.object?.clientType === "Seller") {
            type = "statement";
          }

          isCorrect = b.object?.finalTotalInCents === amountInCents;
        } else if (b.type === "payment") {
          isCorrect = b.object?.amountInCents === amountInCents;
        } else if (b.type === "payout") {
          isCorrect = b.object?.amountInCents === amountInCents;
        }

        let entry: Entry = {
          id: batch.key,
          date: startingTxn.createdAt,
          amountInCents,
          balanceInCents,
          description: toDescription(b.type, b.object),
          link: toLink(b.type, b.object),
          type,
          resource: b.object,
          transactions: b.txns,
          isCorrect,
        };

        if (b.type === "invoice") {
          entry.lotSummary = "todo - thinking of using llm here";
        }

        if (b.type === "payment") {
          entry.paymentSource = b.object?.method as any; // todo
        }

        if (b.type === "payout") {
          entry.paymentMethod = b.object?.method as any; // todo
        }

        entries.push(entry);
        continue;
      }

      if (!hasObject) {
        // only valid if it's a non core object type

        let coreTypes = ["invoice", "payment", "payout"] as TxnReferenceType[];
        let isCoreType = coreTypes.includes(batch.type as TxnReferenceType);

        if (isCoreType) {
          // this entry definitely isn't correct. But we can still show it.
          // There are transactions on the ledger but no corresponding invoice or payment object.
          // Maybe it got deleted incorrectly
          let entry: Entry = {
            id: batch.key,
            date: startingTxn.createdAt,
            amountInCents,
            balanceInCents,
            description: `Missing ${batch.type} with ID ${startingTxn.referenceId}`,
            link: null,
            type: batch.type as TxnReferenceType,
            resource: null,
            transactions: batch.txns,
            isCorrect: false,
          };

          entries.push(entry);
          continue;
        }

        let b = batch as NonObjectBatchType;
        // This is a ledger entry that didn't need an object.

        let entry: Entry = {
          id: b.key,
          date: startingTxn.createdAt,
          amountInCents,
          balanceInCents,
          description: `${startingTxn.batchDescription}`,
          link: null,
          type: b.type as TxnReferenceType,
          resource: null,
          transactions: b.txns,
          isCorrect: true,
        };
        entries.push(entry);
        continue;
      }
    }

    if (!hasTxns && hasObject) {
      // only an object and no transactions so the entry is missing on the ledger
      // a big error

      let b = batch as InvoiceBatchType | PaymentBatchType | PayoutBatchType;

      let amountInCents: number = -1;
      if (b.type === "invoice") {
        amountInCents = b.object?.finalTotalInCents ?? -1;
      } else if (b.type === "payment") {
        amountInCents = b.object?.amountInCents ?? -1;
      } else if (b.type === "payout") {
        amountInCents = b.object?.amountInCents ?? -1;
      }

      let entry: Entry = {
        id: batch.key,
        date: b.object?.createdAt,
        amountInCents,
        balanceInCents: null,
        description: `Transactions for ${b.type} #${b.object?.id} are not present on the ledger`,
        link: null,
        type: batch.type as TxnReferenceType,
        resource: null,
        isCorrect: false,
        transactions: null,
      };
      entries.push(entry);
      continue;
    }

    throw new Error(`Batch ${batch.key} has no transactions or object`);
  }

  entries.sort((a, b) => a.date.toDate().getTime() - b.date.toDate().getTime());
  newState.entries = entries;

  return newState;
}

function toLink(
  type: TxnReferenceType,
  object: Invoice | Payment | Payout | any
) {
  if (type === "invoice") {
    let o = object as Invoice;
    return `/invoices/${o.id}`;
  }

  if (type === "payment") {
    let o = object as Payment;
    return `/payments/${o.id}`;
  }

  if (type === "payout") {
    let o = object as Payout;
    return `/payouts/${o.id}`;
  }

  return null;
}

function toDescription(
  type: TxnReferenceType,
  object: Invoice | Payment | Payout | any
) {
  if (type === "invoice") {
    let o = object as Invoice;
    return `Invoice #${o.invoiceNumber} - DESC TODO`;
  }

  if (type === "payment") {
    let o = object as Payment;
    return `Payment #${o.id} - DESC TODO`;
  }

  if (type === "payout") {
    let o = object as Payout;
    return `Payout #${o.id} - DESC TODO`;
  }

  return "";
}
