import { DocumentSpec, InvoicePDFProps } from "../../_importDocs";

// JS PDF
import { jsPDF } from "jspdf";
import { Market, SuperType } from "types";

declare module "jspdf" {
  interface jsPDF {
    mainHeaderHeight: number;
    runningHeaderHeight: number;
    mainFooterPosition: number;
    lastAutoTable: {
      finalY: number;
    };
    linePosition: number;
    tagsRead: number;
    totalItems: number;
  }
}

import { getMarketBranding } from "../../utils";

// Parts
import footer from "./parts/footer";
import header from "./parts/header";
import table from "./parts/table";

const TagListSpec: DocumentSpec = {
  // must be unique
  id: "sheep-tag-list",
  objectType: "invoice",

  // displayed to the user
  name: "Sheep Tag List",

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    let { invoice, marketId } = props;
    let invoiceNumber =
      invoice.status === "draft" ? "Draft" : invoice.invoiceNumber;
    let filename = `sheep-tag-list-${marketId}-${invoiceNumber}`;
    filename = filename.replace(/ /g, "-").replace(/[^a-zA-Z0-9-]/g, "");
    return `${filename}`;
  },

  // return true if this document is available for a market to generate
  isAvailableFor: (market: Market, superTypes: SuperType[]) => {
    // only available for livestock markets
    let allowedSuperTypes: SuperType[] = ["Sheep", "Goats"];
    return superTypes.some((superType) =>
      allowedSuperTypes.includes(superType)
    );
  },
  // the document template
  jsPDF: (props: InvoicePDFProps) => {
    const { market, invoice } = props;
    const margin = 10;

    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      compress: true,
      unit: "mm",
      format: "a4",
    });

    const {
      dateFormat,
      textFillColor,
      tableHeaderFillColor,
      tableHeaderTextColor,
      tableStripeFillColor,
      tableStripeTextColor,
    } = getMarketBranding(market);

    header(doc, invoice, {
      output: false,
      dateFormat: dateFormat,
      title: "Tag List",
      margin: margin,
      textFillColor: textFillColor,
      logo: props.marketLogo,
      market: market,
    });

    table(doc, invoice, {
      tagsPerRow: 4,
      margin: margin,
      headerFillColor: tableHeaderFillColor,
      headerTextColor: tableHeaderTextColor,
      stripeFillColor: tableStripeFillColor,
      stripeTextColor: tableStripeTextColor,
    });

    header(doc, invoice, {
      output: true,
      dateFormat: dateFormat,
      title: "Tag List",
      margin: margin,
      textFillColor: textFillColor,
      logo: props.marketLogo,
      market: market,
    });

    footer(doc, invoice, {
      margin,
      textFillColor: textFillColor,
    });

    return doc;
  },
};

export default TagListSpec;
