import { getDisplayName } from "@/data/customerUtils";
import { useRouter } from "next/router";
import { Customer } from "../../types";

interface PersonIconProps {
  customer: Customer | null;
}

export const PersonIcon = (props: PersonIconProps) => {
  let { customer } = props;
  let router = useRouter();
  let marketId = router.query.marketId as string;

  if (!customer) {
    return null;
  }

  // If customer has a photo URL set, use that first
  if (customer?.photoURL) {
    return (
      <span className="inline-flex h-14 w-14 items-center justify-center bg-gray-500">
        <img
          src={customer.photoURL}
          alt="Customer photo"
          className="h-14 w-14"
        />
      </span>
    );
  }

  // Use the API-generated avatar
  return (
    <span className="inline-flex h-14 w-14 items-center justify-center">
      <img
        src={`/api/${marketId}/customers/${customer.id}/avatar`}
        alt={getDisplayName(customer)}
        className="h-14 w-14"
      />
    </span>
  );
};

export default PersonIcon;
