import { mediaVariantSelector } from "@/data/media/media.helpers";
import studioDark from "../../images/studio-icon.png";
import { StarIcon } from "@heroicons/react/24/solid";
import { CSSProperties, useState } from "react";
import { Media } from "types";
import Image from "next/image";
import { Spinner } from "../Buttons";
import classNames from "classnames";
import { VideoMediaIcon } from "../Icons";

export const MediaDisplay = ({
  value,
  width = 50,
  height = 50,
  varietyPriority = "thumbnail",
  className,
  style,
  showInitialIcon = false,
  smallIcons = false,
}: {
  value: Media;
  width?: number;
  height?: number;
  varietyPriority?: "small" | "medium" | "large" | "compressed" | "thumbnail";
  className?: string;
  style?: CSSProperties | undefined;
  showInitialIcon?: boolean;
  smallIcons?: boolean;
}) => {
  const [fallBackImage, setFallbackImage] = useState<boolean>(false);

  const isProcessing = Object.values(value.variants).filter((v) => {
    return v.isProcessing === true;
  }).length;

  let showVideoIcon = false;
  if (value?.fileType?.includes("video")) {
    showVideoIcon = true;
  }

  const selectImageVariant = mediaVariantSelector(value, varietyPriority);

  if (isProcessing) {
    if (value?.fileType?.includes("video") && selectImageVariant?.isLocal) {
      return (
        <div className="flex justify-center items-center h-full w-full overflow-hidden rounded-lg">
          <div className=" flex flex-col justify-center items-center gap-2 rounded-lg relative bg-gradient-to-t from-slate-400 to-slate-50">
            {isProcessing && (
              <Spinner className="absolute w-4 -top-1 -left-1 shadow-lg  z-[999]" />
            )}
            {showInitialIcon && <InitialIcon smallIcons={smallIcons} />}
            {showVideoIcon && <VideoIcon smallIcons={smallIcons} />}
            <video
              style={{
                ...style,
              }}
              className="inline-block w-full h-full rounded-lg grayscale"
              controls={false}
              src={selectImageVariant?.url}
            ></video>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="relative w-full h-full">
          {isProcessing && (
            <Spinner className="absolute w-4 -top-1 -left-1 shadow-lg z-[999]" />
          )}
          {showInitialIcon && <InitialIcon smallIcons={smallIcons} />}
          {showVideoIcon && <VideoIcon smallIcons={smallIcons} />}
          <Image
            loading="lazy"
            key={selectImageVariant?.url}
            style={{
              ...style,
            }}
            className={`bg-gradient-to-t from-slate-400 to-slate-50     inline-block rounded-lg object-cover w-full h-full ${
              !selectImageVariant?.isLocal && "!object-none"
            }`}
            src={
              selectImageVariant?.isLocal ? selectImageVariant?.url : studioDark
            }
            alt="logo"
            width={width}
            height={height}
          />
        </div>
      </>
    );
  }

  if (!selectImageVariant) {
    return <>Image not Found</>;
  }
  return (
    <>
      <div className="relative w-full h-full">
        {fallBackImage ? (
          <div className="relative">
            <Image
              loading="lazy"
              style={{
                ...style,
              }}
              src={studioDark}
              alt="logo"
              width={width}
              height={height}
              className="inline-block size-12 rounded-lg grayscale"
            />
          </div>
        ) : (
          <>
            {showInitialIcon && <InitialIcon smallIcons={smallIcons} />}
            {showVideoIcon && <VideoIcon smallIcons={smallIcons} />}
            <Image
              key={selectImageVariant?.url}
              style={{
                ...style,
              }}
              className={classNames(
                `inline-block rounded-lg w-full h-full object-cover`,
                className
              )}
              width={width}
              height={height}
              sizes="100vw"
              onError={(r) => {
                setFallbackImage(true);
              }}
              src={selectImageVariant?.url as string}
              alt={value.fileName}
            />
          </>
        )}
      </div>
    </>
  );
};

const VideoIcon = ({ smallIcons }: { smallIcons?: boolean }) => {
  return (
    <div
      className={classNames(
        "top-1/2 left-1/2 absolute h-8 w-10  text-martEye-400 z-10  rounded-tr-md rounded-bl-md flex items-center justify-center -translate-x-1/2 -translate-y-1/2   rounded-lg   bg-black/10 shadow-lg",
        smallIcons ? "h-6 w-6" : "h-8 w-10"
      )}
    >
      <VideoMediaIcon className="h-10 w-10 " />
    </div>
  );
};

const InitialIcon = ({ smallIcons }: { smallIcons?: boolean }) => {
  return (
    <div
      className={classNames(
        "absolute top-0 right-0 h-6 w-6 text-martEye-400 z-10 bg-white/90 rounded-tr-md rounded-bl-md flex items-center justify-center",
        smallIcons ? "h-4 w-4" : "h-5 w-5"
      )}
    >
      <StarIcon className="h-5 w-5" />
    </div>
  );
};
