import {
  Timestamp,
  deleteField,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
  GeoPoint,
} from "firebase/firestore";
import { useCallback } from "react";

import { useShowDialog } from "@/components/ConfirmationDialogProvider";
import { useFirestore } from "@/data/studio-react/firebase/useFirestore";
import { useCurrentUid } from "@/data/studio-react/firebase/useFirestoreAuth";
import { useMarketId } from "@/data/useMartketId";
import { autoId } from "../../data/ids";
import { Address, AddressWrapper, Customer } from "../../types";

export function useAddressFunctions(customerId: string) {
  let firestore = useFirestore();
  let currentUid = useCurrentUid();
  let { showDialog } = useShowDialog();

  let marketId = useMarketId();

  let createAddress = useCallback(
    async (values: Address, primary: boolean = false) => {
      let newAddress: AddressWrapper = {
        address: {
          id: autoId(),
          firstName: values.firstName as string,
          lastName: values.lastName as string,
          address1: values.address1 as string,
          address2: values.address2 as string,
          city: values.city as string,
          province: values.province as string,
          zip: values.zip as string,
          country: values.country as string,
        },
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        updatedBy: currentUid,
      };

      // convert the coordinates to a GeoPoint
      let hasCoordinates =
        values?.coordinates?.latitude && values?.coordinates?.longitude;

      let coordinates = undefined;

      if (hasCoordinates) {
        let lat = values?.coordinates?.latitude || null;
        let lng = values?.coordinates?.longitude || null;

        if (lat && lng) {
          coordinates = new GeoPoint(lat, lng);
        }
      }

      let docRef = doc(
        firestore,
        `markets/${marketId}/customers/${customerId}`
      );

      try {
        if (primary) {
          await updateDoc(docRef, {
            address: {
              ...newAddress,
              coordinates,
            },
            updatedAt: Timestamp.now(),
          });
          return true;
        } else {
          await updateDoc(docRef, {
            [`otherAddresses.${newAddress.address.id}`]: {
              ...newAddress,
              coordinates,
            },
            updatedAt: Timestamp.now(),
          });
        }

        return true;
      } catch (error) {
        console.log(error);
      }
    },
    [currentUid, marketId, firestore, customerId]
  );

  let updateAddress = useCallback(
    async (values: Address, addressId: string, primary: boolean = false) => {
      let ammendAddress: Partial<AddressWrapper> = {
        address: {
          id: addressId,
          firstName: values.firstName as string,
          lastName: values.lastName as string,
          address1: values.address1 as string,
          address2: values.address2 as string,
          city: values.city as string,
          province: values.province as string,
          zip: values.zip as string,
          country: values.country as string,
          company: values.company as string,
        },
        updatedAt: serverTimestamp(),
        updatedBy: currentUid,
      };

      // convert the coordinates to a GeoPoint
      let hasCoordinates =
        values?.coordinates?.latitude && values?.coordinates?.longitude;

      let coordinates = undefined;

      if (hasCoordinates) {
        let lat = values?.coordinates?.latitude || null;
        let lng = values?.coordinates?.longitude || null;

        if (lat && lng) {
          coordinates = new GeoPoint(lat, lng);
        }
      }

      let docRef = doc(
        firestore,
        `markets/${marketId}/customers/${customerId}`
      );
      try {
        if (primary) {
          await updateDoc(docRef, {
            address: {
              ...ammendAddress,
              coordinates: coordinates,
            },
            updatedAt: Timestamp.now(),
          });
          return true;
        } else {
          await updateDoc(docRef, {
            [`otherAddresses.${addressId}`]: {
              ...ammendAddress,
              coordinates: coordinates,
            },
            updatedAt: Timestamp.now(),
          });

          return true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    [currentUid, marketId, firestore, customerId]
  );

  let deleteAddress = useCallback(
    async (addressId: string) => {
      showDialog(
        "Delete Address",
        "This will delete the address permantly /n Are you sure?",
        "Delete",
        () => {
          let docRef = doc(
            firestore,
            `markets/${marketId}/customers/${customerId}`
          );
          updateDoc(docRef, {
            [`otherAddresses.${addressId}`]: deleteField(),
            updatedAt: Timestamp.now(),
          });
        },
        "Cancel",
        () => {},
        "danger"
      );
    },
    [marketId, firestore, customerId, showDialog]
  );

  let makeAddressPrimary = useCallback(
    async (addressId: string) => {
      let docRef = doc(
        firestore,
        `markets/${marketId}/customers/${customerId}`
      );

      // 1. get the address from the customer
      let addressToMove: AddressWrapper | undefined;
      let oldPrimaryToMove: AddressWrapper | undefined;
      await getDoc(docRef).then((doc) => {
        console.log(doc.data());

        addressToMove = doc.data()?.otherAddresses[addressId];
        oldPrimaryToMove = doc.data()?.address;
      });
      // 2. move the address fro the primary address to the other addresses

      if (oldPrimaryToMove) {
        // Add a ID if there isn't one
        if (!oldPrimaryToMove.address.id) {
          oldPrimaryToMove.address.id = autoId();
        }

        await updateDoc(docRef, {
          [`otherAddresses.${oldPrimaryToMove.address.id}`]: oldPrimaryToMove,
          updatedAt: Timestamp.now(),
        });
      }
      // 3. move the address from the other addresses to the primary address
      if (addressToMove) {
        await updateDoc(docRef, {
          address: addressToMove,
          [`otherAddresses.${addressId}`]: deleteField(),
          updatedAt: Timestamp.now(),
        });
      }
    },
    [currentUid, marketId, firestore, customerId]
  );

  let getAddressesForCustomer = useCallback(async () => {
    if (!customerId) return [];

    let docRef = doc(firestore, `markets/${marketId}/customers/${customerId}`);
    const customer = await getDoc(docRef);
    const customerData = customer.data() as Customer;

    let addresses: AddressWrapper[] = [];

    // 1. get the address from the customer
    if (customerData.address) {
      // Need to check if the address has an ID
      addresses.push(customerData.address);
    }

    // 2. get the other addresses from the customer
    if (customerData.otherAddresses) {
      const otherAddresses = Object.values(customerData.otherAddresses);
      addresses = [...addresses, ...otherAddresses];
    }

    return addresses;
  }, [currentUid, marketId, firestore, customerId]);

  return {
    createAddress,
    updateAddress,
    deleteAddress,
    makeAddressPrimary,
    getAddressesForCustomer,
  };
}
