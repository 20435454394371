import {
  collection,
  doc,
  getDoc,
  getDocFromCache,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  GeoPoint,
} from "firebase/firestore";
import { useCallback } from "react";
import {
  Address,
  AddressWrapper,
  Customer,
  EmailWrapper,
  PhoneNumberWrapper,
} from "../types";
import {
  getInitialisedFirestore,
  useFirestore,
} from "./studio-react/firebase/useFirestore";

import { autoId } from "@/data/ids";
import { useCurrentUid } from "@/data/studio-react/firebase/useFirestoreAuth";
import { useStudioStream } from "./studio-react/useStudioStream";

export interface CreateCustomer {
  displayName: string;
  email?: string;
  phone?: string;
  address?: Omit<Address, "id">;

  // Other fields
  accountNumberPrefix?: string;
  cphNumber?: string;
  farmName?: string;
}

export async function getCustomer(
  marketId: string | null | undefined,
  customerId: string | null | undefined,
  options: { fromCache?: boolean } = {}
) {
  let firestore = getInitialisedFirestore();
  let ref = doc(firestore, `markets/${marketId}/customers/${customerId}`);
  let snap = options.fromCache ? await getDocFromCache(ref) : await getDoc(ref);

  if (!snap.exists()) {
    return null;
  }

  return snap.data() as Customer;
}

export async function updateCustomer(
  marketId: string | null | undefined,
  currentUid: string | null | undefined,
  customer: string | null | undefined,
  update: Partial<Customer>
) {
  if (!marketId || !currentUid || !customer) {
    return;
  }

  let firestore = getInitialisedFirestore();
  let wrappedUpdate: Partial<Customer> = {
    ...update,
    updatedAt: serverTimestamp(),
    updatedBy: currentUid,
  };

  await updateDoc(
    doc(firestore, `markets/${marketId}/customers/${customer}`),
    wrappedUpdate
  );
}

export function generateRandomThreeDigitNumber() {
  const min = 1; // Minimum value for the random number
  const max = 99; // Maximum value for the random number

  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNumber;
}

export function useCustomerFunctions() {
  let firestore = useFirestore();
  let currentUid = useCurrentUid();
  let marketId = useStudioStream("session:marketId");

  let generateCustomerAccountNumber = useCallback(
    async (accountPrefix: string) => {
      let accountNumberStart = accountPrefix;
      let accountNumberEnd = generateRandomThreeDigitNumber();
      let accountNumber = `${accountNumberStart}${accountNumberEnd}`;

      // Check to see if there is a user with this account number
      let accountNumberQuery = query(
        collection(firestore, `markets/${marketId}/customers`),
        where(`accountNumber`, `==`, accountNumber)
      );

      let accountNumberCheck = await getDocs(accountNumberQuery);
      // Generate a new account number if the current one already exists
      let attempts = 0;
      const maxAttempts = 100; // Set a limit to the number of attempts
      while (accountNumberCheck.size > 0 && attempts < maxAttempts) {
        accountNumberEnd = generateRandomThreeDigitNumber();
        accountNumber = `${accountNumberStart}${accountNumberEnd}`;
        accountNumberQuery = query(
          collection(firestore, `markets/${marketId}/customers`),
          where(`accountNumber`, `==`, accountNumber)
        );
        accountNumberCheck = await getDocs(accountNumberQuery);
        attempts++;

        // if we cant find a unique account number after 100 attempts
        if (attempts === maxAttempts && accountNumberStart.length == 4) {
          throw new Error(
            "Could not generate a unique account number. Contact support."
          );
          break;
        }
      }

      // If a unique account number is not found after maxAttempts, throw an error
      if (attempts === maxAttempts) {
        throw new Error(
          "Could not generate a unique account number. Contact support."
        );
      }

      // Once a unique account number is found, you can proceed with it
      return accountNumber;
    },
    [marketId, firestore, currentUid]
  );
  let createCustomer = useCallback(
    async (customer: CreateCustomer) => {
      let customerId = autoId();

      if (!marketId) {
        return;
      }

      let accountNumber = await generateCustomerAccountNumber(
        customer.accountNumberPrefix ?? customer.displayName.substring(0, 4)
      );

      let attributeDefaultsBuyer: Customer["attributeDefaultsBuyer"] = {};
      let attributeDefaultsSeller: Customer["attributeDefaultsSeller"] = {};

      if (customer.cphNumber) {
        attributeDefaultsBuyer.cphNumberDestination = [customer.cphNumber];
        attributeDefaultsSeller.cphNumberOrigin = [customer.cphNumber];
      }

      if (customer.farmName) {
        attributeDefaultsSeller.farmName = [customer.farmName];
      }

      let newCustomer: Partial<Customer> = {
        id: customerId,
        marketId: marketId,
        displayName: customer.displayName ?? null,

        attributeDefaultsBuyer,
        attributeDefaultsSeller,

        accountNumber: accountNumber,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        updatedBy: currentUid,
        otherAddresses: {},
        otherPhoneNumbers: [],
        metadata: [],
      };

      // Add the phone number if it exists
      if (customer.phone) {
        newCustomer.phoneNumber = {
          phoneNumber: customer.phone,
          createdAt: serverTimestamp(),
          isVerified: false,
        } as PhoneNumberWrapper;
      }

      // Add the email if it exists
      if (customer.email) {
        newCustomer.email = {
          email: customer.email,
          isVerified: false,
          createdAt: serverTimestamp(),
        } as EmailWrapper;
      }

      // Add the address if it exists
      if (customer.address) {
        // convert the coordinates to a GeoPoint
        let hasCoordinates =
          customer?.address?.coordinates?.latitude &&
          customer?.address?.coordinates?.longitude;

        let coordinates = null;

        if (hasCoordinates) {
          let lat = customer?.address?.coordinates?.latitude || null;
          let lng = customer?.address?.coordinates?.longitude || null;

          if (lat && lng) {
            coordinates = new GeoPoint(lat, lng);
          }
        }

        newCustomer.address = {
          address: {
            ...customer.address,
            coordinates: coordinates,
            id: autoId(),
          },
          createdAt: serverTimestamp(),
        } as AddressWrapper;
      }

      try {
        await setDoc(
          doc(firestore, `markets/${marketId}/customers/${customerId}`),
          newCustomer
        );

        return customerId;
      } catch (error) {
        console.log(error);
      }
    },
    [marketId, firestore, currentUid]
  );

  return {
    createCustomer,
  };
}
