import { interpolateRainbow } from "d3-scale-chromatic";
import seedrandom from "seedrandom";

/**
 * Deterministic way to generate a random colour for a user
 * @param uid
 */
export function genColour(seed?: string | null) {
  let rng = seedrandom(`${seed?.split("").reverse().join("")}`);
  let number = Math.round(rng() * 999);
  let color = (seed && interpolateRainbow(number / 999)) || "transparent";
  return color;
}

export interface ColorTheme {
  color: string;
  contrast: string;
  // product?: string;
  // productCode?: string;
}

export function getProductCodeColorThemes() {
  const colors: ColorTheme[] = [
    {
      color: "#DBFF00",
      contrast: "#4E5718",
      // product: "Machinery",
      // productCode: "Machinery",
    },
    {
      color: "#FFD59F",
      contrast: "#795322",
      // product: "SL - Store Lambs",
      // productCode: "SL",
    },
    {
      color: "#FFC57F",
      contrast: "#AE5F00",
      // product: "SH - Sheep",
      // productCode: "SH",
    },
    {
      color: "#FF9D89",
      contrast: "#A12208",
      // product: "PS - Pedigree Sheep",
      // productCode: "PS",
    },
    {
      color: "#FF8486",
      contrast: "#9E0003",
      // product: "PL - Pet Lamb",
      // productCode: "PL",
    },
    {
      color: "#FF9FA0",
      contrast: "#C82B2D",
      // product: "PE - Pedigree Ewe",
      // productCode: "PE",
    },
    {
      color: "#FF9DBC",
      contrast: "#C60C47",
      // product: "HG - Fat Hoggets",
      // productCode: "HG",
    },
    {
      color: "#FF9FED",
      contrast: "#9A007E",
      // product: "FL - Fat Lambs",
      // productCode: "FL",
    },
    {
      color: "#DD9FFF",
      contrast: "#700FA4",
      // product: "FE - Fat Ewe",
      // productCode: "FE",
    },
    {
      color: "#7CDAFF",
      contrast: "#006B96",
      // product: "DD - Dairy Dispersal",
      // productCode: "DD",
    },
    {
      color: "#00B7FF",
      contrast: "#064058",
      // product: "DC - Dairy Cow",
      // productCode: "DC",
    },
    {
      color: "#98E698",
      contrast: "#0B650B",
      // product: "SU - Suckler Cow",
      // productCode: "SU",
    },
    {
      color: "#98E698",
      contrast: "#0B650B",
      // product: "SC - Suckler Calf",
      // productCode: "SC",
    },
    {
      color: "#BBFFB8",
      contrast: "#2C7128",
      // product: "PC - Pedigree Cattle",
      // productCode: "PC",
    },
    {
      color: "#BDFF82",
      contrast: "#387402",
      // product: "FC - Fat/Beef Cattle",
      // productCode: "FC",
    },
    {
      color: "#7EC288",
      contrast: "#2E5434",
      // product: "CC - Cull Cows",
      // productCode: "CC",
    },
    {
      color: "#65F57B",
      contrast: "#0D641B",
      // product: "CA - Calf",
      // productCode: "CA",
    },
    {
      color: "#7EB24D",
      contrast: "#335513",
      // product: "BU - Store Cattle",
      // productCode: "BU",
    },
    {
      color: "#9ADA5B",
      contrast: "#316300",
      // product: "BL - Bull",
      // productCode: "BL",
    },
    {
      color: "#B8DA5B",
      contrast: "#435B03",
      // product: "BH - Beef Cattle",
      // productCode: "BH",
    },
    {
      color: "#B8DA5B",
      contrast: "#435B03",
      // product: "BC - Beef Cattle",
      // productCode: "BC",
    },
    {
      color: "#DEFF84",
      contrast: "#4B620A",
      // product: "BB - Beef Bullock",
      // productCode: "BB",
    },
    {
      color: "#FB7DB1",
      contrast: "#882951",
      // product: "PP - Prime Pigs",
      // productCode: "PP",
    },
    {
      color: "#FB7DB1",
      contrast: "#6C002D",
      // product: "PS - Shearling",
      // productCode: "PS",
    },
    {
      color: "#FB7DB1",
      contrast: "#560124",
      // product: "PP - Prime Pigs",
      // productCode: "PP",
    },
  ];

  return colors;
}

export function getProductCodeColorThemeFromList(seed: string): ColorTheme {
  const themes = getProductCodeColorThemes();
  const rng = seedrandom(seed);
  const index = Math.floor(rng() * themes.length);
  return themes[index];
}
