import { autoId } from "@/data/ids";
import { sortByLotNumber } from "@/data/lots";
import {
  Timestamp,
  Transaction,
  WriteBatch,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import {
  AttributeDefinition,
  AttributeValueType,
  Customer,
  Lot,
  LotItem,
  Media,
  ProductCodeConfiguration,
} from "types";
import { FullSaleContext } from "../ExecuteInSaleContext";
import { createShortCustomerDetails } from "../helpers/createShortCustomerDetails";
import { getOrLoadCustomer } from "../helpers/getOrLoadCustomer";
import { createLotItem } from "./createLotItem";

/***
 *  Create a new lot and optionally save it to firestore
 * 
   Will also  handle:
     - setting the correct product code from the lot number if needed
     - auto incrementing the lot number
     - auto incrementing the index
     - set default currency and unit of sale from the market defaults
     - set the default product code from the Sale
     - setting the attribute default values from the product code
     - prefill attributes using values from the previous lot if available
 * 
 *
 *  Can also be used to save a pre-created lot
 * 
 */

export async function createNewLot(
  ctx: FullSaleContext,
  options?:
    | {
        index?: number;
        lotNumber?: string | null;
        group?: string;

        productCode?: string; // if not provided the default for the sale will be used
        sellerCustomerId?: string;

        attributes?: {
          [key: string]: string | number | boolean | Timestamp | Media | null;
        };

        metadata?: {
          [key: string]: any;
        };

        buyerCustomerId?: string; // usually not set at this point but useful for imports
        unitPriceInCents?: number;

        startingPriceInCents?: number;
        reservePriceInCents?: number;
        remarks?: string;
        buyerCasual?: string;

        // the lot start and end times. Optional, will default to now if the unitPriceInCents is set
        startAt?: Timestamp;
        endAt?: Timestamp;

        // use the previous lot's attributes that are marked as prefillable
        // IF the product code and the seller are the same (or the seller is not set)
        prefillFromPreviousLot?: boolean;

        autoGroupWithPreviousLot?: boolean;
        // the ID of the previous lot to prefill from
        prefillFromPreviousLotId?: string;

        // if true, the lot will not be saved to firestore
        doNotWriteToFirestore?: boolean;

        lot?: undefined; // shouldn't be supplied in this mode

        // optional batch to save to. If not provided it will just save to firestore
        batch?: WriteBatch;
      }
    | {
        // provide this if you have the whole object already and just want to save it
        // note supplying a complete lot will side step the functionality in updateLot like setting the shortcustomer details
        lot: Lot;

        // optional batch to save to. If not provided it will just save to firestore
        batch?: never;
        transaction?: Transaction;
      }
    | {
        // provide this if you have the whole object already and just want to save it
        // note supplying a complete lot will side step the functionality in updateLot like setting the shortcustomer details
        lot: Lot;

        // optional batch to save to. If not provided it will just save to firestore
        batch?: WriteBatch;
        transaction?: never;
      }
): Promise<Lot> {
  let { currentUid, marketDefaultSettings, saleProductCodes, sale, lots } = ctx;
  let { defaultProductCode, id: saleId, marketId, attributeSet } = sale || {};
  let workingFromLot: Lot | undefined;

  // if this is true we need to create one lot item
  let hasNoItemLevelAttributes = attributeSet?.every((a) => a.level !== "item");

  if (
    options &&
    "prefillFromPreviousLotId" in options &&
    options.prefillFromPreviousLotId
  ) {
    workingFromLot = findLot(lots, options.prefillFromPreviousLotId);

    if (!workingFromLot) {
      throw new Error(
        `No previous lot found with ID ${options.prefillFromPreviousLotId}. Prefilling from previous lot is not possible.`
      );
    }
  }

  if (options && "lot" in options && options.lot) {
    let lot = options.lot;

    // It's possible the index has now been taken, recalculate it if needed
    if (lot.index) {
      // note here the local lots array may not be up to date
      // especially if you're updating a batch.
      let lotMatchingIndex = ctx.lots.find((l) => l.index === lot.index);
      if (lotMatchingIndex && !options.batch) {
        lot.index = calculateNextLotIndex(ctx.lots);
      }
    } else {
      lot.index = calculateNextLotIndex(ctx.lots);
    }

    // Ensure the super type is set
    let productCodeConfig = ctx.saleProductCodes?.find(
      (config) => config.code === lot.productCode
    )!;

    lot.superType = productCodeConfig.superType;
    let items = Object.keys(lot.itemMap);
    if (hasNoItemLevelAttributes && items.length === 0) {
      // We need to create a lot item
      let item = await createLotItem(ctx, lot, {
        doNotWriteToFirestore: true,
      });

      lot.itemMap = {
        [item.id]: item,
      };
    }
    if ("transaction" in options) {
      return saveLotToFirestore({
        ctx,
        lot: options.lot,
        transaction: options.transaction,
      });
    }

    return saveLotToFirestore({
      ctx,
      lot: options.lot,
      batch: options.batch,
    });
  }

  let { index, lotNumber, doNotWriteToFirestore, group } = options || {};

  if (index === undefined) {
    index = calculateNextLotIndex(lots);
  }

  if (group === undefined) {
    // Optimize the logic for prefilling lot grouping from the previous lot if applicable
    if (options?.prefillFromPreviousLot && options?.autoGroupWithPreviousLot) {
      const currentIndex = index;
      const previousLot = [...ctx.lots]
        .reverse()
        .find((lot) => lot.index < currentIndex);
      // Proceed only if previousLot exists and is part of a group
      if (previousLot && previousLot.group !== null) {
        // Check if the previous lot's group contains more than one lot
        const isGroupedWithOthers = lots.some(
          (lot) => lot.group === previousLot.group && lot !== previousLot
        );
        // If the previous lot is grouped with others, assign its group to the new lot
        if (isGroupedWithOthers) {
          group = previousLot.group;
        }
      }
    }
  }

  // note - allow null to create a lot without a lot number
  if (lotNumber === undefined) {
    lotNumber = calculateNextLotNumber(
      lots,
      workingFromLot?.lotNumber ?? undefined,
      options?.productCode,
      ctx.saleProductCodes
    );
  }

  // Need to set the lot number based on the product code range not set product code by lot number as we dont save the lot number
  // until its created

  // some product codes should be set by the lot number.
  // we change the default here if so.

  let optionsProductCode = options?.productCode;
  let optionsSellerCustomerId = options?.sellerCustomerId;
  let optionsBuyerCustomerId = options?.buyerCustomerId;
  if (options?.prefillFromPreviousLot) {
    let previousLot = findLastLot(lots, optionsProductCode);
    if (previousLot) {
      if (optionsProductCode === undefined) {
        optionsProductCode = previousLot.productCode;
      }

      if (optionsSellerCustomerId === undefined) {
        optionsSellerCustomerId = previousLot.sellerCustomerId ?? undefined;
      }
    }
  }

  let productCode = optionsProductCode ?? defaultProductCode;

  for (let config of saleProductCodes!) {
    if (config.defaultLotRange && lotNumber) {
      let lotNumberInt = parseInt(lotNumber);

      if (config.defaultLotRange.min <= lotNumberInt) {
        if (config.defaultLotRange.max >= lotNumberInt) {
          productCode = config.code;
        }
      }
    }
  }

  let productCodeConfig = saleProductCodes?.find(
    (config) => config.code === productCode
  )!;

  let defaultCurrency =
    productCodeConfig?.defaultCurrency ||
    marketDefaultSettings?.defaultCurrency ||
    "GBP";

  let defaultUnitOfSale =
    productCodeConfig?.defaultUnitOfSale ||
    marketDefaultSettings?.defaultUnitOfSale ||
    "Per Item";

  if (
    !saleProductCodes ||
    !defaultCurrency ||
    !defaultUnitOfSale ||
    !defaultProductCode ||
    !productCode ||
    !currentUid ||
    !saleId ||
    !marketId
  ) {
    console.error("Missing data to create a new lot", {
      saleProductCodes,
      defaultCurrency,
      defaultUnitOfSale,
      defaultProductCode,
      productCode,
      currentUid,
      saleId,
      marketId,
    });
    throw new Error(`Missing data to create a new lot`);
  }

  let seller: Customer | null = null;
  let customerDefaults: {
    [attributekey: string]: AttributeValueType;
  } = {};
  if (options?.sellerCustomerId) {
    let seller = await getOrLoadCustomer(ctx, options?.sellerCustomerId);
    let defaultArrs = seller.attributeDefaultsSeller || {};
    let attributeKeys = Object.keys(defaultArrs);
    for (let k of attributeKeys) {
      if (defaultArrs[k].length > 0) {
        // these defauts are in an array with the most recently used at the top
        customerDefaults[k] = defaultArrs[k][0];
      }
    }
  }
  if (options?.buyerCustomerId) {
    let buyer = await getOrLoadCustomer(ctx, options?.buyerCustomerId);
    let defaultArrs = buyer.attributeDefaultsBuyer || {};
    let attributeKeys = Object.keys(defaultArrs);
    for (let k of attributeKeys) {
      if (defaultArrs[k].length > 0) {
        // these defauts are in an array with the most recently used at the top
        customerDefaults[k] = defaultArrs[k][0];
      }
    }
  }

  let attibuteDefaults = {
    ...customerDefaults,
    ...productCodeConfig?.attributeDefaults,
    ...sale?.attributeDefaults,
  };

  let allowedAttributesForProductCode =
    sale!.attributeSetByProductCode[productCode] || [];

  let lotAttributeDefaults = Object.keys(attibuteDefaults)
    .filter((k) => !k.startsWith("@"))
    .filter((k) => allowedAttributesForProductCode?.includes(k))
    .reduce((acc, key) => {
      acc[key] = attibuteDefaults[key];
      return acc;
    }, {} as Record<string, string | number | boolean | Timestamp | Media>);

  let itemAttributeDefaults = Object.keys(attibuteDefaults)
    .filter((k) => k.startsWith("@"))
    .filter((k) => allowedAttributesForProductCode?.includes(k))
    .reduce((acc, key) => {
      acc[key] = attibuteDefaults[key];
      return acc;
    }, {} as Record<string, string | number | boolean | Timestamp | Media>);

  let attrs = sale?.attributeSetByProductCode[productCode] ?? [];
  if (attrs.find((a) => a === "dateOfMovementIn")) {
    lotAttributeDefaults["dateOfMovementIn"] = Timestamp.now();
  }

  let sellerInfo: Pick<Lot, "sellerCustomerId" | "seller"> = {
    sellerCustomerId: null,
    seller: {
      isSet: false,
    },
  };

  // Add in the seller info if it's provided
  if (optionsSellerCustomerId) {
    // Try finding the seller in the sale first
    let shortDetails = await createShortCustomerDetails(
      ctx,
      optionsSellerCustomerId
    );
    sellerInfo = {
      sellerCustomerId: optionsSellerCustomerId,
      seller: shortDetails,
    };
  }

  let buyerInfo: Pick<Lot, "buyerCustomerId" | "buyer"> = {
    buyerCustomerId: null,
    buyer: {
      isSet: false,
    },
  };

  // Add in the seller info if it's provided
  if (optionsBuyerCustomerId) {
    // Try finding the seller in the sale first
    let shortDetails = await createShortCustomerDetails(
      ctx,
      optionsBuyerCustomerId
    );
    buyerInfo = {
      buyerCustomerId: optionsBuyerCustomerId,
      buyer: shortDetails,
    };
  }

  let lotAttributesFromPrefill = {} as Record<
    string,
    string | number | boolean | Timestamp | Media
  >;
  let itemAttributesFromPrefill = {} as Record<
    string,
    string | number | boolean | Timestamp | Media
  >;

  if (options?.prefillFromPreviousLot) {
    let previousLotId = options?.prefillFromPreviousLotId;
    let previousLot;
    if (previousLotId) {
      previousLot = findLot(lots, previousLotId);
      if (!previousLot) {
        throw new Error(
          `No previous lot found with ID ${previousLotId}. Prefilling from previous lot is not possible.`
        );
      }
    } else {
      previousLot = findLastLot(lots);
    }

    // find the previous lot
    if (previousLot) {
      // Check the previous lot has the same product code and seller
      let shouldPrefillFromPreviousLot =
        previousLot.productCode === productCode &&
        previousLot.sellerCustomerId === sellerInfo.sellerCustomerId;

      if (shouldPrefillFromPreviousLot) {
        let prefillableAttributeConfigsForProductCode = (
          sale!.attributeSetByProductCode[productCode] || []
        )
          .map((attrId) => {
            return sale!.attributeSet.find(
              (attr) => attr.id === attrId
            ) as AttributeDefinition;
          })
          .filter((attr) => attr?.prefillableFromPreviousLot === true);

        for (let attr of prefillableAttributeConfigsForProductCode!) {
          if (attr.id.startsWith("@")) {
            // item level attribute
            let prevItems = Object.values(previousLot.itemMap)
              .sort((a, b) => a.index - b.index)
              .reverse();
            let prevValue = prevItems.reduce((acc, item) => {
              // get the first value that is not empty
              let value = item.attributes[attr.id];
              if (acc === undefined && value !== undefined && value !== null) {
                acc = value;
              }
              return acc;
            }, undefined);

            if (prevValue !== undefined) {
              itemAttributesFromPrefill[attr.id] = prevValue;
            }
          } else {
            // lot level attribute
            let prevValue = previousLot.attributes[attr.id];
            if (prevValue !== undefined) {
              lotAttributesFromPrefill[attr.id] = prevValue;
            }
          }
        }
      }
    }
  }

  let lotId = autoId();
  let items = {} as { [itemId: string]: LotItem };

  hasNoItemLevelAttributes = !attrs.every((a) => a.startsWith("@"));

  if (hasNoItemLevelAttributes && Object.keys(items).length === 0) {
    let newItem: LotItem = {
      id: autoId(),
      createdAt: serverTimestamp() as unknown as Timestamp,
      updatedAt: serverTimestamp() as unknown as Timestamp,
      updatedBy: currentUid,
      index: 10,
      attributes: {
        ...itemAttributeDefaults,
        ...options?.attributes,
      },
      notes: [],
      metadata: options?.metadata ?? {},
    };

    items = {
      [newItem.id]: newItem,
    };
  }

  let newLot: Lot = {
    id: lotId,
    saleId: saleId,
    marketId: marketId,

    createdAt: serverTimestamp() as Timestamp,
    updatedAt: serverTimestamp() as Timestamp,
    updatedBy: currentUid,

    sellerCustomerId: sellerInfo.sellerCustomerId,
    seller: sellerInfo.seller,

    buyerCustomerId: buyerInfo.buyerCustomerId,
    buyer: buyerInfo.buyer,

    group: group ?? autoId(),

    productCode: productCode,
    superType: productCodeConfig.superType,

    itemMap: items,

    attributes: {
      ...lotAttributeDefaults,
      ...lotAttributesFromPrefill,
      ...options?.attributes,
    },

    // default values that should be applied to new items
    itemAttributeDefaults: {
      ...itemAttributeDefaults,
      ...itemAttributesFromPrefill,
    },

    buyerInvoiceId: null,
    sellerInvoiceId: null,

    index: index,
    currency: defaultCurrency,
    unitOfSale: defaultUnitOfSale,

    metadata: options?.metadata || {},
  };
  if (lotNumber !== undefined) {
    newLot.lotNumber = lotNumber;
  }

  if (options && "startAt" in options && options.startAt) {
    newLot.startAt = options.startAt;
  }

  if (options && "endAt" in options && options.endAt) {
    newLot.endAt = options.endAt;
  }

  if (
    options &&
    "unitPriceInCents" in options &&
    !isNaN(options.unitPriceInCents as number)
  ) {
    newLot.unitPriceInCents = options.unitPriceInCents;

    // If we're setting the unitPrice and the lot start and end times are not set then we need to set them
    if (!newLot.startAt) {
      newLot.startAt = serverTimestamp() as Timestamp;
    }
    if (!newLot.endAt) {
      newLot.endAt = serverTimestamp() as Timestamp;
    }
  }

  if (options) {
    //  startingPriceInCents?: number;
    // reservePriceInCents?: number;
    // remarks?: string;
    // buyerCasual?: string;
    if (
      "startingPriceInCents" in options &&
      !isNaN(options.startingPriceInCents as number)
    ) {
      newLot.startingPriceInCents = options.startingPriceInCents;
    }
    if (
      "reservePriceInCents" in options &&
      !isNaN(options.reservePriceInCents as number)
    ) {
      newLot.reservePriceInCents = options.reservePriceInCents;
    }
    if ("remarks" in options && options.remarks !== undefined) {
      newLot.remarks = options.remarks;
    }
    if ("buyerCasual" in options && options.buyerCasual !== undefined) {
      newLot.buyerCasual = options.buyerCasual;
    }
  }

  if (hasNoItemLevelAttributes && Object.keys(newLot.itemMap).length === 0) {
    // We need to create a lot item
    let item = await createLotItem(ctx, newLot, {
      doNotWriteToFirestore: true,
    });

    newLot.itemMap = {
      [item.id]: item,
    };
  }

  if (doNotWriteToFirestore === true) {
    // skip saving
    return newLot;
  } else {
    return await saveLotToFirestore({
      ctx,
      lot: newLot,
    });
  }
}

async function saveLotToFirestore({
  ctx,
  lot,
  batch,
  transaction,
}:
  | {
      ctx: FullSaleContext;
      lot: Lot;
      batch?: never;
      transaction?: Transaction;
    }
  | {
      ctx: FullSaleContext;
      lot: Lot;
      batch?: WriteBatch;
      transaction?: never;
    }) {
  let { firestore, currentUid, marketId, saleId } = ctx;

  if (!currentUid) throw new Error(`No current user`);
  if (!marketId) throw new Error(`No market id`);
  if (!saleId) throw new Error(`No sale id`);

  let docPath = `markets/${marketId}/sales/${saleId}/lots/${lot.id}`;
  let docRef = doc(firestore, docPath);

  let lotToSave = {
    ...lot,

    marketId: ctx.marketId,
    saleId: ctx.saleId,

    createdAt: serverTimestamp() as Timestamp,
    updatedAt: serverTimestamp() as Timestamp,
    updatedBy: currentUid,
  };
  if (transaction) {
    transaction.set(docRef, lotToSave);
    return lotToSave as Lot;
  } else if (batch) {
    batch.set(docRef, lotToSave);
    // not too much we can to about the timestamps here
    return lotToSave as Lot;
  } else {
    await setDoc(docRef, lotToSave);
    // ensures timestamps will be correct
    return (await getDoc(docRef)).data() as Lot;
  }
}

/***
 * Returns the lot with the highest index
 * @param lots
 * @param prefillFromPreviousLotId -the Lot ID to start from
 */
export function findLot(lots: Lot[], prefillFromPreviousLotId: string) {
  // find the last lot of that product code if its set in params
  const foundLot = lots.find((lot) => lot.id === prefillFromPreviousLotId);
  if (foundLot) {
    return foundLot;
  }
}

/***
 * Returns the lot with the highest index
 * @param lots
 * @param productCode - optional filter to only look at lots with this product code
 */
export function findLastLot<T extends Pick<Lot, "index" | "productCode">>(
  lots: T[],
  productCode?: string
): T | null {
  // find the last lot of that product code if its set in params
  lots = lots.filter((lot) => {
    return productCode ? lot.productCode === productCode : true;
  });

  if (lots.length === 0) {
    return null;
  }

  let lastLot = lots[lots.length - 1];
  for (let lot of lots) {
    if (lot.index > lastLot.index) {
      lastLot = lot;
    }
  }
  return lastLot;
}

// moving lots places half way between the indexes. The larger the number the more likely
// the index is to land on a whole number
const indexSpacing = 10;
const startLotIndex = 10;
export function calculateNextLotIndex(
  lots: Pick<Lot, "index" | "productCode">[]
) {
  let maxIndex = findLastLot(lots)?.index ?? startLotIndex;
  let nextIndex = maxIndex + indexSpacing;
  return nextIndex;
}

export function calculateNextLotNumber(
  lots: Pick<
    Lot,
    "lotNumber" | "group" | "createdAt" | "id" | "productCode" | "index"
  >[],
  previousLotNumber?: string,
  productCode?: string,
  saleProductCodes?: ProductCodeConfiguration[] | null
) {
  let startLotNumber: string;
  if (saleProductCodes && productCode) {
    let productCodeConfig = saleProductCodes?.find(
      (config) => config.code === productCode
    )!;
    startLotNumber = productCodeConfig?.defaultLotRange?.min.toString() ?? "1";

    lots = lots.filter((lots) => lots.productCode === productCode);
  } else {
    startLotNumber = "1";
  }

  if (lots.length === 0) return startLotNumber;

  let lastLotNumber = previousLotNumber;

  if (previousLotNumber === undefined) {
    let orderedLots = [...lots].sort((a, b) => {
      return a.index - b.index;
    });

    // work our way back until we find a lot with a lot number
    for (let lot of orderedLots.reverse()) {
      if (lot.lotNumber) {
        lastLotNumber = lot.lotNumber;
        break;
      }
    }
  }

  if (lastLotNumber === undefined || lastLotNumber === null) {
    return startLotNumber;
  }

  // A lot number can either be a number or a number and a string like "1A"
  // If the last lot number is a number, then the next lot number should be the last lot number + 1
  // If the last lot number is a number and a string, then the next lot number should increment the alpha part of the string

  let nextLotNumber: string | null = nextLogicalLotNumber(lastLotNumber);

  // Check the next lot number to see if it's already in use
  let nextLotNumberInUse = isNumberInUse(lots, nextLotNumber);
  if (nextLotNumberInUse) {
    // let the user set the lot number
    nextLotNumber = null;

    // Just use the max lot number
    let lotsSorted = sortByLotNumber(lots);
    let lastLot = lotsSorted[lotsSorted.length - 1];

    nextLotNumber = nextLogicalLotNumber(lastLot.lotNumber ?? startLotNumber);
  }

  return nextLotNumber;
}

function isNumberInUse(lots: Pick<Lot, "lotNumber">[], lotNumber: string) {
  return lots.some((lot) => lot.lotNumber === lotNumber);
}

export function nextLogicalLotNumber(lastLotNumber: string): string {
  let nextLotNumber: string;

  let lastLotNumberAsNumber = parseInt(lastLotNumber);

  if (lastLotNumber === `${lastLotNumberAsNumber}`) {
    nextLotNumber = `${lastLotNumberAsNumber + 1}`;
  } else {
    let lastLotNumberAlpha = lastLotNumber
      .replace(/\d/g, "")
      .toLocaleUpperCase();
    let lastLotNumberNumber = parseInt(lastLotNumber.replace(/\D/g, ""));
    let nextLotNumberAlpha = String.fromCharCode(
      lastLotNumberAlpha.charCodeAt(0) + 1
    );
    nextLotNumber = lastLotNumberNumber + nextLotNumberAlpha;
  }

  return nextLotNumber;
}
