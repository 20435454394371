import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import {
  CheckCircleIcon,
  EnvelopeIcon,
  MapPinIcon,
  PencilIcon,
  PhoneIcon,
  PlusIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Field, Form, Formik } from "formik";
import { Fragment, useState } from "react";
import * as Yup from "yup";
import { Customer } from "../../../types";

interface TabProps {
  customer: Customer | null;
  marketId: string;
}

interface Contact {
  id: string;
  name?: string;
  email?: string;
  phone?: string;
  address?: string;
  martEyeId?: string; // if they have attached marteye account
  // notifications may be sent via email, push or sms
  notificationPreferences: {
    marketing: boolean; // send marketing emails
    accounts: boolean; // send invoices
  };
}

interface ContactFormValues {
  name: string;
  email: string;
  phone: string;
  address: string;
  martEyeId: string;
  notificationPreferences: {
    marketing: boolean;
    accounts: boolean;
  };
}

// Validation schema for contact form
const ContactSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string().email("Invalid email format"),
  phone: Yup.string(),
  address: Yup.string(),
  martEyeId: Yup.string(),
});

// Dummy data for contacts
let contacts: Contact[] = [
  {
    id: "contact1",
    name: "John Smith",
    // email: "john.smith@example.com",
    phone: "+44 7700 900123",
    // address: "123 High Street, London, SW1A 1AA",
    martEyeId: "user123",
    notificationPreferences: {
      marketing: true,
      accounts: true,
    },
  },
  {
    id: "contact2",
    name: "Sarah Jones",
    email: "sarah.j@example.com",
    phone: "+44 7700 900456",
    address: "45 Church Road, Manchester, M1 1AA",
    notificationPreferences: {
      marketing: false,
      accounts: true,
    },
  },
  {
    id: "contact3",
    name: "Mike Wilson",
    email: "mike.wilson@example.com",
    phone: "+44 7700 900789",
    address: "78 Queen Street, Edinburgh, EH2 1JF",
    martEyeId: "user456",
    notificationPreferences: {
      marketing: true,
      accounts: false,
    },
  },
  {
    id: "contact4",
    name: "Emma Brown",
    email: "emma.b@example.com",
    phone: "+44 7700 900321",
    address: "92 Castle Street, Cardiff, CF10 1BT",
    notificationPreferences: {
      marketing: false,
      accounts: false,
    },
  },
];

// Component to display the MartEye icon if a contact has a martEyeId
const MartEyeIcon = () => (
  <div className="inline-flex items-center justify-center rounded-full bg-blue-100 p-1">
    <span className="text-xs font-medium text-blue-800">ME</span>
  </div>
);

// Contact card component
const ContactCard = ({
  contact,
  onEdit,
}: {
  contact: Contact;
  onEdit: (contact: Contact) => void;
}) => {
  return (
    <div className="flex flex-col rounded-lg border border-gray-200 bg-white p-4 shadow-sm">
      <div className="mb-2 flex items-center justify-between ">
        <h3 className="text-lg font-semibold text-gray-900">
          {contact.name || "Unnamed Contact"}
          {contact.martEyeId && (
            <span className="ml-2">
              <MartEyeIcon />
            </span>
          )}
        </h3>
        <button
          onClick={() => onEdit(contact)}
          className="rounded p-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
        >
          <PencilIcon className="h-5 w-5" />
        </button>
      </div>

      <div className="space-y-2 text-sm text-gray-600 flex-grow">
        {contact.email && (
          <div className="flex items-center">
            <EnvelopeIcon className="mr-2 h-4 w-4" />
            <span>{contact.email}</span>
          </div>
        )}
        {contact.phone && (
          <div className="flex items-center">
            <PhoneIcon className="mr-2 h-4 w-4" />
            <span>{contact.phone}</span>
          </div>
        )}
        {contact.address && (
          <div className="flex items-center">
            <MapPinIcon className="mr-2 h-4 w-4" />
            <span>{contact.address}</span>
          </div>
        )}
      </div>

      <div className="mt-4 border-t border-gray-100 pt-3">
        <div className="flex items-center justify-between text-xs">
          <div>
            <span className="font-medium">Notifications:</span>
          </div>
          <div className="space-x-2">
            <span
              className={`inline-flex items-center rounded-full px-2 py-1 font-medium ${
                contact.notificationPreferences.marketing
                  ? "bg-green-100 text-green-800"
                  : "bg-gray-100 text-gray-800"
              }`}
            >
              Marketing
            </span>
            <span
              className={`inline-flex items-center rounded-full px-2 py-1 font-medium ${
                contact.notificationPreferences.accounts
                  ? "bg-green-100 text-green-800"
                  : "bg-gray-100 text-gray-800"
              }`}
            >
              Accounts
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

// Contact Form Dialog
const ContactFormDialog = ({
  open,
  setOpen,
  contact,
  onSave,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  contact: Contact | null;
  onSave: (values: ContactFormValues) => void;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<
    Array<{ id: string; name: string; email?: string; phone?: string }>
  >([]);

  // Mock function to search for MartEye users
  const searchMartEyeUsers = (term: string) => {
    if (!term || term.length < 3) {
      setSearchResults([]);
      return;
    }

    // Simulate API search with a delay
    setTimeout(() => {
      const mockResults = [
        {
          id: "user123",
          name: "John Smith",
          email: "john.smith@example.com",
          phone: "+44 7700 900123",
        },
        {
          id: "user456",
          name: "Mike Wilson",
          email: "mike.wilson@example.com",
          phone: "+44 7700 900789",
        },
        {
          id: "user789",
          name: "Jessica Parker",
          email: "j.parker@example.com",
          phone: "+44 7700 900999",
        },
      ].filter(
        (user) =>
          user.name.toLowerCase().includes(term.toLowerCase()) ||
          (user.email &&
            user.email.toLowerCase().includes(term.toLowerCase())) ||
          (user.phone && user.phone.includes(term))
      );
      setSearchResults(mockResults);
    }, 300);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setSearchTerm(value);
    searchMartEyeUsers(value);
  };

  const initialValues: ContactFormValues = contact
    ? {
        name: contact.name || "",
        email: contact.email || "",
        phone: contact.phone || "",
        address: contact.address || "",
        martEyeId: contact.martEyeId || "",
        notificationPreferences: {
          marketing: contact.notificationPreferences.marketing,
          accounts: contact.notificationPreferences.accounts,
        },
      }
    : {
        name: "",
        email: "",
        phone: "",
        address: "",
        martEyeId: "",
        notificationPreferences: {
          marketing: false,
          accounts: false,
        },
      };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setOpen(false)}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex items-start justify-between">
                  <DialogTitle
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {contact ? "Edit Contact" : "Add Contact"}
                  </DialogTitle>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="mt-4">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={ContactSchema}
                    onSubmit={(values) => {
                      onSave(values);
                      setOpen(false);
                    }}
                  >
                    {({ setFieldValue, values, errors, touched }) => (
                      <Form className="space-y-4">
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name
                          </label>
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                          {errors.name && touched.name && (
                            <p className="mt-1 text-xs text-red-600">
                              {errors.name}
                            </p>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email
                          </label>
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                          {errors.email && touched.email && (
                            <p className="mt-1 text-xs text-red-600">
                              {errors.email}
                            </p>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone
                          </label>
                          <Field
                            type="text"
                            name="phone"
                            id="phone"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                          {errors.phone && touched.phone && (
                            <p className="mt-1 text-xs text-red-600">
                              {errors.phone}
                            </p>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="address"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Address
                          </label>
                          <Field
                            as="textarea"
                            name="address"
                            id="address"
                            rows={3}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                          {errors.address && touched.address && (
                            <p className="mt-1 text-xs text-red-600">
                              {errors.address}
                            </p>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="martEyeSearch"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Link MartEye Account
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              id="martEyeSearch"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              placeholder="Search by name, email or phone"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          {searchResults.length > 0 && (
                            <div className="mt-2 max-h-40 overflow-y-auto rounded-md border border-gray-300 bg-white">
                              <ul className="divide-y divide-gray-200">
                                {searchResults.map((user) => (
                                  <li
                                    key={user.id}
                                    className="cursor-pointer p-2 hover:bg-gray-50"
                                    onClick={() => {
                                      setFieldValue("martEyeId", user.id);
                                      setSearchTerm(user.name);
                                      setSearchResults([]);
                                    }}
                                  >
                                    <div className="flex items-center justify-between">
                                      <div className="flex items-center">
                                        <UserCircleIcon className="mr-2 h-5 w-5 text-gray-400" />
                                        <span className="font-medium">
                                          {user.name}
                                        </span>
                                      </div>
                                      <div className="text-xs text-gray-500">
                                        {user.email || user.phone}
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {values.martEyeId && (
                            <div className="mt-2 flex items-center rounded-md bg-blue-50 p-2 text-sm text-blue-800">
                              <MartEyeIcon />
                              <span className="ml-2">
                                MartEye account linked
                              </span>
                              <button
                                type="button"
                                className="ml-auto text-blue-500 hover:text-blue-700"
                                onClick={() => {
                                  setFieldValue("martEyeId", "");
                                  setSearchTerm("");
                                }}
                              >
                                <XMarkIcon className="h-4 w-4" />
                              </button>
                            </div>
                          )}
                        </div>

                        <div>
                          <h4 className="text-sm font-medium text-gray-700">
                            Notification Preferences
                          </h4>
                          <div className="mt-2 space-y-2">
                            <div className="flex items-center">
                              <Field
                                type="checkbox"
                                name="notificationPreferences.marketing"
                                id="marketing"
                                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                              />
                              <label
                                htmlFor="marketing"
                                className="ml-2 block text-sm text-gray-700"
                              >
                                Marketing emails
                              </label>
                            </div>
                            <div className="flex items-center">
                              <Field
                                type="checkbox"
                                name="notificationPreferences.accounts"
                                id="accounts"
                                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                              />
                              <label
                                htmlFor="accounts"
                                className="ml-2 block text-sm text-gray-700"
                              >
                                Account notifications (invoices, etc.)
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="mt-6 flex justify-end space-x-3">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          >
                            Save
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export function ContactsTab(props: TabProps) {
  let { customer, marketId } = props;
  let [dialogOpen, setDialogOpen] = useState(false);
  let [currentContact, setCurrentContact] = useState<Contact | null>(null);
  let [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleAddContact = () => {
    setCurrentContact(null);
    setDialogOpen(true);
  };

  const handleEditContact = (contact: Contact) => {
    setCurrentContact(contact);
    setDialogOpen(true);
  };

  const handleSaveContact = (values: ContactFormValues) => {
    if (currentContact && currentContact.id) {
      let contactId = currentContact.id;
      let contactIndex = contacts.findIndex((c) => c.id === contactId);
      if (contactIndex !== -1) {
        contacts[contactIndex] = {
          ...currentContact,
          name: values.name,
          email: values.email,
          phone: values.phone,
          address: values.address,
          martEyeId: values.martEyeId,
          notificationPreferences: values.notificationPreferences,
        };
      }
    } else {
      // Add new contact
      let newContact: Contact = {
        id: `contact${contacts.length + 1}`,
        name: values.name,
        email: values.email,
        phone: values.phone,
        address: values.address,
        martEyeId: values.martEyeId,
        notificationPreferences: values.notificationPreferences,
      };
      contacts.push(newContact);
    }

    // Show success message briefly
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);
  };

  return (
    <>
      <div className="p-4">
        {showSuccessMessage && (
          <div className="mb-4 rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-green-800">
                  Contact {currentContact ? "updated" : "added"} successfully
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-lg font-medium text-gray-900">Contacts</h2>
          <button
            type="button"
            onClick={handleAddContact}
            className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Add Contact
          </button>
        </div>

        {contacts.length === 0 ? (
          <div className="rounded-lg border-2 border-dashed border-gray-300 p-6 text-center">
            <UserCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No contacts
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by adding a new contact.
            </p>
            <div className="mt-6">
              <button
                type="button"
                onClick={handleAddContact}
                className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Add Contact
              </button>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {contacts.map((contact) => (
              <ContactCard
                key={contact.id}
                contact={contact}
                onEdit={handleEditContact}
              />
            ))}
          </div>
        )}
      </div>

      <ContactFormDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        contact={currentContact}
        onSave={handleSaveContact}
      />
    </>
  );
}
