import { memo } from "react";
import { Address } from "types";

// UI
import PostcoderLookup from "./postcoderLookup";
import GoogleLookup from "./googleLookup";

export interface PostCoderFieldProps {
  zip: string;
  country: string;
  marketId: string;
  label?: string;
  address1: string;
  onSelect: (address: Partial<Address>) => void;
  onChange: (address1: string) => void;
}

const AddressLookupField = (props: PostCoderFieldProps) => {
  const { country } = props;

  const shortCountry = country.substring(0, 2).toUpperCase();
  if (shortCountry === "GB" || shortCountry === "IE") {
    return <PostcoderLookup {...props} />;
  }

  return <GoogleLookup {...props} />;
};

export default memo(AddressLookupField);
