// search for a customer but use the sale context to organise the results
import { getDisplayName } from "@/data/customerUtils";
import { useFirestore } from "@/data/studio-react/firebase/useFirestore";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { useMarketId } from "@/data/useMartketId";
import {
  Timestamp,
  collection,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import Fuse from "fuse.js";
import { useEffect, useMemo, useRef, useState } from "react";

import {
  AddressWrapper,
  Customer,
  CustomerFromSearch,
  Lot,
  PhoneNumberWrapper,
} from "../../types";
import { useSearchDocuments } from "../SearchProvider";
import { SearchParams } from "meilisearch";

export interface CustomerItem {
  id: string;
  displayValue: string;
  fullName?: string;
  // true if this is just a placeholder for a customer that doesn't exist yet
  isCasual?: boolean;

  //Additional info
  address?: AddressWrapper;
  phoneNumbers?: PhoneNumberWrapper;

  // if coming back from meilisearch
  _hit?: CustomerFromSearch;
  _local?: boolean;
  _remote?: boolean;
  // if coming back from meilisearch
  _isExactMatch?: boolean;
  // matched upto the lentgh of the query exactly
  _isPartialExactMatch?: boolean;

  _isMatchedOnBidderNumber?: boolean;
}

export interface IndexItem {
  customer: CustomerItem;
  count: number;
  lastSeen: number;
}

function createCustomerItem(lot: Lot): CustomerItem | null {
  if (lot.buyer?.isSet) {
    return {
      id: lot.buyer.id!,
      displayValue: lot.buyer.accountNumber!,
      fullName: lot.buyer.displayName!,
      isCasual: false,
    };
  }

  if (lot.buyerCasual) {
    return {
      id: lot.buyerCasual,
      displayValue: lot.buyerCasual,
      isCasual: true,
    };
  }

  return null;
}

const fuseOptions = {
  keys: ["customer.displayValue", "customer.fullName"],

  sortFn: (a: any, b: any) => {
    // By score, then by count, then by last seen
    if (a.score === b.score) {
      if (a.item.count === b.item.count) {
        return b.item.lastSeen - a.item.lastSeen;
      }
      return b.item.count - a.item.count;
    }
    return a.score - b.score;
  },
};

export function useCasualCustomerFrecencyIndex() {
  let customerItemsRef = useRef(
    {} as { [customerItemId: string]: CustomerItem }
  );
  let perLotCountsRef = useRef(
    {} as {
      [lotId: string]: {
        customerItemId: string;
        lastSeen: number;
        count: number;
      };
    }
  );

  let { addListener: addLotChangesListener } =
    useStudioStream("sale:lots-info");
  let [fuse, setFuse] = useState(() => new Fuse<IndexItem>([], fuseOptions));
  let [index, setIndex] = useState([] as IndexItem[]);

  // Incrementally build a frecency index of customers
  useEffect(() => {
    let hasInitialised = false;
    let unsub = addLotChangesListener((changes) => {
      let indexUpdateRequired = false;

      let addedOrModified: Lot[];

      if (hasInitialised) {
        addedOrModified = [
          ...(changes?.added || []),
          ...(changes?.modified || []),
        ].map((change) => change.item);
      } else {
        addedOrModified = [...(changes.items || [])];
        hasInitialised = true;
      }

      addedOrModified.forEach((changedLot) => {
        let customerItem = createCustomerItem(changedLot);
        if (!customerItem?.isCasual) {
          return;
        }

        if (customerItem && customerItem.id) {
          if (
            !perLotCountsRef.current[changedLot.id] ||
            // Check they're not the same customer
            (perLotCountsRef.current[changedLot.id].customerItemId !==
              customerItem.id &&
              perLotCountsRef.current[changedLot.id].lastSeen !==
                (changedLot.updatedAt as Timestamp)?.toMillis())
          ) {
            perLotCountsRef.current[changedLot.id] = {
              customerItemId: customerItem.id,
              count: 1,
              lastSeen: (changedLot.updatedAt as Timestamp)?.toMillis(),
            };
            customerItemsRef.current[customerItem.id] = customerItem;

            indexUpdateRequired = true;
          }
        }
      });

      changes.removed.forEach((change) => {
        let customerItem = createCustomerItem(change.item);

        if (!customerItem?.isCasual) {
          return;
        }

        if (customerItem && customerItem.id) {
          delete perLotCountsRef.current[change.item.id];
          indexUpdateRequired = true;
        }
      });

      if (indexUpdateRequired) {
        // Merge
        let index = Object.values(perLotCountsRef.current).reduce(
          (acc, lotCount) => {
            if (!acc[lotCount.customerItemId]) {
              acc[lotCount.customerItemId] = {
                customer: customerItemsRef.current[lotCount.customerItemId],
                count: 0,
                lastSeen: 0,
              };
            }

            acc[lotCount.customerItemId].count += lotCount.count;
            acc[lotCount.customerItemId].lastSeen = Math.max(
              acc[lotCount.customerItemId].lastSeen,
              lotCount.lastSeen
            );

            return acc;
          },
          {} as {
            [customerId: string]: IndexItem;
          }
        );

        let idx = Object.values(index);
        idx.sort((a, b) => {
          if (a.count === b.count) {
            return b.lastSeen - a.lastSeen;
          }
          return b.count - a.count;
        });

        setIndex(idx);
        setFuse(new Fuse<IndexItem>(Object.values(index), fuseOptions));
      }
    });

    return () => {
      unsub();
    };
  }, [addLotChangesListener]);

  return { index, fuse };
}

export function useCustomerSearch(
  query: string | undefined,
  _allowCasual: boolean = true,
  meiliOptions: SearchParams = {}
) {
  let [localResults, setLocalResults] = useState([] as CustomerItem[]);
  let [remoteResults, setRemoteResults] = useState([] as CustomerItem[]);
  let q = query || "";

  let limit = meiliOptions.limit ?? 10;
  let offset = meiliOptions.offset ?? 0;

  let bidderNumberSearchResult = useBidderNumberExactMatch(q);

  // build a frecency index of casual customers
  let { index: localCustomerIndex, fuse } = useCasualCustomerFrecencyIndex();

  let remoteSearch = useSearchDocuments();

  // Use meiliseach to augment the index
  useEffect(() => {
    // The local index only contains casual customers
    if (!_allowCasual) {
      setLocalResults([]);
      return;
    }

    if (!q || q.length === 0) {
      setLocalResults(localCustomerIndex.map((item) => item.customer));
      return;
    }

    let r = fuse.search(q).map((result) => {
      let c: CustomerItem = {
        ...result.item.customer,
        _isExactMatch: result.item.customer.displayValue === q,
        _isPartialExactMatch: (
          result.item.customer.displayValue ?? ""
        ).startsWith(q),
      };

      return c;
    });

    setLocalResults(r);
  }, [q, fuse, localCustomerIndex, _allowCasual]);

  useEffect(() => {
    remoteSearch<CustomerFromSearch>(q, {
      filter: "_type = 'customer'",
      sort: q.length === 0 ? ["updatedAt:desc"] : [],
      limit: q.length === 0 ? 5 : limit,
      offset: offset,
    }).then((res) => {
      let customerItems: CustomerItem[] = res.hits.map((hit) => {
        let name = hit.displayName ?? hit.accountNumber ?? "-no name-";

        return {
          id: hit.id,
          displayValue: hit.accountNumber!,
          fullName: name,
          isCasual: false,
          _hit: hit,
          _remote: true,
          _isExactMatch: q === hit.accountNumber,
          _isPartialExactMatch: (hit.accountNumber ?? "").startsWith(q),
        };
      });
      setRemoteResults(customerItems);
    });
  }, [q, remoteSearch, offset, limit, setRemoteResults]);

  let results = useMemo(() => {
    if (bidderNumberSearchResult) {
      return [bidderNumberSearchResult];
    }

    let results = [...localResults, ...remoteResults];
    let seen = new Set<string>();
    return results
      .filter((r) => {
        if (seen.has(r.id)) {
          return false;
        }
        seen.add(r.id);
        return true;
      })
      .sort((a, b) => {
        // Bring the exact matches to the top
        if (a._isExactMatch && !b._isExactMatch) return -1;
        if (!a._isExactMatch && b._isExactMatch) return 1;
        return 0;
      });
  }, [localResults, remoteResults, bidderNumberSearchResult]);

  return results;
}

// Returns the customer if we have an exact match on the bidder number
export function useBidderNumberExactMatch(q: string) {
  let firestore = useFirestore();
  let marketId = useMarketId();

  let [customer, setCustomer] = useState<CustomerItem | null>(null);

  useEffect(() => {
    if (q.length === 0) {
      setCustomer(null);
      return;
    }

    // only search if we have a number
    let bidderNumberInt = parseInt(q);
    if (isNaN(bidderNumberInt)) {
      setCustomer(null);
      return;
    }

    let fireQuery = query(
      collection(firestore, `markets/${marketId}/customers`),
      where("bidderNumber", "==", bidderNumberInt),
      limit(1)
    );

    getDocs(fireQuery).then((snapshot) => {
      if (snapshot.size === 0) {
        setCustomer(null);
        return;
      }
      let doc = snapshot.docs[0];
      let data = doc.data() as Customer;
      setCustomer({
        id: doc.id,
        displayValue: data.accountNumber ?? "",
        fullName: `${getDisplayName(data)} (${data.bidderNumber})`,
        address: data.address,
        phoneNumbers: data.phoneNumber,

        isCasual: false,
        _isMatchedOnBidderNumber: true,
      });
    });
  }, [q, firestore, marketId]);

  return customer;
}
