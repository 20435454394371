import { getMarketBranding } from "documents/utils";
import { type jsPDF } from "jspdf";
import { InvoicePDFProps } from "../../../_importDocs";
import header from "../../sheep-tag-list/parts/header";
import footer from "../parts/footer";
import table from "../parts/table";

export default function DefaultInvoice(
  doc: jsPDF,
  props: InvoicePDFProps
): jsPDF {
  const { market, invoice } = props;
  const isDraft = props.isDraft ?? false;
  const isVoid = props.invoice.status === "void";

  let title = invoice.clientType === "Seller" ? "Statement" : "Invoice";
  if (isDraft) {
    title = `Draft ${title}`;
  }

  if (isVoid) {
    title = `Void ${title}`;
  }
  const margin = 10;

  const {
    dateFormat,
    textFillColor,
    buyerLegalText,
    sellerLegalText,
    tableHeaderFillColor,
    tableHeaderTextColor,
    tableStripeFillColor,
    tableStripeTextColor,
  } = getMarketBranding(market);

  header(doc, invoice, {
    output: false,
    dateFormat: dateFormat,
    title: title,
    margin: margin,
    textFillColor: textFillColor,
    logo: props.marketLogo,
    market: market,
  });

  footer(doc, invoice, {
    output: false,
    margin,
    textFillColor: textFillColor,
    legalText:
      invoice.clientType === "Buyer" ? buyerLegalText : sellerLegalText,
  });

  table(doc, invoice, {
    margin: margin,
    market,
    paymentLink: props.paymentLink,
    paymentQrCode: props.paymentQrCode,
    headerFillColor: tableHeaderFillColor,
    headerTextColor: tableHeaderTextColor,
    stripeFillColor: tableStripeFillColor,
    stripeTextColor: tableStripeTextColor,
  });

  header(doc, invoice, {
    output: true,
    dateFormat: dateFormat,
    title: title,
    margin: margin,
    textFillColor: textFillColor,
    logo: props.marketLogo,
    market: market,
  });

  footer(doc, invoice, {
    output: true,
    margin,
    textFillColor: textFillColor,
    legalText:
      invoice.clientType === "Buyer" ? buyerLegalText : sellerLegalText,
  });

  return doc;
}
