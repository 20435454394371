import { SVGProps } from "react";

interface IconProps {
  width?: number | string;
  height?: number | string;
  className?: string;
  fill?: string;
}

export function GavelIcon(props: IconProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 258 300"
      className={props.className}
      fill={props.className ? "currentColor" : "none"}
    >
      <g>
        <path d="M166.6,258l-14.6-108.8l-45.4,0L91.9,258c-0.8,17.4,20.6,32,38.1,32l0,0C147.5,290,167.3,275.5,166.6,258z" />
        <path
          d="M82,10.9c-6.3,0-11.5,5.1-11.5,11.5l0,111.3c0,6.3,5.1,11.5,11.5,11.5c6.3,0,11.5-5.1,11.5-11.5v0.1l71.4,0
		c0,6.3,5.2,11.4,11.5,11.4c6.3,0,11.5-5.1,11.5-11.5l0-111.3c0-6.3-5.1-11.5-11.5-11.5c-6.3,0-11.5,5.1-11.5,11.4l-71.4,0l0,0.1
		C93.5,16.1,88.4,10.9,82,10.9z"
        />
        <path
          d="M203.4,31.5l0,93.1c0,11.4,9.2,20.6,20.6,20.6c11.4,0,20.6-9.2,20.6-20.6l0-93.1c0-11.4-9.2-20.6-20.6-20.6
		C212.6,10.9,203.4,20.1,203.4,31.5z"
        />
        <path
          d="M13.9,31.5l0,93.1c0,11.4,9.2,20.6,20.6,20.6c11.4,0,20.6-9.2,20.6-20.6l0-93.1c0-11.4-9.2-20.6-20.6-20.6
		C23.1,10.9,13.9,20.1,13.9,31.5z"
        />
      </g>
    </svg>
  );
}

export function GavelDownIcon(props: IconProps) {
  // let activeColour = "#FFF27E"
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 13"
      className={props.className}
      fill={props.className ? "currentColor" : "none"}
    >
      <path d="M14.4261 4.57216L7.97363 5.41111V7.58794L14.4265 8.42348C15.284 8.46705 16 7.49781 15.9996 6.49739C15.9996 5.49697 15.2836 4.52816 14.4261 4.57173V4.57216Z" />
      <path d="M0.800538 8.9924C0.800538 9.35592 1.0535 9.65066 1.36586 9.65066L7.21086 9.64981C7.52285 9.64981 7.77582 9.35506 7.77582 8.99112C7.77582 8.62717 7.52285 8.33286 7.21049 8.33286H7.21306L7.21269 4.66564C7.52359 4.66394 7.77509 4.37005 7.77509 4.00738C7.77509 3.64387 7.52212 3.34912 7.20976 3.34912L1.36476 3.34998C1.05277 3.34998 0.799805 3.64472 0.799805 4.00866C0.799805 4.37133 1.05167 4.66522 1.3622 4.66693V8.33414H1.36513C1.05314 8.33414 0.800171 8.62888 0.800171 8.99283L0.800538 8.9924Z" />
      <path d="M1.8124 2.46018L6.76249 2.45933C7.32158 2.45933 7.77509 1.93093 7.77472 1.2795C7.77472 0.628074 7.32158 0.100098 6.76212 0.100098L1.81204 0.100952C1.25295 0.100952 0.799805 0.628928 0.799805 1.28078C0.799805 1.93221 1.25331 2.46061 1.8124 2.46018Z" />
      <path d="M1.81338 12.9001L6.76346 12.8993C7.32256 12.8993 7.77606 12.3709 7.7757 11.7194C7.7757 11.068 7.32219 10.5396 6.7631 10.54L1.81301 10.5409C1.25392 10.5409 0.800415 11.0693 0.800781 11.7207C0.800781 12.3722 1.25429 12.9006 1.81338 12.9001V12.9001Z" />
    </svg>
  );
}

export function StudioLogo(props: IconProps) {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 300 300">
      <path
        fill="#285B3F"
        d="M255.5,193H34.9v-75.2c0-5.5,4.5-10,10-10h220.6V183C265.5,188.6,261,193,255.5,193z"
      />
      <path
        fill="#0EA665"
        d="M143,278.4l116.1-80.9c4-2.8,6.4-7.4,6.4-12.3v-77.4L98.6,210.7c-12,7.4-19.2,20.4-19.2,34.5l0,0
		C79.4,277.9,116.2,297.1,143,278.4z"
      />
      <path
        fill="#3ECE8D"
        d="M98.6,210.7c-12,7.4-19.2,20.4-19.2,34.5v0c0,0.6,0,1.2,0.1,1.7c0.6-13.3,7.8-25.5,19.2-32.6l166.9-102.8v-3.6
		L98.6,210.7z"
      />
      <path
        fill="#9BDD66"
        d="M157.4,22.5L41.3,103.4c-4,2.8-6.4,7.4-6.4,12.3V193L201.8,90.2c12-7.4,19.2-20.4,19.2-34.5l0,0
		C221,23,184.2,3.8,157.4,22.5z"
      />
      <path
        fill="#CAF7A4"
        d="M216,36.1c2.2,5,3.5,10.5,3.5,16.6v0c0,14-7.3,27.1-19.2,34.5L34.9,189.1v3.9L201.8,90.2
		c12-7.4,19.2-20.4,19.2-34.5v0C221,48.4,219.2,41.8,216,36.1z"
      />
      <polygon
        fill="#193826"
        fillRule="evenodd"
        clipRule="evenodd"
        points="34.9,193 184.1,108 172.9,108 "
      />
      <polygon
        opacity="0.47"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#193826"
        points="265.5,107.8 91.8,193 127.3,193 "
      />
      <polygon
        fill="#193826"
        fillRule="evenodd"
        clipRule="evenodd"
        points="265.2,108 116,193 127.3,193 "
      />
    </svg>
  );
}

export function CreateSaleIcon(props: IconProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 11 13"
      className={props.className}
      fill={props.className ? "currentColor" : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.3002 0.0999756C1.87585 0.0999756 1.46888 0.268546 1.16882 0.568605C0.868766 0.868663 0.700195 1.27563 0.700195 1.69998V11.3C0.700195 11.7243 0.868766 12.1313 1.16882 12.4313C1.46888 12.7314 1.87585 12.9 2.3002 12.9H8.7002C9.12454 12.9 9.53151 12.7314 9.83157 12.4313C10.1316 12.1313 10.3002 11.7243 10.3002 11.3V4.43118C10.3001 4.00686 10.1315 3.59996 9.8314 3.29998L7.1002 0.568776C6.80021 0.268697 6.39331 0.100066 5.969 0.0999756H2.3002ZM6.3002 4.89998C6.3002 4.6878 6.21591 4.48432 6.06588 4.33429C5.91585 4.18426 5.71237 4.09998 5.5002 4.09998C5.28802 4.09998 5.08454 4.18426 4.93451 4.33429C4.78448 4.48432 4.7002 4.6878 4.7002 4.89998V6.49998H3.1002C2.88802 6.49998 2.68454 6.58426 2.53451 6.73429C2.38448 6.88432 2.3002 7.0878 2.3002 7.29998C2.3002 7.51215 2.38448 7.71563 2.53451 7.86566C2.68454 8.01569 2.88802 8.09998 3.1002 8.09998H4.7002V9.69998C4.7002 9.91215 4.78448 10.1156 4.93451 10.2657C5.08454 10.4157 5.28802 10.5 5.5002 10.5C5.71237 10.5 5.91585 10.4157 6.06588 10.2657C6.21591 10.1156 6.3002 9.91215 6.3002 9.69998V8.09998H7.9002C8.11237 8.09998 8.31585 8.01569 8.46588 7.86566C8.61591 7.71563 8.7002 7.51215 8.7002 7.29998C8.7002 7.0878 8.61591 6.88432 8.46588 6.73429C8.31585 6.58426 8.11237 6.49998 7.9002 6.49998H6.3002V4.89998Z"
      />
    </svg>
  );
}

export function InformationIcon(props: IconProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 192 512"
      className={props.className}
      fill={props.className ? "currentColor" : "none"}
    >
      <path d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z" />
    </svg>
  );
}

export function AddIcon(props: IconProps) {
  const width = props.width ? props.width : 21;
  const height = props.height ? props.height : 21;
  const fill = props.fill ? props.fill : "#DFF3E0";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 21C13.2848 21 15.9555 19.8938 17.9246 17.9246C19.8938 15.9555 21 13.2848 21 10.5C21 7.71523 19.8938 5.04451 17.9246 3.07538C15.9555 1.10625 13.2848 0 10.5 0C7.71523 0 5.04451 1.10625 3.07538 3.07538C1.10625 5.04451 0 7.71523 0 10.5C0 13.2848 1.10625 15.9555 3.07538 17.9246C5.04451 19.8938 7.71523 21 10.5 21ZM11.8125 6.5625C11.8125 6.2144 11.6742 5.88056 11.4281 5.63442C11.1819 5.38828 10.8481 5.25 10.5 5.25C10.1519 5.25 9.81806 5.38828 9.57192 5.63442C9.32578 5.88056 9.1875 6.2144 9.1875 6.5625V9.1875H6.5625C6.2144 9.1875 5.88056 9.32578 5.63442 9.57192C5.38828 9.81806 5.25 10.1519 5.25 10.5C5.25 10.8481 5.38828 11.1819 5.63442 11.4281C5.88056 11.6742 6.2144 11.8125 6.5625 11.8125H9.1875V14.4375C9.1875 14.7856 9.32578 15.1194 9.57192 15.3656C9.81806 15.6117 10.1519 15.75 10.5 15.75C10.8481 15.75 11.1819 15.6117 11.4281 15.3656C11.6742 15.1194 11.8125 14.7856 11.8125 14.4375V11.8125H14.4375C14.7856 11.8125 15.1194 11.6742 15.3656 11.4281C15.6117 11.1819 15.75 10.8481 15.75 10.5C15.75 10.1519 15.6117 9.81806 15.3656 9.57192C15.1194 9.32578 14.7856 9.1875 14.4375 9.1875H11.8125V6.5625Z"
        fill={fill}
      />
    </svg>
  );
}

export function GroupLotsIcon(props: IconProps) {
  const width = props.width ? props.width : 21;
  const height = props.height ? props.height : 21;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      className={props.className}
      width={width}
      height={height}
      fill="currentColor"
    >
      <path d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z" />
    </svg>
  );
}
export function UnGroupLotsIcon(props: IconProps) {
  const width = props.width ? props.width : 21;
  const height = props.height ? props.height : 21;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      className={props.className}
      width={width}
      height={height}
      fill="currentColor"
    >
      <path d="M408 56c-13.3 0-24 10.7-24 24s10.7 24 24 24H520c13.3 0 24-10.7 24-24s-10.7-24-24-24H408zm-96.5 77.2c-14.9-6.9-32.1-6.9-47 0L45.9 234.2C37.4 238.1 32 246.6 32 256s5.4 17.9 13.9 21.8l218.6 101c14.9 6.9 32.1 6.9 47 0l218.6-101c8.5-3.9 13.9-12.4 13.9-21.8s-5.4-17.9-13.9-21.8l-218.6-101zm13.4 274.7c-23.4 10.8-50.4 10.8-73.8 0l-152-70.2L45.9 362.2C37.4 366.1 32 374.6 32 384s5.4 17.9 13.9 21.8l218.6 101c14.9 6.9 32.1 6.9 47 0l218.6-101c8.5-3.9 13.9-12.4 13.9-21.8s-5.4-17.9-13.9-21.8l-53.2-24.6-152 70.2z" />
    </svg>
  );
}

export function SheepIcon(props: IconProps) {
  const width = props.width ? props.width : 21;
  const height = props.height ? props.height : 21;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      className={props.className}
      width={width}
      height={height}
      fill="currentColor"
    >
      <path d="M384 135.8V208c0 44.2 35.8 80 80 80h32c44.2 0 80-35.8 80-80V135.8l14.5 6.2c12.2 5.2 26.3-.4 31.5-12.6s-.4-26.3-12.6-31.5L561.6 77.4C544.7 50.2 514.5 32 480 32s-64.7 18.2-81.6 45.4L350.5 97.9c-12.2 5.2-17.8 19.3-12.6 31.5s19.3 17.8 31.5 12.6l14.5-6.2zm80-7.8a16 16 0 1 1 0 32 16 16 0 1 1 0-32zm48 16a16 16 0 1 1 32 0 16 16 0 1 1 -32 0zM352 175.4c-18.7-2.7-35.5-14.8-43.5-33.4c-6.9-16.1-5.7-33.5 1.8-47.9C299 76 278.9 64 256 64c-19.2 0-36.5 8.5-48.2 21.9C196.9 77.2 183 72 168 72c-30.1 0-55.3 20.8-62.2 48.8c-3.2-.5-6.5-.8-9.8-.8c-35.3 0-64 28.7-64 64c0 5.3 .7 10.5 1.9 15.5C13.7 210.3 0 231.5 0 256s13.7 45.7 33.9 56.5c-1.2 5-1.9 10.2-1.9 15.5c0 35.3 28.7 64 64 64c.5 0 1 0 1.5 0l17.6 93.9c2.8 15.1 16.1 26.1 31.5 26.1H176c17.7 0 32-14.3 32-32V426.3c11.7 13.3 28.9 21.7 48 21.7s36.3-8.4 48-21.7V480c0 17.7 14.3 32 32 32h29.4c15.4 0 28.6-11 31.4-26.1L414.5 392c.5 0 1 0 1.5 0c35.3 0 64-28.7 64-64c0-2.7-.2-5.4-.5-8H464c-61.9 0-112-50.1-112-112V175.4z" />{" "}
    </svg>
  );
}
export function HerdIcon(props: IconProps) {
  const width = props.width ? props.width : 21;
  const height = props.height ? props.height : 21;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      className={props.className}
      width={width}
      height={height}
      fill="currentColor"
    >
      <path d="M96 224v32V416c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V327.8c9.9 6.6 20.6 12 32 16.1V368c0 8.8 7.2 16 16 16s16-7.2 16-16V351.1c5.3 .6 10.6 .9 16 .9s10.7-.3 16-.9V368c0 8.8 7.2 16 16 16s16-7.2 16-16V343.8c11.4-4 22.1-9.4 32-16.1V416c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V256l32 32v49.5c0 9.5 2.8 18.7 8.1 26.6L530 427c8.8 13.1 23.5 21 39.3 21c22.5 0 41.9-15.9 46.3-38l20.3-101.6c2.6-13-.3-26.5-8-37.3l-3.9-5.5V184c0-13.3-10.7-24-24-24s-24 10.7-24 24v14.4l-52.9-74.1C496 86.5 452.4 64 405.9 64H272 256 192 144C77.7 64 24 117.7 24 184v54C9.4 249.8 0 267.8 0 288v17.6c0 8 6.4 14.4 14.4 14.4C46.2 320 72 294.2 72 262.4V256 224 184c0-24.3 12.1-45.8 30.5-58.9C98.3 135.9 96 147.7 96 160v64zM560 336a16 16 0 1 1 32 0 16 16 0 1 1 -32 0zM166.6 166.6c-4.2-4.2-6.6-10-6.6-16c0-12.5 10.1-22.6 22.6-22.6H361.4c12.5 0 22.6 10.1 22.6 22.6c0 6-2.4 11.8-6.6 16l-23.4 23.4C332.2 211.8 302.7 224 272 224s-60.2-12.2-81.9-33.9l-23.4-23.4z" />{" "}
    </svg>
  );
}

export function CreditCardIcon(props: IconProps) {
  const width = props.width ? props.width : 21;
  const height = props.height ? props.height : 21;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      className={props.className}
      width={width}
      height={height}
      fill="currentColor"
    >
      <path d="M64 32C28.7 32 0 60.7 0 96v32H576V96c0-35.3-28.7-64-64-64H64zM576 224H0V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V224zM112 352h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16z" />{" "}
    </svg>
  );
}

export function TemporaryCustomerIcon(props: IconProps) {
  const width = props.width ? props.width : 7;
  const height = props.height ? props.height : 7;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 20 20"
      version="1.1"
      viewBox="0 0 20 20"
      xmlSpace="preserve"
      width={width}
      height={height}
      className={props.className}
    >
      <path
        fill="#62B9EA"
        d="M12.3 12.2c0 .2-.1.5-.2.7-.1.2-.2.3-.4.4-.2.1-.3.2-.5.3-.2.1-.5.1-.8.1-.5 0-1-.1-1.4-.4-.4-.2-.6-.6-.8-1.1-.2-.6-.3-1.3-.3-2.2 0-.8.1-1.6.3-2.1.1-.6.4-.9.8-1.2.4-.3.8-.4 1.4-.4.3 0 .6 0 .9.1.2.1.4.2.5.3.1.1.3.3.3.5.1.2.2.4.2.7l.1.9h6.5l-.1-1c-.1-1.2-.4-2.2-.8-3.1-.6-.9-1.2-1.7-2-2.3-.8-.6-1.6-1.1-2.6-1.4-1-.3-2-.5-3.2-.5C8.6.5 7 .9 5.7 1.6c-1.4.7-2.5 1.8-3.3 3.3C1.6 6.3 1.2 8 1.2 10c0 2 .4 3.7 1.2 5.1.8 1.4 1.9 2.5 3.2 3.3 1.3.7 2.9 1.1 4.6 1.1 1.3 0 2.5-.2 3.6-.6 1-.4 1.9-1 2.6-1.7.7-.7 1.3-1.5 1.7-2.3.4-.8.6-1.7.7-2.5l.1-1h-6.4l-.2.8z"
      ></path>
    </svg>
  );
}

export function UnGroupAllIcon(props: IconProps) {
  const width = props.width ? props.width : 7;
  const height = props.height ? props.height : 7;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56 56"
      width={width}
      height={height}
      className={props.className}
    >
      <path
        fill="currentColor"
        d="m35.36 28.89l15.187-8.812c1.875-1.101 2.625-1.969 2.625-3.258c0-1.265-.75-2.133-2.625-3.234L32.195 2.946c-1.125-.657-2.039-.962-2.976-.962c-.938 0-1.852.305-2.977.961L15.578 9.11Zm14.437 23.508c.703.704 1.875.704 2.555 0a1.79 1.79 0 0 0 0-2.554L6.086 3.602c-.703-.704-1.852-.704-2.578 0c-.703.68-.68 1.875 0 2.554Zm-30.07-25.476L7.023 14.219c-1.289.89-1.78 1.617-1.78 2.672c0 1.218.679 2.039 2.483 3.093Zm9.492 16.312c.843 0 1.57-.398 2.554-.984l2.438-1.453l-2.766-2.766l-1.336.774c-.328.187-.633.328-.89.328c-.258 0-.563-.14-.89-.328L6.601 26.265c-.657.305-1.36 1.102-1.36 2.063c0 .727.422 1.524 1.29 2.04L26.663 42.25c.984.586 1.71.984 2.555.984m13.195-7.265l9.492-5.602c.891-.539 1.29-1.312 1.29-2.039c0-.96-.727-1.758-1.36-2.062L39.719 33.25ZM29.219 54.016c.843 0 1.57-.399 2.554-.985l9.258-5.437l-2.742-2.719l-8.18 4.734c-.328.188-.633.328-.89.328c-.258 0-.563-.14-.89-.328L6.601 37.047c-.657.328-1.36 1.101-1.36 2.062c0 .727.422 1.547 1.29 2.04L26.663 53.03c.984.586 1.71.985 2.555.985m19.968-11.25l2.72-1.618c.866-.492 1.288-1.312 1.288-2.039c0-.96-.726-1.734-1.359-2.062l-5.274 3.07Z"
      ></path>
    </svg>
  );
}

export function LineMdLoadingLoop(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeDasharray="15"
        strokeDashoffset="15"
        strokeLinecap="round"
        strokeWidth="2"
        d="M12 3C16.9706 3 21 7.02944 21 12"
      >
        <animate
          fill="freeze"
          attributeName="stroke-dashoffset"
          dur="0.3s"
          values="15;0"
        ></animate>
        <animateTransform
          attributeName="transform"
          dur="1.5s"
          repeatCount="indefinite"
          type="rotate"
          values="0 12 12;360 12 12"
        ></animateTransform>
      </path>
    </svg>
  );
}

export function MynauiInboxDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="M3.25 13h3.68a2 2 0 0 1 1.664.89l.812 1.22a2 2 0 0 0 1.664.89h1.86a2 2 0 0 0 1.664-.89l.812-1.22A2 2 0 0 1 17.07 13h3.68"></path>
        <path d="m5.45 4.11l-2.162 7.847A8 8 0 0 0 3 14.082V19a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4.918a8 8 0 0 0-.288-2.125L18.55 4.11A2 2 0 0 0 16.76 3H7.24a2 2 0 0 0-1.79 1.11M12.003 6L12 11.28"></path>
        <path d="M14.5 9.347L12 12L9.5 9.347"></path>
      </g>
    </svg>
  );
}

export function MynauiInboxUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="M3.25 13h3.68a2 2 0 0 1 1.664.89l.812 1.22a2 2 0 0 0 1.664.89h1.86a2 2 0 0 0 1.664-.89l.812-1.22A2 2 0 0 1 17.07 13h3.68"></path>
        <path d="m5.45 4.11l-2.162 7.847A8 8 0 0 0 3 14.082V19a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4.918a8 8 0 0 0-.288-2.125L18.55 4.11A2 2 0 0 0 16.76 3H7.24a2 2 0 0 0-1.79 1.11M12.003 12L12 6.72"></path>
        <path d="M14.5 8.653L12 6L9.5 8.653"></path>
      </g>
    </svg>
  );
}

export function CPHIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="M136.83 220.43a8 8 0 0 1-11.09 2.23A183.15 183.15 0 0 0 24 192a8 8 0 0 1 0-16a199.1 199.1 0 0 1 110.6 33.34a8 8 0 0 1 2.23 11.09M24 144a8 8 0 0 0 0 16a214.8 214.8 0 0 1 151.17 61.71a8 8 0 1 0 11.2-11.42A230.7 230.7 0 0 0 24 144m208 16a216.5 216.5 0 0 0-48.59 5.49q8.24 6.25 16 13.16A201.5 201.5 0 0 1 232 176a8 8 0 0 1 0 16c-6 0-11.93.29-17.85.86q8.32 8.67 15.94 18.14a8 8 0 1 1-12.48 10A247 247 0 0 0 24 128a8 8 0 0 1 0-16a265.4 265.4 0 0 1 48 4.38V80a8 8 0 0 1 3.2-6.4l64-48a8 8 0 0 1 9.6 0l64 48A8 8 0 0 1 216 80v32.5c5.31-.32 10.64-.5 16-.5a8 8 0 0 1 0 16a246.3 246.3 0 0 0-84.26 14.69q9.44 5 18.46 10.78A232.2 232.2 0 0 1 232 144a8 8 0 0 1 0 16m-103.93-26.73A261.5 261.5 0 0 1 168 119.81V96h-48v34c2.71 1 5.4 2.13 8.07 3.27"
      ></path>
    </svg>
  );
}

export function VideoMediaIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 55 45"
      {...props}
    >
      <path
        d="M11 0C4.92525 0 0 5.03691 0 11.25V33.75C0 39.9628 4.92525 45 11 45H44C50.0748 45 55 39.9628 55 33.75V11.25C55 5.03691 50.0748 0 44 0H11ZM20.9688 11.6893C21.9835 11.1321 23.3063 11.0886 24.4915 11.7773L35.6647 18.2812C39.3167 20.6016 39.4542 24.3141 35.6647 26.7188L24.4915 33.2212C22.121 34.5994 19.25 33.1847 19.25 30.2344V14.7656C19.25 13.2905 19.954 12.2467 20.9688 11.6893Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  );
}
