import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { PostCoderFieldProps } from "./index";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { convertGoogleAddress } from "./helpers";
import AddressComboBox from "./combobox";

const API_KEY = process.env.NEXT_PUBLIC_GOOGLE_PLACES_API;

export interface PlaceResult {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  geometry?: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
  name: string;
  business_status?: {
    name: string;
  };
}

const GoogleLookUp = ({
  zip,
  country,
  marketId,
  label = "Address 1",
  address1,
  onSelect,
  onChange,
}: PostCoderFieldProps) => {
  if (!API_KEY) {
    return null;
  }

  const options = useMemo(
    () => ({
      types: ["address"], // or ["geocode", "(regions)"], etc.
      fields: ["name", "address_components", "business_status"],
      componentRestrictions: country
        ? { country } // must be a valid ISO-2 country code
        : undefined,
    }),
    [country]
  );

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: API_KEY,
    options,
  });

  const selectOptions = useMemo(() => {
    if (!placePredictions) {
      return [];
    }

    // Want these in Array of { label: string, value: string } format
    return placePredictions.map((prediction) => {
      return {
        label: prediction.description,
        value: prediction.place_id,
      };
    });
  }, [placePredictions]);

  const getSelectedAddress = useCallback(
    (place_id: string) => {
      // Get the place details by place_id
      if (!placesService) {
        return;
      }

      placesService.getDetails(
        {
          placeId: place_id,
          fields: ["name", "address_components", "business_status", "geometry"],
        },
        (place: PlaceResult, status: string) => {
          if (status === "OK") {
            const convertedAddress = convertGoogleAddress(place);

            if (onSelect) {
              onSelect(convertedAddress);
            }
          }
        }
      );
    },
    [placePredictions, placesService, onSelect]
  );

  const getAddressPredictions = useCallback(
    (input: string) => {
      // If we have a country, use it
      if (country) {
        getPlacePredictions({ input, componentRestrictions: { country } });
      } else {
        // Otherwise, just get the predictions
        getPlacePredictions({ input });
      }
    },
    [getPlacePredictions, country]
  );

  return (
    <>
      <AddressComboBox
        label={label}
        options={selectOptions}
        onSelect={(value) => {
          if (value === "") {
            return;
          }

          getSelectedAddress(value as string);
        }}
        loading={isPlacePredictionsLoading}
        value={address1}
        setValue={(address1) => {
          onChange(address1);
          getAddressPredictions(address1);
        }}
      />
    </>
  );
};

export default GoogleLookUp;
