import { useMemo } from "react";
import { AttributeDefinition, Lot, Media, Sale } from "types";
import { useStudioStream } from "../studio-react/useStudioStream";

export type AttributeDefinitionWithMedia = AttributeDefinition & {
  assets: Media[];
};
export function useAssetsOnLot(lot: Lot | null | undefined) {
  let sale = useStudioStream("sale:sale");

  const allRemoteAssets: AttributeDefinitionWithMedia[] = useMemo(() => {
    if (!sale || !lot) {
      return [];
    }

    const attributes =
      getApplicableAttributes(sale, lot?.productCode || "") || [];

    const onlyAssets = attributes.filter(
      (a) => a.type === "media" || a.type === "mediaset"
    );
    // MAP ALL IN here
    return onlyAssets.map((attribute) => {
      // From Firebase
      const remoteAssets = lot?.attributes?.[attribute.id] || [];

      // Convert remoteAssets to array if it's an object
      const remoteAssetsArray = Array.isArray(remoteAssets)
        ? remoteAssets
        : remoteAssets
        ? [remoteAssets]
        : [];

      const assets = [...remoteAssetsArray];
      return {
        ...attribute,
        assets: assets,
      };
    });
  }, [lot, sale]);

  return allRemoteAssets;
}

export function getApplicableAttributes(sale: Sale, productCode: string) {
  if (!sale) {
    return null;
  }

  let attributeIds = sale.attributeSetByProductCode[productCode];
  if (!attributeIds) {
    return null;
  }
  return sale.attributeSet.filter((attr) => attributeIds.includes(attr.id));
}
