import { StatusButton } from "@/components/Buttons";
import { useInvoiceSheet } from "@/components/invoice-sheet/useInvoiceSheet";
import { RegisterPaymentDialog } from "@/components/payments/RegisterPayment";
import { formatAsCurrency } from "@/data/amounts";
import { classNames } from "@/data/classnames";
import { useCustomerInvoices } from "@/data/studio-react/loaders/useLoadSaleInvoices";
import useStudio from "@/data/studio-react/useStudio";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { Disclosure, Listbox, Menu, Transition } from "@headlessui/react";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/solid";
import {
  endOfMonth,
  endOfToday,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  format,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";
import { Timestamp } from "firebase/firestore";
import Link from "next/link";
import { Fragment, useRef, useState } from "react";
import { usePopper } from "react-popper";
import {
  Customer,
  Invoice,
  InvoiceLineItem,
  Payment,
  Payout,
} from "../../../types";

interface AccountsTabProps {
  customer: Customer | null;
  marketId: string;
}

const filterTypes = ["All", "Statements", "Invoices", "Payments", "Payouts"];

interface DateRange {
  name: string;
  value: {
    start: Date;
    end: Date;
  };
}

const dateRangeOptions: DateRange[] = [
  {
    name: "Today",
    value: { start: startOfToday(), end: endOfToday() },
  },
  {
    name: "Yesterday",
    value: { start: startOfYesterday(), end: endOfYesterday() },
  },
  {
    name: "This Week",
    value: {
      start: startOfWeek(startOfToday()),
      end: endOfWeek(startOfToday()),
    },
  },
  {
    name: "Last Week",
    value: {
      start: startOfWeek(subWeeks(startOfWeek(startOfToday()), 1)),
      end: endOfWeek(subWeeks(startOfWeek(startOfToday()), 1)),
    },
  },
  {
    name: "This Month",
    value: {
      start: startOfMonth(startOfToday()),
      end: endOfMonth(startOfToday()),
    },
  },
  {
    name: "Last Month",
    value: {
      start: startOfMonth(subMonths(startOfMonth(startOfToday()), 1)),
      end: endOfMonth(subMonths(startOfMonth(startOfToday()), 1)),
    },
  },
  {
    name: "This Year",
    value: {
      start: startOfYear(startOfToday()),
      end: endOfYear(startOfToday()),
    },
  },
  {
    name: "Last Year",
    value: {
      start: startOfYear(subYears(startOfYear(startOfToday()), 1)),
      end: endOfYear(subYears(startOfYear(startOfToday()), 1)),
    },
  },
  {
    name: "All Time",
    value: {
      start: new Date(0),
      end: new Date(),
    },
  },
];

export function AccountsTab(props: AccountsTabProps) {
  let { customer, marketId } = props;

  let [filterType, setFilterType] = useState(filterTypes[0]);
  let [queryLimit, setQueryLimit] = useState(100);
  let [dateRange, setDateRange] = useState(dateRangeOptions[2]);

  let [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  let [openPayoutDialog, setOpenPayoutDialog] = useState(false);

  let invoices =
    useCustomerInvoices(
      marketId,
      customer?.id,
      filterType === "Invoices"
        ? "Buyer"
        : filterType === "Statements"
        ? "Seller"
        : undefined,
      queryLimit,
      dateRange.value
    ).data ?? [];

  let paymentsInfo = useStudioStream("payments-info", marketId, {
    customerUid: customer?.id,
    from: Timestamp.fromDate(dateRange.value.start),
    to: Timestamp.fromDate(dateRange.value.end),
  });
  let payments = paymentsInfo.data ?? [];

  let payoutsInfo = useStudioStream("payouts-info", marketId, {
    customerUid: customer?.id,
    from: Timestamp.fromDate(dateRange.value.start),
    to: Timestamp.fromDate(dateRange.value.end),
  });
  let payouts = payoutsInfo.data ?? [];

  if (filterType === "Statements" || filterType === "Invoices") {
    payments = [];
    payouts = [];
  } else if (filterType === "Payments") {
    invoices = [];
    payouts = [];
  } else if (filterType === "Payouts") {
    invoices = [];
    payments = [];
  }

  let data = [
    ...invoices.map((invoice) => ({ type: "invoice", data: invoice })),
    ...payments.map((payment) => ({ type: "payment", data: payment })),
    ...payouts.map((payout) => ({ type: "payout", data: payout })),
  ].sort((a, b) => {
    let dateA = (a.data.createdAt as Timestamp)?.toMillis();
    let dateB = (b.data.createdAt as Timestamp)?.toMillis();

    if (!dateA || !dateB) {
      return 0;
    }

    return dateB - dateA;
  });

  return (
    <>
      <div className="flex flex-wrap gap-4 p-3">
        <div className="w-full">
          <div className="flex flex-wrap gap-4">
            <Listbox value={dateRange} onChange={setDateRange}>
              {({ open }) => (
                <>
                  <Listbox.Label className="sr-only">Date Range</Listbox.Label>
                  <div className="relative">
                    <Listbox.Button className="relative w-56 cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-martEye-400 sm:text-sm sm:leading-6">
                      <span className="block truncate">{dateRange.name}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {dateRangeOptions.map((option) => (
                          <Listbox.Option
                            key={option.name}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "bg-martEye-400 text-white"
                                  : "text-gray-900",
                                "relative cursor-default select-none py-2 pl-3 pr-9"
                              )
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  {option.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active
                                        ? "text-white"
                                        : "text-martEye-400",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>

            <div className="ml-auto flex gap-4">
              <button
                className="inline-flex items-center gap-x-1.5 rounded-md bg-martEye-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 hover:bg-martEye-500"
                onClick={() => {
                  setOpenPaymentDialog(true);
                }}
              >
                <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
                Payment
              </button>

              <button
                className="inline-flex items-center gap-x-1.5 rounded-md bg-martEye-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 hover:bg-martEye-500"
                onClick={() => {
                  setOpenPayoutDialog(true);
                }}
              >
                <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
                Payout
              </button>

              <span className="isolate inline-flex rounded-md shadow-sm">
                {filterTypes.map((filter, index) => (
                  <button
                    key={filter}
                    type="button"
                    className={classNames(
                      "relative inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium focus:z-10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 hover:bg-martEye-400 hover:text-white",
                      filterType === filter
                        ? "z-10 border-martEye-400 bg-white text-martEye-400"
                        : "bg-white text-martEye-700",
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === filterTypes.length - 1 ? "rounded-r-md" : ""
                    )}
                    onClick={() => setFilterType(filter)}
                  >
                    {filter}
                  </button>
                ))}
              </span>
            </div>
          </div>
        </div>

        <div className="w-full rounded-md bg-white">
          <div className=" divide divide-y">
            {data.map((item) => {
              if (item.type === "invoice") {
                return (
                  <InvoiceCell
                    key={item.data.id}
                    invoice={item.data as Invoice}
                    openPaymentDialog={() => setOpenPaymentDialog(true)}
                  />
                );
              }
              if (item.type === "payment") {
                return (
                  <PaymentCell
                    key={item.data.id}
                    payment={item.data as Payment}
                  />
                );
              }
              if (item.type === "payout") {
                return (
                  <PayoutCell key={item.data.id} payout={item.data as Payout} />
                );
              }
              return null;
            })}

            {invoices.length == 0 && (
              <div className="px-4 py-6 text-center text-sm font-bold">
                {filterType === "Both" && "No Statements or Invoices Found"}
                {filterType === "Buyer" && "No Invoices Found"}
                {filterType === "Seller" && "No Statements Found"}
                <span className="block text-xs font-normal">
                  For this given date range, please try another date range.
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <RegisterPaymentDialog
        open={openPaymentDialog || openPayoutDialog}
        closeDialog={() => {
          setOpenPaymentDialog(false);
          setOpenPayoutDialog(false);
        }}
        customerUid={customer?.id || null}
        isPayout={openPayoutDialog}
      />
    </>
  );
}
// Helper Functions
function groupItemsBySaleID(items: InvoiceLineItem[]) {
  const sales: any = {};
  for (let item of items) {
    const saleId: string = item.saleId ?? "";
    if (!sales[saleId]) {
      sales[saleId] = [];
    }
    sales[saleId].push(item);
  }

  return sales;
}

function PaymentCell(props: { payment: Payment }) {
  let payment = props.payment;
  let marketId = useStudioStream("session:marketId");
  let marketSettings = useStudioStream("marketDefaultSettings", marketId);
  let members = useStudioStream("members", marketId);

  let { voidPayment } = useStudio();

  let [loading, setLoading] = useState(false);

  // Menu
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  if (!marketSettings) {
    return null;
  }

  let canBeVoided = payment.status === "complete";

  return (
    <div className="flex w-full items-center justify-start gap-x-6 p-4 text-left text-martEye-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400">
      <div className="text-base font-bold">
        <span className="inline-flex items-center gap-2">
          Payment - {payment.method}{" "}
          {payment.reference ? `(${payment.reference})` : ""}
          <div className={loading ? "animate-bounce" : ""}>
            <StatusButton title={payment.status} />
          </div>
          <span className="text-xs font-normal text-gray-400">
            {payment.id}
          </span>
        </span>

        <span className="mt-1 block text-xs font-normal text-gray-400">
          {format(payment.transactionDate.toDate(), "E d MMM, yyyy")}
        </span>

        {payment.status === "void" ? (
          <>
            <span className="mt-1 block text-xs font-normal text-gray-400">
              Voided by{" "}
              {members.find((m) => m.uid === payment.voidedBy)?.displayName ??
                "no name given"}
            </span>
            <span className="mt-1 block text-xs font-normal text-gray-400">
              reason: "{payment.voidReason}"
            </span>
            <span className="mt-1 block text-xs font-normal text-gray-400">
              on {format(payment.voidedAt.toDate(), "E d MMM, yyyy")}
            </span>
          </>
        ) : null}
      </div>

      <div className="ml-auto flex flex-wrap items-center gap-x-2">
        <span className="text-base font-bold">
          {formatAsCurrency(
            marketSettings.defaultCurrency,
            payment.amountInCents
          )}
        </span>

        <Menu as="div" className="relative inline-block text-right">
          {({ open }) => (
            <>
              <div ref={setTargetElement} className="rounded-md shadow-sm">
                <Menu.Button className="z-30 block p-2.5 text-martEye-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 hover:text-martEye-500">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <div
                ref={popperElRef}
                style={styles.popper}
                {...attributes.popper}
                className="z-40"
              >
                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  beforeEnter={() => setPopperElement(popperElRef.current)}
                  afterLeave={() => setPopperElement(null)}
                  as={Fragment}
                >
                  <Menu.Items className="absolute right-0 z-40 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item disabled={!canBeVoided}>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-martEye-500 text-white"
                                : "bg-white text-gray-700",
                              "block px-4 py-2 text-sm",
                              !canBeVoided && "opacity-50 cursor-not-allowed"
                            )}
                            onClick={async () => {
                              if (!canBeVoided) {
                                return;
                              }

                              let voidReason = prompt(
                                "Please enter a reason for voiding this payout"
                              );

                              if (marketId && voidReason) {
                                try {
                                  setLoading(true);
                                  await voidPayment(
                                    marketId,
                                    payment.id,
                                    voidReason
                                  );
                                } finally {
                                  setLoading(false);
                                }
                              }
                            }}
                          >
                            Void Payout
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </div>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
}
function PayoutCell(props: { payout: Payout }) {
  let payout = props.payout;
  let marketId = useStudioStream("session:marketId");
  let marketSettings = useStudioStream("marketDefaultSettings", marketId);
  let members = useStudioStream("members", marketId);

  let { voidPayout, completePayout } = useStudio();

  let [loading, setLoading] = useState(false);

  // Menu
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  if (!marketSettings) {
    return null;
  }

  let canBeVoided = payout.status === "pending" || payout.status === "complete";
  let canBeCompleted = payout.status === "pending";

  return (
    <div className="flex w-full items-center justify-start gap-x-6 p-4 text-left text-martEye-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400">
      <div className="text-base font-bold">
        <span className="inline-flex items-center gap-2">
          Payout - {payout.method}{" "}
          {payout.reference ? `(${payout.reference})` : ""}
          <div className={loading ? "animate-bounce" : ""}>
            <StatusButton title={payout.status} />
          </div>
          <span className="text-xs font-normal text-gray-400">{payout.id}</span>
        </span>

        <span className="mt-1 block text-xs font-normal text-gray-400">
          {format(payout.transactionDate.toDate(), "E d MMM, yyyy")}
        </span>

        {payout.status === "void" ? (
          <>
            <span className="mt-1 block text-xs font-normal text-gray-400">
              Voided by{" "}
              {members.find((m) => m.uid === payout.voidedBy)?.displayName ??
                "no name given"}
            </span>
            <span className="mt-1 block text-xs font-normal text-gray-400">
              reason: "{payout.voidReason}"
            </span>
            <span className="mt-1 block text-xs font-normal text-gray-400">
              on {format(payout.voidedAt.toDate(), "E d MMM, yyyy")}
            </span>
          </>
        ) : null}
      </div>

      <div className="ml-auto flex flex-wrap items-center gap-x-2">
        <span className="text-base font-bold">
          {formatAsCurrency(
            marketSettings.defaultCurrency,
            payout.amountInCents
          )}
        </span>

        <Menu as="div" className="relative inline-block text-right">
          {({ open }) => (
            <>
              <div ref={setTargetElement} className="rounded-md shadow-sm">
                <Menu.Button className="z-30 block p-2.5 text-martEye-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 hover:text-martEye-500">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <div
                ref={popperElRef}
                style={styles.popper}
                {...attributes.popper}
                className="z-40"
              >
                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  beforeEnter={() => setPopperElement(popperElRef.current)}
                  afterLeave={() => setPopperElement(null)}
                  as={Fragment}
                >
                  <Menu.Items className="absolute right-0 z-40 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item disabled={!canBeCompleted}>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-martEye-500 text-white"
                                : "bg-white text-gray-700",
                              "block px-4 py-2 text-sm",
                              !canBeCompleted && "opacity-50 cursor-not-allowed"
                            )}
                            onClick={async () => {
                              if (!canBeCompleted) {
                                return;
                              }

                              if (marketId) {
                                try {
                                  setLoading(true);
                                  await completePayout(
                                    marketId,
                                    payout.id,
                                    null
                                  );
                                } finally {
                                  setLoading(false);
                                }
                              }
                            }}
                          >
                            Mark as Cleared
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item disabled={!canBeVoided}>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-martEye-500 text-white"
                                : "bg-white text-gray-700",
                              "block px-4 py-2 text-sm",
                              !canBeVoided && "opacity-50 cursor-not-allowed"
                            )}
                            onClick={async () => {
                              if (!canBeVoided) {
                                return;
                              }

                              let voidReason = prompt(
                                "Please enter a reason for voiding this payout"
                              );

                              if (marketId && voidReason) {
                                try {
                                  setLoading(true);
                                  await voidPayout(
                                    marketId,
                                    payout.id,
                                    voidReason,
                                    null
                                  );
                                } finally {
                                  setLoading(false);
                                }
                              }
                            }}
                          >
                            Void Payout
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </div>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
}

function InvoiceCell(props: {
  invoice: Invoice;
  openPaymentDialog: () => void;
}) {
  let invoice = props.invoice;
  let { voidInvoice } = useStudio();

  let [loading, setLoading] = useState(false);

  let marketId = useStudioStream("session:marketId");

  let { openInvoiceSheet } = useInvoiceSheet();

  let title: string =
    (invoice.clientType === "Buyer" ? "Invoice" : "Statement") +
    " " +
    invoice.invoiceNumber;

  let issuedDate = new Date(invoice.issuedAt.seconds * 1000);

  let invoiceTotal = new Intl.NumberFormat("en-gb", {
    style: "currency",
    currency: invoice.currency,
  }).format(invoice.finalTotalInCents / 100);

  let groupedInvoiceItems = groupItemsBySaleID(invoice.lineItems);

  // Menu
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  let canVoidInvoice = invoice.status !== "void";

  return (
    <Disclosure as="div" key={invoice.id} className="">
      {({ open }) => (
        <>
          <dt>
            <Disclosure.Button className="flex w-full items-center justify-start gap-x-6 p-4 text-left text-martEye-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400">
              <span>
                {open ? (
                  <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                ) : (
                  <ChevronRightIcon className="h-6 w-6" aria-hidden="true" />
                )}
              </span>

              <div className="text-base font-bold">
                <span
                  className={classNames(
                    "inline-flex items-center gap-2",
                    loading && "animate-bounce"
                  )}
                >
                  {title} <StatusButton title={invoice.status} />
                </span>

                <span className="mt-1 block text-xs font-normal text-gray-400">
                  {format(issuedDate, "E d MMM, yyyy")}
                </span>
              </div>

              <div className="ml-auto flex flex-wrap items-center gap-x-2">
                <span className="text-base font-bold">{invoiceTotal}</span>

                <Menu as="div" className="relative inline-block text-right">
                  {({ open }) => (
                    <>
                      <div
                        ref={setTargetElement}
                        className="rounded-md shadow-sm"
                      >
                        <Menu.Button className="z-30 block p-2.5 text-martEye-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 hover:text-martEye-500">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <div
                        ref={popperElRef}
                        style={styles.popper}
                        {...attributes.popper}
                        className="z-40"
                      >
                        <Transition
                          show={open}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                          beforeEnter={() =>
                            setPopperElement(popperElRef.current)
                          }
                          afterLeave={() => setPopperElement(null)}
                          as={Fragment}
                        >
                          <Menu.Items className="absolute right-0 z-40 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href={`#`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      openInvoiceSheet({
                                        invoiceId: invoice.id,
                                      });
                                    }}
                                    className={classNames(
                                      active
                                        ? "bg-martEye-500 text-white"
                                        : "bg-white text-gray-700",
                                      "block w-full px-4 py-2 text-right text-sm"
                                    )}
                                  >
                                    Open
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href={`/api/${marketId}/documents/invoice/${invoice?.id}`}
                                    target="_blank"
                                    className={classNames(
                                      active
                                        ? "bg-martEye-500 text-white"
                                        : "bg-white text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}
                                  >
                                    Download PDF
                                  </Link>
                                )}
                              </Menu.Item>
                              {/* <Menu.Item disabled={true}>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? "bg-martEye-500 text-white"
                                        : "bg-white text-gray-700",
                                      "block px-4 py-2 text-sm",
                                      "cursor-not-allowed"
                                    )}
                                  >
                                    Email PDF
                                  </a>
                                )}
                              </Menu.Item> */}
                              <Menu.Item disabled={!canVoidInvoice}>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? "bg-martEye-500 text-white"
                                        : "bg-white text-gray-700",
                                      "block px-4 py-2 text-sm",
                                      !canVoidInvoice &&
                                        "opacity-50 cursor-not-allowed",
                                      loading && "cursor-progress"
                                    )}
                                    onClick={async (e) => {
                                      e.preventDefault();

                                      if (!canVoidInvoice) {
                                        return;
                                      }

                                      setLoading(true);

                                      let voidReason = prompt(
                                        "Please enter a reason for voiding this invoice"
                                      );

                                      if (marketId && voidReason) {
                                        try {
                                          await voidInvoice(
                                            marketId,
                                            invoice.id,
                                            voidReason
                                          );
                                        } finally {
                                          setLoading(false);
                                        }
                                      }
                                    }}
                                  >
                                    Void
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </div>
                    </>
                  )}
                </Menu>
              </div>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="mt-2">
            <div>
              {Object.keys(groupedInvoiceItems).map((saleId) => {
                let saleName =
                  invoice.sales.find((sale) => sale.id === saleId)?.name ??
                  "Sale Name Not Found";

                let items = groupedInvoiceItems[saleId];

                return (
                  <div key={saleId} className="">
                    <div className=" bg-gray-100 p-4 text-sm text-gray-500">
                      <span className="font-bold ">{saleName}</span>
                    </div>

                    <div className="inline-block min-w-full align-middle">
                      <table className="min-w-full border-separate border-spacing-0">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-4 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                            >
                              Description
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-4 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                            >
                              Type
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-4 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                            >
                              Lot
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-4 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                            >
                              Qty
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.map((item: InvoiceLineItem) => {
                            let lineTotal = new Intl.NumberFormat("en-gb", {
                              style: "currency",
                              currency: invoice.currency,
                            }).format(item.totalAmountInCentsExVat / 100);

                            if (item.adjustmentConfig) {
                              lineTotal = "-" + lineTotal;
                            }

                            return (
                              <tr key={item.id}>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {item.description}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {item.superType}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {item?.metadata?.lotNumber ?? "-"}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {item.quantity}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
