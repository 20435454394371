import { Address } from "types";
import { type PostcoderAddress } from "@/pages/api/[marketId]/addressLookup";
import { type PlaceResult } from "./googleLookup";

// Helper function to convert Postcoder address to our Address type
export function convertPostcoderAddress(
  address: PostcoderAddress
): Partial<Address> {
  let convertedAddress: Partial<Address> = {
    address1: "",
    address2: "",
  };

  if (address.organisation) {
    convertedAddress.company = address.organisation;
  }

  if (address.premise) {
    convertedAddress.address1 = address.premise;
  }

  if (address.street) {
    // If the address1 is numeric, add a space before the street otherwise add a comma
    if (
      convertedAddress.address1 &&
      !isNaN(Number(convertedAddress.address1))
    ) {
      convertedAddress.address1 += " " + address.street;
    } else {
      convertedAddress.address1 += ", " + address.street;
    }
  }

  if (address.dependentlocality) {
    if (convertedAddress.address2) {
      convertedAddress.address2 += ", ";
    }

    convertedAddress.address2 += address.dependentlocality;
  }

  if (address.posttown) {
    convertedAddress.city = address.posttown;
  }

  if (address.county) {
    convertedAddress.province = address.county;
  }

  if (address.postcode) {
    convertedAddress.zip = address.postcode;
  }

  if (address.latitude && address.longitude) {
    convertedAddress.coordinates = {
      latitude: address.latitude,
      longitude: address.longitude,
    };
  }

  if (address.country) {
    switch (address.country) {
      case "England":
        convertedAddress.country = "GB-ENG";
        break;
      case "Scotland":
        convertedAddress.country = "GB-SCT";
        break;
      case "Wales":
        convertedAddress.country = "GB-WLS";
        break;
      case "Northern Ireland":
        convertedAddress.country = "GB-NIR";
        break;
      case "Ireland":
        convertedAddress.country = "IE";
        break;
    }
  }

  return convertedAddress;
}

export function convertGoogleAddress(address: PlaceResult) {
  const convertedAddress: Partial<Address> = {};

  if (address?.business_status?.name) {
    convertedAddress.company = address.business_status.name;
  }

  const address1 = getGoogleAddressPart(address.address_components, "address1");
  if (address1) {
    convertedAddress.address1 = address1;
  }

  const address2 = getGoogleAddressPart(address.address_components, "address2");
  if (address2) {
    convertedAddress.address2 = address2;
  }

  const province = getGoogleAddressPart(address.address_components, "province");
  if (province) {
    convertedAddress.province = province;
  }

  const city = getGoogleAddressPart(address.address_components, "city");
  if (city) {
    convertedAddress.city = city;
  }

  const zip = getGoogleAddressPart(address.address_components, "zip");
  if (zip) {
    convertedAddress.zip = zip;
  }

  if (address.geometry?.location) {
    const lattitude = address.geometry.location.lat();
    const longitude = address.geometry.location.lng();

    convertedAddress.coordinates = {
      latitude: lattitude,
      longitude: longitude,
    };
  }

  return convertedAddress;
}

export function getGoogleAddressPart(addressComponents: any, part: string) {
  switch (part) {
    case "address1":
      let address1 = "";

      let street_number = addressComponents.find((item: any) =>
        item.types.includes("street_number")
      );
      if (street_number) {
        address1 += street_number.long_name;
      }

      // farm name
      let premise = addressComponents.find((item: any) =>
        item.types.includes("premise")
      );
      if (premise) {
        address1 += " " + premise.long_name;
      }

      let route = addressComponents.find((item: any) =>
        item.types.includes("route")
      );
      if (route) {
        address1 += " " + route.long_name;
      }

      return address1;

    case "province":
      const province = addressComponents.find((item: any) =>
        item.types.includes("administrative_area_level_1")
      );

      const provinceName = province ? province.long_name : "";

      return provinceName;

    case "address2":
      let address2Parts = [];

      const administrative_area_level_2 = addressComponents.find((item: any) =>
        item.types.includes("administrative_area_level_2")
      );
      if (administrative_area_level_2) {
        address2Parts.push(administrative_area_level_2.long_name);
      }

      const sublocality = addressComponents.find((item: any) =>
        item.types.includes("sublocality")
      );
      if (sublocality) {
        address2Parts.push(sublocality.long_name);
      }

      let locality = addressComponents.find((item: any) =>
        item.types.includes("locality")
      );

      let postal_town1 = addressComponents.find((item: any) =>
        item.types.includes("postal_town")
      );

      if (postal_town1 && locality) {
        address2Parts.push(locality.long_name);
      }

      return address2Parts.join(", ");
    case "city":
      let postal_town = addressComponents.find((item: any) =>
        item.types.includes("postal_town")
      );

      let locality1 = addressComponents.find((item: any) =>
        item.types.includes("locality")
      );

      return postal_town?.long_name ?? locality1?.long_name ?? null;

    case "zip":
      let zip = addressComponents.find((item: any) =>
        item.types.includes("postal_code")
      );

      return zip?.long_name ?? null;

    default:
      return null;
  }

  return null;
}

export function getZipLabel(country: string) {
  switch (country) {
    case "GB":
    case "GB-ENG":
    case "GB-SCT":
    case "GB-WLS":
    case "GB-NIR":
      return "Postcode";
    case "IE":
      return "Eircode";
    default:
      return "Zip";
  }
}
