import CheckboxInput from "@/components/form/CheckboxInput";
import ComboBoxInput from "@/components/form/ComboboxInput";
import CustomerLookupCombobox, {
  toBuyerCustomerItem,
  toSellerCustomerItem,
} from "@/components/form/CustomerLook";
import { BuyersCard } from "@/components/sale-page/buyers/BuyersCard";
import { Lot, LotIssue, Media, Sale } from "../../types";
import { SellersCard } from "../sale-page/sellers/SellersCard";

import {
  LotAttributeController,
  useSaleAttributeControllers,
} from "@/data/attribute-definitions/attributeControls";

import useStudio from "@/data/studio-react/useStudio";
import format from "date-fns/format";
import { Timestamp, deleteField } from "firebase/firestore";
import { Fragment } from "react";
import TextInput from "../form/TextInput";
import { LotIssueAlert } from "./LotIssueAlert";

// Validation
import { lotValidationByPath, lotValidation } from "./LotValidation";
import { Button } from "../Buttons";
import { useUrlFragmentParamState } from "../Flyout/useUrlFragmentState";
import { MediaDisplay } from "../media/Media.ImageDisplay";
import { ArrowUpTrayIcon, StarIcon } from "@heroicons/react/24/solid";
import { useLotAttributeMenuOptions } from "./useLotAttributeMenuOptions.hook";

//Interfaces
interface OverviewTabProps {
  lot: Lot | null;
  sale: Sale | null;
  issues?: LotIssue[][];
}
interface GroupedAttributes {
  [key: string]: LotAttributeController[];
}
interface ItemInputProps {
  attribute: LotAttributeController;
  lot: Lot;
  sale: Sale;
  lotItemCount: number;
  validationIssuesByPath: { [key: string]: LotIssue[] };
}

//Main Component
export function OverviewTab(props: OverviewTabProps) {
  const { lot, sale } = props;

  const { sortedAttributeFields } = useLotAttributeMenuOptions(sale!, lot!);

  let { updateLot } = useStudio();
  let lotId = lot?.id;

  let lotItemCount = Object.keys(lot?.itemMap || {}).length;
  let superType = sale?.superTypes ?? "Sale";

  let attributes = useSaleAttributeControllers();

  // We should only show item level attributes if there isn't multiple items
  if (lotItemCount > 1) {
    attributes = attributes.filter((attr) => attr.definition.level !== "item");
  }

  let groupedAttributes = groupItemsByGroupID(attributes);

  // Sale
  let saleStartDate = (sale?.startsAt as Timestamp)?.toDate();

  // Validation
  let { lotIssues, validationIssuesByPath } = lotValidation(lot);

  if (!lot || !sale) {
    return null;
  }

  let isPreSale = lot.unitPriceInCents ? false : true;

  let availableOptions = ["Sold", "Unsold", "Rerun", "Resold"];
  // If the lot has a buyer and a seller invoice we can only mark it as sold
  if (lot.buyerInvoiceId) {
    availableOptions = ["Sold"];
  }

  // if the lot has a seller invoice we can no longer rerun
  if (lot.sellerInvoiceId) {
    availableOptions = ["Sold", "Resold"];
  }

  return (
    <>
      <>
        {lotIssues.length > 0 && (
          <div className="w-full gap-4 p-3">
            {lotIssues.map((issue) => {
              return (
                <Fragment key={issue.id}>
                  <LotIssueAlert issue={issue} />
                </Fragment>
              );
            })}
          </div>
        )}
      </>
      <div className="p-3 mt-3">
        <div className="w-full gap-4 md:flex md:flex-nowrap">
          <div className="flex shrink flex-wrap gap-4">
            <div className=" w-full rounded-md bg-white p-4">
              <h3 className="text-base font-medium text-martEye-400">
                Lot Details
              </h3>
              <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                <TextInput
                  label="Lot Number"
                  name="lotNumber"
                  type="text"
                  disabled={false}
                  value={lot?.lotNumber ?? ""}
                  onChange={(value) => {
                    updateLot(lotId!, {
                      lotNumber: `${value}`,
                    });
                  }}
                  required={true}
                  error={lotValidationByPath(lot, "lotNumber")}
                  isPreSale={isPreSale}
                />

                <ComboBoxInput
                  columns={1}
                  label="Sale Status"
                  name="saleStatus"
                  options={availableOptions}
                  value={lot?.saleStatus || ""}
                  onChange={(value) => {
                    updateLot(lotId!, {
                      saleStatus: value,
                    });
                  }}
                  isPreSale={isPreSale}
                  required={true}
                  allowRemove={false}
                />

                <ComboBoxInput
                  columns={1}
                  label="Product Code"
                  name="product-code"
                  value={lot?.productCode || ""}
                  options={sale?.availableProductCodes || []}
                  onChange={(value: string | null) => {
                    updateLot(lotId!, {
                      productCode: value,
                    });
                  }}
                  error={lotValidationByPath(lot, "productCode")}
                  isPreSale={isPreSale}
                />
              </div>
            </div>

            <div className=" w-full rounded-md bg-white p-4">
              <h3 className="text-base font-medium text-martEye-400">
                Sale Details
              </h3>

              <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                <TextInput
                  label="Total Price"
                  name="totalValueInCents"
                  type="currency"
                  disabled={true}
                  currency={lot?.currency}
                  value={
                    lot?.generated?.totalValueInCents
                      ? lot.generated.totalValueInCents / 100
                      : ""
                  }
                  onChange={(value) => {}}
                  isPreSale={isPreSale}
                />
                <TextInput
                  label="Unit Price"
                  name="unitPriceInCents"
                  type="currency"
                  currency={lot?.currency}
                  value={
                    lot?.unitPriceInCents ? lot.unitPriceInCents / 100 : ""
                  }
                  onChange={(value) => {
                    updateLot(lotId!, {
                      unitPriceInCents: value ? Number(value) * 100 : null,
                    });
                  }}
                  error={lotValidationByPath(lot, "unitPriceInCents")}
                  isPreSale={isPreSale}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
              {sortedAttributeFields.fields.map((group) => {
                return (
                  <div className="w-full rounded-md bg-white p-4" key={group}>
                    <h3 className="text-base font-medium text-martEye-400 capitalize">
                      {group}
                    </h3>

                    <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      {sortedAttributeFields.attributeFields[group].map(
                        (attribute) => {
                          const att = attributes.find(
                            (att) => att.definition.id === attribute.id
                          );
                          if (!att) {
                            return null;
                          }
                          return (
                            <div key={attribute.id}>
                              <ItemInput
                                attribute={att}
                                lot={lot!}
                                sale={sale!}
                                lotItemCount={lotItemCount}
                                validationIssuesByPath={validationIssuesByPath}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-4 w-full flex-none md:mt-0 md:w-[320px]">
            <div className="flex flex-wrap gap-4">
              <div className="w-full rounded-md bg-white p-4">
                <h3 className="text-base font-medium text-martEye-400">
                  Seller
                </h3>

                <div className="relative mt-2" id="sellerCustomerId">
                  <CustomerLookupCombobox
                    label=""
                    customerType="Seller"
                    value={toSellerCustomerItem(lot ? [lot] : [])}
                    onChange={async (value) => {
                      if (value?.isCasual) {
                        updateLot(lotId!, {
                          sellerCasual: value.displayValue || null,
                          sellerCustomerId: null,
                        });
                      } else {
                        updateLot(lotId!, {
                          sellerCasual: deleteField(),
                          sellerCustomerId: value?.id || null,
                        });
                      }
                    }}
                    disallowCasual={true}
                  />

                  <SellersCard
                    classnames="mt-4"
                    sellerCustomerId={lot?.sellerCustomerId || null}
                  />
                </div>
              </div>

              <div className="w-full rounded-md bg-white p-4">
                <h3 className="text-base font-medium text-martEye-400">
                  Buyer
                </h3>
                <div className="relative mt-2" id="buyerCustomerId">
                  <CustomerLookupCombobox
                    label=""
                    customerType="Buyer"
                    value={toBuyerCustomerItem(lot ? [lot] : [])}
                    onChange={async (value) => {
                      if (value?.isCasual) {
                        updateLot(lotId!, {
                          buyerCasual: value.displayValue || null,
                          buyerCustomerId: null,
                        });
                      } else {
                        updateLot(lotId!, {
                          buyerCasual: deleteField(),
                          buyerCustomerId: value?.id || null,
                        });
                      }
                    }}
                    disallowCasual={false}
                  />
                </div>

                <BuyersCard
                  classnames="mt-4"
                  buyerCustomerId={
                    lot?.buyerCustomerId ?? lot?.buyerCasual ?? null
                  }
                />
              </div>

              <div className="w-full rounded-md bg-white p-4">
                <h3 className="text-base font-medium text-martEye-400">
                  Sale Information
                </h3>

                <ul
                  role="list"
                  className="mt-4 mb-2 divide-y divide-gray-100 border-y border-gray-100"
                >
                  <li className="flex justify-between py-4 text-sm font-medium">
                    <span className="text-gray-400">Sale</span>
                    <span className="text-martEye-500">{superType}</span>
                  </li>
                  <li className="flex justify-between py-4 text-sm font-medium">
                    <span className="text-gray-400">Sale Name</span>
                    <span className="text-martEye-500">{sale?.name}</span>
                  </li>
                  <li className="flex justify-between py-4 text-sm font-medium">
                    <span className="text-gray-400">Sale Date</span>
                    <span className="text-martEye-500">
                      {saleStartDate
                        ? format(saleStartDate, "d LLL, yyyy")
                        : ""}
                    </span>
                  </li>
                  {sale?.location && (
                    <li className="flex justify-between py-4 text-sm font-medium">
                      <span className="text-gray-400">Ring</span>
                      <span className="text-martEye-500">{sale.location}</span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
// Input Component
const ItemInput = ({
  attribute,
  lot,
  lotItemCount,
  validationIssuesByPath,
}: ItemInputProps) => {
  let type: string = attribute.definition.type;
  let [tabIndex, selectTabIndex] = useUrlFragmentParamState("lt");

  if (attribute.definition.hasOwnProperty("allowedValues")) {
    type = "select";
  }

  let level = attribute.definition.level;
  let itemId = null as string | null;
  if (level === "item") {
    if (lotItemCount === 1) {
      itemId = Object.keys(lot.itemMap)[0];
    }
  }

  // Lets check to see if the attribute has a validation issue
  let path = `attributes.${attribute.definition.id}`;
  let validationIssues = validationIssuesByPath[path] || [];

  let error: boolean | LotIssue = false;

  if (validationIssues.length > 0) {
    error = validationIssues[0];
  }

  let hasPrice = lot.unitPriceInCents ?? null;

  let requiredField = hasPrice
    ? attribute.definition.requiredPreSale ||
      attribute.definition.requiredToCheckout
    : attribute.definition.requiredPreSale;

  let value = attribute.getValue(lot, itemId);

  if (attribute.isReadOnlyOnSaleSheet(lot) === true) {
    type = "readonly";
  }

  let isPreSale = lot.unitPriceInCents ? false : true;

  switch (type) {
    case "readonly":
      return (
        <TextInput
          id={`attributes.${attribute.definition.id}`}
          label={attribute.definition.name}
          name={attribute.definition.id}
          type="text"
          disabled={true}
          value={attribute.getDisplayValue(lot, null)}
          onChange={() => {}}
          error={error}
          isLoadingIn={attribute.isLoading(lot, null)}
          isPreSale={isPreSale}
        />
      );

    case "date":
    case "datetime":
    case "time":
    case "string":
    case "number":
      return (
        <>
          <TextInput
            id={`attributes.${attribute.definition.id}`}
            label={attribute.definition.name}
            name={attribute.definition.id}
            required={requiredField}
            type={type}
            value={value as string}
            onChange={(value) => {
              if (level === "item") {
                attribute.setItemValue(lot, itemId, value);
              } else {
                attribute.setValue(lot, value);
              }
            }}
            error={error}
            isLoadingIn={attribute.isLoading(lot, null)}
            isPreSale={isPreSale}
          />
        </>
      );
    case "select":
      return (
        <ComboBoxInput
          id={`attributes.${attribute.definition.id}`}
          columns={1}
          label={attribute.definition.name}
          name={attribute.definition.id}
          required={requiredField}
          value={value as string}
          options={attribute.definition.allowedValues || []}
          onChange={(value: string | null) => {
            if (level === "item") {
              attribute.setItemValue(lot, itemId, value);
            } else {
              attribute.setValue(lot, value);
            }
          }}
          error={error}
          isLoadingIn={attribute.isLoading(lot, null)}
          isPreSale={isPreSale}
        />
      );

    case "boolean":
      return (
        <>
          <CheckboxInput
            label={attribute.definition.name}
            helper={attribute.definition.description}
            name={attribute.definition.id}
            value={value as boolean}
            onChange={(value) => {
              if (level === "item") {
                attribute.setItemValue(lot, itemId, value);
              } else {
                attribute.setValue(lot, value);
              }
            }}
          />
        </>
      );

    case "media":
      // If there is not main image we don't show the media tab
      if (!value) {
        return null;
      }
      return (
        <div className="w-full">
          <div className="flex flex-col">
            <label className="w-full text-sm font-medium text-gray-900">
              {attribute.definition.name}
            </label>
            <label className="text-xs font-medium text-gray-600">
              {attribute.definition.description}
            </label>
          </div>
          {value ? (
            <div
              className="size-12"
              onClick={() => {
                selectTabIndex("2");
              }}
            >
              <MediaDisplay
                value={value as unknown as Media}
                smallIcons={true}
              />
            </div>
          ) : null}
        </div>
      );
    case "mediaset":
      return (
        <div className="w-full">
          <div className="flex flex-col">
            <label className="w-full text-sm font-medium text-gray-900">
              {attribute.definition.name}
            </label>
            <label className="text-xs font-medium text-gray-600">
              {attribute.definition.description}
            </label>
          </div>
          <div
            className="flex gap-2 hover:opacity-90 cursor-pointer"
            onClick={() => {
              selectTabIndex("2");
            }}
          >
            <div className="w-full grid gap-2 grid-cols-3">
              {(value as unknown as Media[])?.length ? (
                (value as unknown as Media[]).map((v, i) => (
                  <div className="size-12 ">
                    <MediaDisplay
                      value={v}
                      showInitialIcon={i === 0}
                      smallIcons={true}
                    />
                  </div>
                ))
              ) : (
                <Button
                  icon={
                    <ArrowUpTrayIcon className="h-5 w-5" aria-hidden="true" />
                  }
                  className="w-full col-span-3"
                  onClick={() => {
                    selectTabIndex("2");
                  }}
                >
                  Upload {attribute.definition.name}
                </Button>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return (
        <p>
          Unknown attribute type: {JSON.stringify(attribute.definition.type)}
        </p>
      );
  }
};

// Helper Functions
function groupItemsByGroupID(items: LotAttributeController[]) {
  const groups: GroupedAttributes = {};
  for (let item of items) {
    const groupId: string =
      item?.definition.lotDetailFlyoutGroup?.split("~")[0] ?? "Details";
    if (!groups[groupId]) {
      groups[groupId] = [];
    }
    groups[groupId].push(item);
  }

  //TODO Sort the typescript error

  // We then need to sort the groups by the order of the first item in the group
  for (let key in groups) {
    groups[key] = groups[key].sort((a: any, b: any) => {
      if (
        a.definition.lotDetailFlyoutGroup < b.definition.lotDetailFlyoutGroup
      ) {
        return -1;
      }
      if (
        a.definition.lotDetailFlyoutGroup > b.definition.lotDetailFlyoutGroup
      ) {
        return 1;
      }

      return 0;
    });
  }

  return groups;
}
