import { AttributeValueType } from "types";
import { cloudFunction } from "../helpers/cloudfunction";

interface MoveInOutData {
  marketId: string;
  lotIdents: { saleId: string; lotId: string }[];
}

export interface PreMoveInResponse {
  lotIdents: { saleId: string; lotId: string }[];
}

export interface MoveInOutResponse {
  files: {
    fileName: string;
    base64: string;
  }[];
  results: {
    saleId: string;
    lotId: string;
    status: "success" | "failed" | "warning" | "deleted" | "alreadyMoved";
    error?: string;
    message?: string;
    attributes?: {
      [key: string]: AttributeValueType;
    };
  }[];
}

/***
 * Takes in a list of lot idents and returns a list of all lots that are needed in the movement
 * Also removes any lots that aren't moveable
 */
export async function moveInPreflight(
  marketId: string | null,
  params: {
    lotIdents: { saleId: string; lotId: string }[];
  }
) {
  if (!marketId) throw new Error(`No market id`);

  const func = cloudFunction<MoveInOutData, PreMoveInResponse>(
    "movementsPreMoveIn"
  );

  return func({
    marketId,
    lotIdents: params.lotIdents,
  });
}

export async function moveOutPreflight(
  marketId: string | null,
  params: {
    lotIdents: { saleId: string; lotId: string }[];
  }
) {
  if (!marketId) throw new Error(`No market id`);

  const func = cloudFunction<MoveInOutData, PreMoveInResponse>(
    "movementsPreMoveOut"
  );

  return func({
    marketId,
    lotIdents: params.lotIdents,
  });
}

/***
 * Moves lots into the market using the relevant installed app
 */
export async function moveIn(
  marketId: string | null,
  params: {
    lotIdents: { saleId: string; lotId: string }[];
  }
) {
  if (!marketId) throw new Error(`No market id`);

  const func = cloudFunction<MoveInOutData, MoveInOutResponse>(
    "movementsMoveIn"
  );

  return func({
    marketId,
    lotIdents: params.lotIdents,
  });
}

export async function moveOut(
  marketId: string | null,
  params: {
    lotIdents: { saleId: string; lotId: string }[];
  }
) {
  if (!marketId) throw new Error(`No market id`);

  const func = cloudFunction<MoveInOutData, MoveInOutResponse>(
    "movementsMoveOut"
  );

  return func({
    marketId,
    lotIdents: params.lotIdents,
  });
}

export async function amendMoveIn(
  marketId: string | null,
  params: {
    lotIdents: { saleId: string; lotId: string }[];
  }
) {
  if (!marketId) throw new Error(`No market id`);

  const func = cloudFunction<MoveInOutData, MoveInOutResponse>(
    "movementsAmendIn"
  );

  return func({
    marketId,
    lotIdents: params.lotIdents,
  });
}

export async function deleteMoveIn(
  marketId: string | null,
  params: {
    lotIdents: { saleId: string; lotId: string }[];
  }
) {
  if (!marketId) throw new Error(`No market id`);

  const func = cloudFunction<MoveInOutData, MoveInOutResponse>(
    "movementsDeleteIn"
  );

  return func({
    marketId,
    lotIdents: params.lotIdents,
  });
}

export async function amendMoveOut(
  marketId: string | null,
  params: {
    lotIdents: { saleId: string; lotId: string }[];
  }
) {
  if (!marketId) throw new Error(`No market id`);

  const func = cloudFunction<MoveInOutData, MoveInOutResponse>(
    "movementsAmendOut"
  );

  return func({
    marketId,
    lotIdents: params.lotIdents,
  });
}
export async function deleteMoveOut(
  marketId: string | null,
  params: {
    lotIdents: { saleId: string; lotId: string }[];
  }
) {
  if (!marketId) throw new Error(`No market id`);

  const func = cloudFunction<MoveInOutData, MoveInOutResponse>(
    "movementsDeleteOut"
  );

  return func({
    marketId,
    lotIdents: params.lotIdents,
  });
}
