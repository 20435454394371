import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { Menu, Transition } from "@headlessui/react";
import {
  ArrowDownTrayIcon,
  BanknotesIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CreditCardIcon,
  DocumentTextIcon,
  FunnelIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/24/outline";
import { format, parseISO, subDays, subYears } from "date-fns";
import Link from "next/link";
import { Fragment, useMemo, useRef, useState } from "react";
import { Customer } from "../../../types";
import { Entry, useLedgerData } from "./hooks/useLedgerData";

interface TabProps {
  customer: Customer | null;
  marketId: string;
}

// Custom PitchPay icon component
const PitchPayIcon = () => (
  <div className="flex items-center justify-center w-5 h-5 rounded-full bg-purple-100">
    <span className="text-xs font-bold text-purple-600">PP</span>
  </div>
);

// Component to render icon based on transaction type
const TransactionIcon = ({
  type,
  paymentSource,
}: {
  type: Entry["type"];
  paymentSource?: Entry["paymentSource"];
}) => {
  // Special case for PitchPay payments
  if (type === "payment" && paymentSource === "pitchpay") {
    return <PitchPayIcon />;
  }

  // Regular transaction icons
  switch (type) {
    case "invoice":
      return (
        <ReceiptPercentIcon
          className="h-5 w-5 text-red-500"
          aria-hidden="true"
        />
      );
    case "payment":
      return (
        <CreditCardIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
      );
    case "statement":
      return (
        <DocumentTextIcon
          className="h-5 w-5 text-blue-500"
          aria-hidden="true"
        />
      );
    case "payout":
      return (
        <BanknotesIcon className="h-5 w-5 text-orange-500" aria-hidden="true" />
      );
    default:
      return null;
  }
};

// Amount component with color and arrow based on value
const Amount = ({ amount }: { amount: number | null }) => {
  let value = amount ?? 0;
  let isPositive = value > 0;
  let textColor = isPositive ? "text-green-600" : "text-gray-700";

  return (
    <div className={`flex items-center font-medium ${textColor}`}>
      {isPositive && <span className="mr-1">+</span>}
      {Math.abs(value).toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
      })}
    </div>
  );
};

// Format balance
const formatBalance = (balance: number) => {
  return balance.toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
  });
};

// Add debt duration calculation function
const getDebtDuration = (latestTransactionDate: string) => {
  const today = new Date();
  const debtDate = parseISO(latestTransactionDate);

  // Calculate days between
  const diffTime = Math.abs(today.getTime() - debtDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays < 30) {
    return `${diffDays} days`;
  } else if (diffDays < 365) {
    const months = Math.floor(diffDays / 30);
    return `${months} ${months === 1 ? "month" : "months"}`;
  } else {
    const years = Math.floor(diffDays / 365);
    const remainingMonths = Math.floor((diffDays % 365) / 30);

    if (remainingMonths === 0) {
      return `${years} ${years === 1 ? "year" : "years"}`;
    } else {
      return `${years} ${
        years === 1 ? "year" : "years"
      } and ${remainingMonths} ${remainingMonths === 1 ? "month" : "months"}`;
    }
  }
};

// Format today's date for max attribute
const todayString = format(new Date(), "yyyy-MM-dd");

// Add date preset function
const applyDatePreset = (
  preset: "today" | "yesterday" | "last30days" | "lastyear",
  setTempFilters: any,
  tempFilters: any
) => {
  const today = new Date();
  let start = "";
  let end = format(today, "yyyy-MM-dd");

  switch (preset) {
    case "today":
      start = format(today, "yyyy-MM-dd");
      break;
    case "yesterday":
      start = format(subDays(today, 1), "yyyy-MM-dd");
      end = start;
      break;
    case "last30days":
      start = format(subDays(today, 30), "yyyy-MM-dd");
      break;
    case "lastyear":
      start = format(subYears(today, 1), "yyyy-MM-dd");
      break;
  }

  setTempFilters({
    ...tempFilters,
    dateRange: { start, end },
  });
};

// Add validation function
const validateDateRange = (start: string, end: string) => {
  const today = new Date();
  today.setHours(23, 59, 59, 999); // Set to end of day

  const startDate = start ? new Date(start) : null;
  const endDate = end ? new Date(end) : null;

  // Validate dates are not in the future
  if (startDate && startDate > today) {
    return {
      isValid: false,
      startError: "Start date cannot be in the future",
      endError: "",
    };
  }

  if (endDate && endDate > today) {
    return {
      isValid: false,
      startError: "",
      endError: "End date cannot be in the future",
    };
  }

  // Validate start is not after end
  if (startDate && endDate && startDate > endDate) {
    return {
      isValid: false,
      startError: "Start date cannot be after end date",
      endError: "End date cannot be before start date",
    };
  }

  return { isValid: true, startError: "", endError: "" };
};

// Skeleton component for loading state
const LedgerSkeleton = () => {
  return (
    <div className="flex flex-col h-full animate-pulse">
      {/* Balance header skeletons */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        {/* Current balance skeleton */}
        <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
          <div className="h-4 w-28 bg-gray-200 rounded mb-2"></div>
          <div className="h-8 w-40 bg-gray-300 rounded"></div>
        </div>

        {/* Payment behavior skeleton */}
        <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
          <div className="h-4 w-32 bg-gray-200 rounded mb-2"></div>
          <div className="h-6 w-44 bg-gray-300 rounded mb-2"></div>
          <div className="flex items-center mt-1">
            <div className="h-2 w-2 rounded-full bg-gray-300 mr-2"></div>
            <div className="h-4 w-36 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>

      {/* Transaction container skeleton */}
      <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden flex flex-col mb-1 flex-grow">
        {/* Action buttons skeleton */}
        <div className="p-3 border-b border-gray-200 bg-gray-50 flex flex-wrap gap-2 justify-between">
          <div className="h-9 w-20 bg-gray-300 rounded-md"></div>
          <div className="flex space-x-2">
            <div className="h-9 w-32 bg-gray-300 rounded-md"></div>
            <div className="h-9 w-32 bg-gray-300 rounded-md"></div>
            <div className="h-9 w-9 bg-gray-300 rounded-md"></div>
          </div>
        </div>

        {/* Transaction list skeleton */}
        <div className="flex-grow overflow-y-auto min-h-[400px]">
          <div className="divide-y divide-gray-200">
            {/* Date header skeleton */}
            <div className="bg-gray-50 p-3 flex justify-between items-center">
              <div className="h-5 w-48 bg-gray-300 rounded"></div>
              <div className="h-5 w-24 bg-gray-300 rounded"></div>
            </div>

            {/* Transaction rows skeleton */}
            {[...Array(6)].map((_, i) => (
              <div key={i} className="flex items-center px-4 py-3 bg-white">
                <div className="flex-shrink-0 mr-3">
                  <div className="h-5 w-5 bg-gray-300 rounded-full"></div>
                </div>
                <div className="min-w-0 flex-1">
                  <div className="h-5 w-48 bg-gray-300 rounded mb-2"></div>
                  <div className="h-3 w-32 bg-gray-200 rounded"></div>
                </div>
                <div className="flex-shrink-0 ml-4">
                  <div className="h-5 w-20 bg-gray-300 rounded"></div>
                </div>
              </div>
            ))}

            {/* Second date header skeleton */}
            <div className="bg-gray-50 p-3 flex justify-between items-center">
              <div className="h-5 w-48 bg-gray-300 rounded"></div>
              <div className="h-5 w-24 bg-gray-300 rounded"></div>
            </div>

            {/* More transaction rows */}
            {[...Array(4)].map((_, i) => (
              <div key={i} className="flex items-center px-4 py-3 bg-white">
                <div className="flex-shrink-0 mr-3">
                  <div className="h-5 w-5 bg-gray-300 rounded-full"></div>
                </div>
                <div className="min-w-0 flex-1">
                  <div className="h-5 w-48 bg-gray-300 rounded mb-2"></div>
                  <div className="h-3 w-32 bg-gray-200 rounded"></div>
                </div>
                <div className="flex-shrink-0 ml-4">
                  <div className="h-5 w-20 bg-gray-300 rounded"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// Pagination component
const Pagination = ({
  currentPage = 1,
  totalPages = 1,
  onPageChange,
}: {
  currentPage?: number;
  totalPages?: number;
  onPageChange?: (page: number) => void;
}) => {
  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => onPageChange?.(currentPage - 1)}
          disabled={currentPage <= 1}
          className={`relative inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium ${
            currentPage <= 1
              ? "text-gray-300 cursor-not-allowed"
              : "text-gray-700 hover:bg-gray-50"
          }`}
        >
          Previous
        </button>
        <button
          onClick={() => onPageChange?.(currentPage + 1)}
          disabled={currentPage >= totalPages}
          className={`relative ml-3 inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium ${
            currentPage >= totalPages
              ? "text-gray-300 cursor-not-allowed"
              : "text-gray-700 hover:bg-gray-50"
          }`}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing page <span className="font-medium">{currentPage}</span> of{" "}
            <span className="font-medium">{totalPages}</span>
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={() => onPageChange?.(currentPage - 1)}
              disabled={currentPage <= 1}
              className={`relative inline-flex items-center rounded-l-md border border-gray-300 px-2 py-2 text-sm font-medium ${
                currentPage <= 1
                  ? "text-gray-300 cursor-not-allowed"
                  : "text-gray-500 hover:bg-gray-50"
              }`}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
              {currentPage}
            </span>
            <button
              onClick={() => onPageChange?.(currentPage + 1)}
              disabled={currentPage >= totalPages}
              className={`relative inline-flex items-center rounded-r-md border border-gray-300 px-2 py-2 text-sm font-medium ${
                currentPage >= totalPages
                  ? "text-gray-300 cursor-not-allowed"
                  : "text-gray-500 hover:bg-gray-50"
              }`}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export function LedgerTab(props: TabProps) {
  let { customer, marketId } = props;

  // Use the ledger data hook to fetch entries
  let { entries, isLoading, error } = useLedgerData({ customer, marketId });

  // Filter state
  let [isFilterOpen, setIsFilterOpen] = useState(false);
  let [filters, setFilters] = useState({
    dateRange: {
      start: "",
      end: "",
    },
    types: {
      invoice: true,
      payment: true,
      statement: true,
      payout: true,
    },
  });

  // Temporary filter state (used in the menu)
  let [tempFilters, setTempFilters] = useState(filters);

  // Add a reference to the filter button
  let filterButtonRef = useRef<HTMLButtonElement>(null);

  // Add state for date validation errors
  let [dateErrors, setDateErrors] = useState({ start: "", end: "" });

  // Add pagination state
  let [currentPage, setCurrentPage] = useState(1);
  let [totalPages, setTotalPages] = useState(1);

  // Handler for page changes
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Function to generate filter summary
  const getFilterSummary = () => {
    const summaryParts = [];

    // Check date range
    if (filters.dateRange.start || filters.dateRange.end) {
      if (
        filters.dateRange.start === filters.dateRange.end &&
        filters.dateRange.start
      ) {
        // Same day
        summaryParts.push(format(parseISO(filters.dateRange.start), "d MMM"));
      } else {
        // Date range
        const startText = filters.dateRange.start
          ? format(parseISO(filters.dateRange.start), "d MMM")
          : "";
        const endText = filters.dateRange.end
          ? format(parseISO(filters.dateRange.end), "d MMM")
          : "";

        if (startText && endText) {
          summaryParts.push(`${startText} - ${endText}`);
        } else if (startText) {
          summaryParts.push(`From ${startText}`);
        } else if (endText) {
          summaryParts.push(`Until ${endText}`);
        }
      }
    }

    return summaryParts.join(", ");
  };

  // Check if filters are active
  let isFilterActive = useMemo(() => {
    return (
      filters.dateRange.start !== "" ||
      filters.dateRange.end !== "" ||
      !Object.values(filters.types).every((value) => value === true)
    );
  }, [filters]);

  // Get filter summary for display
  let filterSummary = useMemo(() => {
    const summaryParts = [];
    const currentYear = new Date().getFullYear();

    // Check if either date is outside current year
    const shouldShowYears = () => {
      if (filters.dateRange.start) {
        const startYear = parseISO(filters.dateRange.start).getFullYear();
        if (startYear !== currentYear) return true;
      }

      if (filters.dateRange.end) {
        const endYear = parseISO(filters.dateRange.end).getFullYear();
        if (endYear !== currentYear) return true;
      }

      return false;
    };

    // Determine if we need to show years
    const showYears = shouldShowYears();

    // Format date with year conditionally
    const formatDate = (dateStr: string) => {
      const date = parseISO(dateStr);

      // If either date is not in current year, include year in format for both dates
      if (showYears) {
        return format(date, "d MMM yyyy");
      }

      // Otherwise just show day and month
      return format(date, "d MMM");
    };

    // Check date range
    if (filters.dateRange.start || filters.dateRange.end) {
      if (
        filters.dateRange.start === filters.dateRange.end &&
        filters.dateRange.start
      ) {
        // Same day
        summaryParts.push(formatDate(filters.dateRange.start));
      } else {
        // Date range
        const startText = filters.dateRange.start
          ? formatDate(filters.dateRange.start)
          : "";
        const endText = filters.dateRange.end
          ? formatDate(filters.dateRange.end)
          : "";

        if (startText && endText) {
          summaryParts.push(`${startText} - ${endText}`);
        } else if (startText) {
          summaryParts.push(`From ${startText}`);
        } else if (endText) {
          summaryParts.push(`Until ${endText}`);
        }
      }
    }

    return summaryParts.join(", ");
  }, [filters]);

  // Check which transaction types are active
  let activeTypes = useMemo(() => {
    const typesEnabled = {
      invoice: filters.types.invoice,
      payment: filters.types.payment,
      statement: filters.types.statement,
      payout: filters.types.payout,
    };

    // If all types are enabled, return empty array (no filtering)
    if (Object.values(typesEnabled).every((v) => v)) {
      return [];
    }

    // Return list of active types
    return Object.entries(typesEnabled)
      .filter(([_, enabled]) => enabled)
      .map(([type]) => type as "invoice" | "payment" | "statement" | "payout");
  }, [filters]);

  // Apply filters to entries
  let filteredEntries = useMemo(() => {
    return entries.filter((entry) => {
      // Filter by type
      if (
        !Object.prototype.hasOwnProperty.call(filters.types, entry.type) ||
        !filters.types[entry.type as keyof typeof filters.types]
      ) {
        return false;
      }

      // Filter by date range (start)
      if (
        filters.dateRange.start &&
        entry.date.toDate() < new Date(filters.dateRange.start)
      ) {
        return false;
      }

      // Filter by date range (end)
      if (
        filters.dateRange.end &&
        entry.date.toDate() > new Date(filters.dateRange.end)
      ) {
        return false;
      }

      return true;
    });
  }, [entries, filters]);

  // Group entries by date and calculate daily totals
  let groupedEntries = useMemo(() => {
    // First sort entries by date (newest first)
    let sortedEntries = [...filteredEntries].sort((a, b) => {
      return b.date.toDate().getTime() - a.date.toDate().getTime();
    });

    // Group by date
    let groups: { [key: string]: Entry[] } = {};
    sortedEntries.forEach((entry) => {
      let dateKey = entry.date.toDate().toISOString();
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(entry);
    });

    // Calculate daily totals
    let result = Object.entries(groups).map(([date, entries]) => {
      let dayTotal = entries.reduce(
        (sum, entry) => sum + (entry.amountInCents || 0),
        0
      );
      return {
        date,
        entries,
        dayTotal,
      };
    });

    return result;
  }, [filteredEntries]);

  // Get current balance (the most recent transaction's balance)
  let currentBalance = useMemo(() => {
    if (entries.length === 0) return 0;

    // Sort entries by date (newest first)
    let sortedEntries = [...entries].sort((a, b) => {
      return b.date.toDate().getTime() - a.date.toDate().getTime();
    });

    return sortedEntries[0]?.balanceInCents ?? 0;
  }, [entries]);

  // Get the most recent transaction date
  let latestTransactionDate = useMemo(() => {
    if (entries.length === 0) return "";

    // Sort entries by date (newest first)
    let sortedEntries = [...entries].sort((a, b) => {
      return b.date.toDate().getTime() - a.date.toDate().getTime();
    });

    return sortedEntries[0]?.date;
  }, [entries]);

  // Validate date inputs and update tempFilters accordingly
  const handleDateChange = (field: "start" | "end", value: string) => {
    // Create updated filter state
    const updatedDateRange = {
      ...tempFilters.dateRange,
      [field]: value,
    };

    // Validate the new date range
    const validation = validateDateRange(
      updatedDateRange.start,
      updatedDateRange.end
    );

    // Update error state
    setDateErrors({
      start: field === "start" ? validation.startError : dateErrors.start,
      end: field === "end" ? validation.endError : dateErrors.end,
    });

    // Update filters only if valid or clearing a field
    if (validation.isValid || value === "") {
      setTempFilters({
        ...tempFilters,
        dateRange: updatedDateRange,
      });
    }
  };

  // Reset temp filters to current filters
  const handleOpenFilter = () => {
    setTempFilters(filters);
    // We don't need to manually open the menu since clicking the Menu.Button will do that
    // Just make sure the tempFilters are set to current filters
  };

  // Modify the function that applies the filters to also close the menu
  const applyFiltersAndClose = () => {
    setFilters(tempFilters);
    // Close the menu by clicking the button
    if (filterButtonRef.current) {
      filterButtonRef.current.click();
    }
  };

  // Clear all filters
  const clearFilters = () => {
    const resetFilters = {
      dateRange: {
        start: "",
        end: "",
      },
      types: {
        invoice: true,
        payment: true,
        statement: true,
        payout: true,
      },
    };

    setTempFilters(resetFilters);
    setFilters(resetFilters);

    // Close the menu by clicking the button
    if (filterButtonRef.current) {
      filterButtonRef.current.click();
    }
  };

  // Add function to check if current date range matches a preset
  const isDatePresetActive = (
    preset: "today" | "yesterday" | "last30days" | "lastyear"
  ) => {
    if (!tempFilters.dateRange.start || !tempFilters.dateRange.end)
      return false;

    const today = new Date();
    const todayStr = format(today, "yyyy-MM-dd");
    const yesterdayStr = format(subDays(today, 1), "yyyy-MM-dd");
    const thirtyDaysAgoStr = format(subDays(today, 30), "yyyy-MM-dd");
    const oneYearAgoStr = format(subYears(today, 1), "yyyy-MM-dd");

    const { start, end } = tempFilters.dateRange;

    switch (preset) {
      case "today":
        return start === todayStr && end === todayStr;
      case "yesterday":
        return start === yesterdayStr && end === yesterdayStr;
      case "last30days":
        return start === thirtyDaysAgoStr && end === todayStr;
      case "lastyear":
        return start === oneYearAgoStr && end === todayStr;
      default:
        return false;
    }
  };

  return (
    <div className="flex flex-col h-full p-4">
      {/* Show loading skeleton instead of spinner */}
      {isLoading ? (
        <LedgerSkeleton />
      ) : error ? (
        <div className="flex items-center justify-center h-full">
          <div className="text-center text-red-500">
            <p>Error loading ledger data: {error.message}</p>
          </div>
        </div>
      ) : (
        <>
          {/* Balance header and payment info */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            {/* Current balance */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
              <h3 className="text-sm font-medium text-gray-500">
                Current Balance
              </h3>
              <div
                className={`mt-1 text-2xl font-semibold ${
                  currentBalance >= 0 ? "text-green-600" : "text-red-600"
                }`}
              >
                {formatBalance(currentBalance)}
              </div>

              {/* Show debt duration if balance is negative */}
              {currentBalance < 0 && (
                <div className="mt-2 text-sm text-red-500">
                  <span className="font-medium">
                    Outstanding for{" "}
                    {getDebtDuration(
                      typeof latestTransactionDate === "string"
                        ? latestTransactionDate
                        : latestTransactionDate?.toDate().toISOString() ?? ""
                    )}
                  </span>
                </div>
              )}
            </div>

            {/* Payment behavior */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
              <h3 className="text-sm font-medium text-gray-500">
                Payment Behavior
              </h3>
              <div className="mt-1">
                <div className="text-lg font-medium text-gray-900">
                  Pays within 14 days
                </div>
                <div className="mt-1 text-sm text-gray-500 flex items-center">
                  <span className="inline-block h-2 w-2 rounded-full bg-green-500 mr-2"></span>
                  Good payment history
                </div>
              </div>
            </div>
          </div>

          {/* Unified transaction container */}
          <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden flex flex-col mb-1 flex-grow">
            {/* Action buttons - now part of the same visual component as the list */}
            <div className="p-3 border-b border-gray-200 bg-gray-50 flex flex-wrap gap-2 justify-between">
              <div className="flex flex-wrap gap-2 relative">
                {/* Filter button */}
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button
                    ref={filterButtonRef}
                    className={`inline-flex items-center px-3 py-2 border ${
                      isFilterActive
                        ? "border-blue-500 bg-blue-50 text-blue-700"
                        : "border-gray-300 bg-white text-gray-700"
                    } shadow-sm text-sm leading-4 font-medium rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                    onClick={handleOpenFilter}
                  >
                    <FunnelIcon
                      className={`mr-2 h-4 w-4 ${
                        isFilterActive ? "text-blue-600" : ""
                      }`}
                      aria-hidden="true"
                    />
                    Filter
                    {isFilterActive && (
                      <div className="ml-1 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 gap-1">
                        {filterSummary && <span>{filterSummary}</span>}
                        {filterSummary && activeTypes.length > 0 && (
                          <span className="mx-0.5">•</span>
                        )}
                        <div className="flex space-x-1">
                          {activeTypes.includes("invoice") && (
                            <ReceiptPercentIcon
                              className="h-3 w-3 text-red-500"
                              aria-hidden="true"
                            />
                          )}
                          {activeTypes.includes("payment") && (
                            <CreditCardIcon
                              className="h-3 w-3 text-green-500"
                              aria-hidden="true"
                            />
                          )}
                          {activeTypes.includes("statement") && (
                            <DocumentTextIcon
                              className="h-3 w-3 text-blue-500"
                              aria-hidden="true"
                            />
                          )}
                          {activeTypes.includes("payout") && (
                            <BanknotesIcon
                              className="h-3 w-3 text-orange-500"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-0 z-[200] mt-2 w-96 origin-top-left rounded-md bg-white shadow-xl ring-1 ring-gray-300 divide-y divide-gray-100 focus:outline-none max-h-[80vh] overflow-y-auto">
                      <div className="p-4">
                        <div className="flex items-center justify-between mb-4 sticky top-0 bg-white pt-1 pb-2">
                          <h3 className="text-sm font-medium text-gray-900">
                            Filter Transactions
                          </h3>
                          <button
                            type="button"
                            className="text-sm text-blue-600 hover:text-blue-800"
                            onClick={clearFilters}
                          >
                            Clear All
                          </button>
                        </div>

                        {/* Date range filter */}
                        <div className="mb-4">
                          <h4 className="text-xs font-medium text-gray-700 mb-2">
                            Date Range
                          </h4>
                          <div className="grid grid-cols-2 gap-3 mb-2">
                            <div>
                              <label
                                htmlFor="filter-start-date"
                                className="block text-xs text-gray-500"
                              >
                                From
                              </label>
                              <input
                                type="date"
                                id="filter-start-date"
                                className={`mt-1 block w-full rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${
                                  dateErrors.start
                                    ? "border-red-300"
                                    : "border-gray-300"
                                }`}
                                value={tempFilters.dateRange.start}
                                max={todayString}
                                onChange={(e) =>
                                  handleDateChange("start", e.target.value)
                                }
                              />
                              {dateErrors.start && (
                                <p className="mt-1 text-xs text-red-600">
                                  {dateErrors.start}
                                </p>
                              )}
                            </div>
                            <div>
                              <label
                                htmlFor="filter-end-date"
                                className="block text-xs text-gray-500"
                              >
                                To
                              </label>
                              <input
                                type="date"
                                id="filter-end-date"
                                className={`mt-1 block w-full rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${
                                  dateErrors.end
                                    ? "border-red-300"
                                    : "border-gray-300"
                                }`}
                                value={tempFilters.dateRange.end}
                                max={todayString}
                                min={tempFilters.dateRange.start || ""}
                                onChange={(e) =>
                                  handleDateChange("end", e.target.value)
                                }
                              />
                              {dateErrors.end && (
                                <p className="mt-1 text-xs text-red-600">
                                  {dateErrors.end}
                                </p>
                              )}
                            </div>
                          </div>

                          {/* Date presets now directly under date inputs */}
                          <div className="flex flex-wrap gap-2">
                            <button
                              type="button"
                              className={
                                isDatePresetActive("today")
                                  ? "px-2 py-1 text-xs font-medium rounded-md border bg-blue-50 border-blue-200 text-blue-700 hover:bg-gray-50"
                                  : "px-2 py-1 text-xs font-medium rounded-md border border-gray-200 text-gray-700 hover:bg-gray-50"
                              }
                              onClick={() =>
                                applyDatePreset(
                                  "today",
                                  setTempFilters,
                                  tempFilters
                                )
                              }
                            >
                              Today
                            </button>
                            <button
                              type="button"
                              className={
                                isDatePresetActive("yesterday")
                                  ? "px-2 py-1 text-xs font-medium rounded-md border bg-blue-50 border-blue-200 text-blue-700 hover:bg-gray-50"
                                  : "px-2 py-1 text-xs font-medium rounded-md border border-gray-200 text-gray-700 hover:bg-gray-50"
                              }
                              onClick={() =>
                                applyDatePreset(
                                  "yesterday",
                                  setTempFilters,
                                  tempFilters
                                )
                              }
                            >
                              Yesterday
                            </button>
                            <button
                              type="button"
                              className={
                                isDatePresetActive("last30days")
                                  ? "px-2 py-1 text-xs font-medium rounded-md border bg-blue-50 border-blue-200 text-blue-700 hover:bg-gray-50"
                                  : "px-2 py-1 text-xs font-medium rounded-md border border-gray-200 text-gray-700 hover:bg-gray-50"
                              }
                              onClick={() =>
                                applyDatePreset(
                                  "last30days",
                                  setTempFilters,
                                  tempFilters
                                )
                              }
                            >
                              Last 30 Days
                            </button>
                            <button
                              type="button"
                              className={
                                isDatePresetActive("lastyear")
                                  ? "px-2 py-1 text-xs font-medium rounded-md border bg-blue-50 border-blue-200 text-blue-700 hover:bg-gray-50"
                                  : "px-2 py-1 text-xs font-medium rounded-md border border-gray-200 text-gray-700 hover:bg-gray-50"
                              }
                              onClick={() =>
                                applyDatePreset(
                                  "lastyear",
                                  setTempFilters,
                                  tempFilters
                                )
                              }
                            >
                              Last Year
                            </button>
                          </div>
                        </div>

                        {/* Transaction type filter */}
                        <div>
                          <h4 className="text-xs font-medium text-gray-700 mb-2">
                            Transaction Types
                          </h4>
                          <div className="space-y-2">
                            <div className="flex items-center">
                              <input
                                id="filter-invoice"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                checked={tempFilters.types.invoice}
                                onChange={() =>
                                  setTempFilters({
                                    ...tempFilters,
                                    types: {
                                      ...tempFilters.types,
                                      invoice: !tempFilters.types.invoice,
                                    },
                                  })
                                }
                              />
                              <label
                                htmlFor="filter-invoice"
                                className="ml-2 flex items-center text-sm text-gray-700"
                              >
                                <ReceiptPercentIcon className="mr-1.5 h-4 w-4 text-red-500" />
                                Invoices
                              </label>
                            </div>

                            <div className="flex items-center">
                              <input
                                id="filter-payment"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                checked={tempFilters.types.payment}
                                onChange={() =>
                                  setTempFilters({
                                    ...tempFilters,
                                    types: {
                                      ...tempFilters.types,
                                      payment: !tempFilters.types.payment,
                                    },
                                  })
                                }
                              />
                              <label
                                htmlFor="filter-payment"
                                className="ml-2 flex items-center text-sm text-gray-700"
                              >
                                <CreditCardIcon className="mr-1.5 h-4 w-4 text-green-500" />
                                Payments
                              </label>
                            </div>

                            <div className="flex items-center">
                              <input
                                id="filter-statement"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                checked={tempFilters.types.statement}
                                onChange={() =>
                                  setTempFilters({
                                    ...tempFilters,
                                    types: {
                                      ...tempFilters.types,
                                      statement: !tempFilters.types.statement,
                                    },
                                  })
                                }
                              />
                              <label
                                htmlFor="filter-statement"
                                className="ml-2 flex items-center text-sm text-gray-700"
                              >
                                <DocumentTextIcon className="mr-1.5 h-4 w-4 text-blue-500" />
                                Statements
                              </label>
                            </div>

                            <div className="flex items-center">
                              <input
                                id="filter-payout"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                checked={tempFilters.types.payout}
                                onChange={() =>
                                  setTempFilters({
                                    ...tempFilters,
                                    types: {
                                      ...tempFilters.types,
                                      payout: !tempFilters.types.payout,
                                    },
                                  })
                                }
                              />
                              <label
                                htmlFor="filter-payout"
                                className="ml-2 flex items-center text-sm text-gray-700"
                              >
                                <BanknotesIcon className="mr-1.5 h-4 w-4 text-orange-500" />
                                Payouts
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="p-3 bg-gray-50">
                        <button
                          type="button"
                          className="w-full rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          onClick={applyFiltersAndClose}
                        >
                          Apply Filters
                        </button>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>

              <div className="flex flex-wrap gap-2 items-center">
                <button
                  className="inline-flex items-center px-3 py-2 border border-green-500 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  onClick={() => {
                    /* Record payment logic */
                  }}
                >
                  <PlusCircleIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                  Record Payment
                </button>

                <button
                  className="inline-flex items-center px-3 py-2 border border-blue-500 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => {
                    /* Record payout logic */
                  }}
                >
                  <MinusCircleIcon
                    className="mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  Record Payout
                </button>

                {/* Download icon button */}
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="px-3 py-2 rounded border border-gray-300 text-gray-500 hover:text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500">
                    <span className="sr-only">Download options</span>
                    <ArrowDownTrayIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700"
                              } group flex w-full items-center px-4 py-2 text-sm`}
                              onClick={() => {
                                /* Download as CSV logic */
                              }}
                            >
                              <DocumentTextIcon
                                className="mr-3 h-5 w-5 text-gray-500"
                                aria-hidden="true"
                              />
                              Download CSV
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700"
                              } group flex w-full items-center px-4 py-2 text-sm`}
                              onClick={() => {
                                /* Download as PDF logic */
                              }}
                            >
                              <DocumentTextIcon
                                className="mr-3 h-5 w-5 text-gray-500"
                                aria-hidden="true"
                              />
                              Download PDF
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>

            {/* Transaction list - now within the same container as the action buttons */}
            <div className="flex-grow overflow-y-auto min-h-[400px] flex">
              <div className="divide-y divide-gray-200 h-full flex-grow">
                {groupedEntries.length > 0 ? (
                  <>
                    {groupedEntries.map(({ date, entries, dayTotal }) => (
                      <div key={date} className="bg-gray-50">
                        {/* Date header with daily total */}
                        <div className="flex justify-between px-4 py-3 items-center sticky top-0 bg-gray-50 z-10">
                          <h4 className="text-sm font-medium text-gray-700">
                            {format(parseISO(date), "EEEE, d MMMM yyyy")}
                          </h4>
                          <div className="text-sm font-medium text-gray-500">
                            {formatBalance(dayTotal)}
                          </div>
                        </div>

                        {/* Transactions for this day */}
                        <div className="bg-white divide-y divide-gray-100">
                          {entries.map((entry) => (
                            <Link
                              href={entry.link || "#"}
                              key={entry.id}
                              className="flex items-center px-4 py-3 hover:bg-gray-50 transition-colors duration-150"
                            >
                              <div className="flex-shrink-0 mr-3">
                                <TransactionIcon
                                  type={entry.type}
                                  paymentSource={entry.paymentSource}
                                />
                              </div>
                              <div className="min-w-0 flex-1">
                                <p className="text-sm font-medium text-gray-900 truncate">
                                  {entry.description}
                                </p>
                                <div className="flex flex-col space-y-1">
                                  {/* Show lot summary for invoices and statements */}
                                  {(entry.type === "invoice" ||
                                    entry.type === "statement") &&
                                    entry.description && (
                                      <p className="text-xs italic text-gray-600">
                                        {entry.description}
                                      </p>
                                    )}

                                  {/* Show payment method for payments and payouts */}
                                  {(entry.type === "payment" ||
                                    entry.type === "payout") &&
                                    entry.paymentMethod && (
                                      <p className="text-xs text-gray-500 capitalize">
                                        {entry.paymentMethod === "BACS"
                                          ? "BACS"
                                          : entry.paymentMethod}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="flex-shrink-0 ml-4">
                                <Amount amount={entry.amountInCents ?? 0} />
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    ))}

                    {/* Add pagination component */}
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </>
                ) : (
                  /* Empty state */
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="text-center">
                      <DocumentTextIcon className="mx-auto h-12 w-12 text-gray-400" />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">
                        No transactions
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {isFilterActive
                          ? "No transactions match your filter criteria. Try adjusting your filters."
                          : "This customer doesn't have any transactions yet."}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function useCurrentBalance(
  marketId: string | null,
  customerId: string | null | undefined
) {
  let cxTrdReceivable =
    useStudioStream("balance", marketId, {
      account: customerId && `${customerId}:asset:trade receivable`,
    }) || 0;

  let cxTrdPayable =
    useStudioStream("balance", marketId, {
      account: customerId && `${customerId}:liability:trade payable`,
    }) || 0;

  let customerBalances =
    cxTrdReceivable &&
    cxTrdPayable &&
    !cxTrdReceivable.loading &&
    !cxTrdPayable.loading
      ? {
          tradeReceivable: cxTrdReceivable.data!,
          tradePayable: cxTrdPayable.data!,
        }
      : null;

  let currentBalance =
    (customerBalances?.tradeReceivable ?? 0) +
    (customerBalances?.tradePayable ?? 0);

  return currentBalance;
}
