import { Firestore, doc, getDoc, getDocFromCache } from "firebase/firestore";
import { useContext, useMemo } from "react";
import { Lot, Sale } from "types";
import {
  ExecuteInSaleContext,
  WithSaleContext,
  _InternalExecuteInSaleContext,
} from "./ExecuteInSaleContext";
import { createDraftInvoice } from "./actions/createDraftInvoice";
import { createNewLot } from "./actions/createLot";
import { createLotIssue, resolveLotIssue } from "./actions/createLotIssue";
import { createLotItem } from "./actions/createLotItem";
import { createSale, duplicateSale } from "./actions/createSale";
import {
  getSaleLotWeight,
  isWeightCaptured,
  updateSaleLotWeight,
} from "./actions/getUpdateLotWeight";

import { useMarketId } from "../useMartketId";
import {
  checkBidderNumberIsUnique,
  clearAllBidderNumbers,
  setBidderNumber,
} from "./actions/bidderNumbers";
import { createBACSFile } from "./actions/createBACSFile";
import { deleteLot } from "./actions/deleteLot";
import { deleteLotItems } from "./actions/deleteLotItem";
import { groupItemCount } from "./actions/groupItemCount";
import { importCustomers } from "./actions/importCustomers";
import { issueInvoice } from "./actions/issueInvoice";
import {
  moveIn,
  moveInPreflight,
  moveOut,
  moveOutPreflight,
  amendMoveIn,
  amendMoveOut,
  deleteMoveIn,
  deleteMoveOut,
} from "./actions/movements";
import { createPayment, voidPayment } from "./actions/payments";
import { completePayout, createPayout, voidPayout } from "./actions/payouts";
import { printJobs } from "./actions/printing";
import { sendInvoiceEmail } from "./actions/sendInvoiceEmail";
import { updateSaleLot, updateSaleLots } from "./actions/updateLot";
import {
  deleteSale,
  updateSale,
  updateSaleSettings,
} from "./actions/updateSale";
import { voidInvoice } from "./actions/voidInvoice";
import { useFirestore } from "./firebase/useFirestore";
import { useCurrentUid } from "./firebase/useFirestoreAuth";
import {
  createCustomerInteraction,
  deleteCustomerInteraction,
  updateCustomerInteraction,
} from "./actions/interactions";

export type Studio = ReturnType<typeof constructStudioAPI>;

export function constructStudioAPI(
  firestore: Firestore,
  currentUid: string | null,
  marketId: string | null,
  executeInSaleContext: ExecuteInSaleContext,
  withSaleContext: WithSaleContext
) {
  return {
    createSale: (info: Parameters<typeof createSale>[1]) => {
      if (!marketId) throw new Error(`No market id`);
      return createSale(marketId, info);
    },
    duplicateSale: (info: Parameters<typeof duplicateSale>[1]) => {
      if (!marketId) throw new Error(`No market id`);
      return duplicateSale(marketId, info);
    },
    updateSaleSettings: (info: Parameters<typeof updateSaleSettings>[0]) => {
      return updateSaleSettings(info);
    },
    deleteSale: (info: Parameters<typeof deleteSale>[0]) => {
      return deleteSale(info);
    },

    // actions that mutate the data
    createLot: withSaleContext(createNewLot),
    updateLot: withSaleContext(updateSaleLot),
    updateLots: withSaleContext(updateSaleLots),
    deleteLot: withSaleContext(deleteLot),

    // special case for updating the weight of all items in a lot group
    updateLotGroupWeight: withSaleContext(updateSaleLotWeight),
    getLotGroupWeight: withSaleContext(getSaleLotWeight),
    isWeightCaptured: withSaleContext(isWeightCaptured),

    countItemsInLotGroup: withSaleContext(groupItemCount),

    createLotItem: withSaleContext(createLotItem),
    deleteLotItems: withSaleContext(deleteLotItems),

    createLotIssue: withSaleContext(createLotIssue),
    resolveLotIssue: withSaleContext(resolveLotIssue),

    // Get just a snapshot of the sale
    getSales: async (
      saleIdOrIds: string[] | string | null | undefined,
      { fromCacheOnly = false }: { fromCacheOnly?: boolean } = {}
    ) => {
      if (!marketId) throw new Error(`No market id`);
      if (saleIdOrIds === null || saleIdOrIds === undefined) return [];

      let saleIds = Array.isArray(saleIdOrIds) ? saleIdOrIds : [saleIdOrIds];
      let docs = saleIds.map((saleId) =>
        doc(firestore, `markets/${marketId}/sales/${saleId}`)
      );
      let snaps = await Promise.all(
        docs.map((d) => {
          if (fromCacheOnly) {
            return getDocFromCache(d);
          } else {
            return getDoc(d);
          }
        })
      );
      return snaps
        .filter((s) => s.exists())
        .map((snap) => Object.assign({ id: snap.id }, snap.data()) as Sale);
    },
    updateSale: (saleId: string | null | undefined, values: Partial<Sale>) => {
      if (!currentUid) throw new Error(`No current user`);
      if (!marketId) throw new Error(`No market id`);
      if (!saleId) throw new Error(`No sale id`);
      return updateSale(firestore, currentUid, marketId, saleId, values);
    },

    getLots: async (
      lotIdents: { saleId: string; lotId: string }[] | null | undefined,
      { fromCacheOnly = false }: { fromCacheOnly?: boolean } = {}
    ) => {
      if (!marketId) throw new Error(`No market id`);
      if (lotIdents === null || lotIdents === undefined) return [];
      let docs = lotIdents.map(({ saleId, lotId }) =>
        doc(firestore, `markets/${marketId}/sales/${saleId}/lots/${lotId}`)
      );
      let snaps = await Promise.all(
        docs.map(async (d) => {
          if (fromCacheOnly) {
            try {
              let snap = await getDocFromCache(d);
              return snap;
            } catch (e) {
              return { exists: () => false } as any;
            }
          } else {
            return getDoc(d);
          }
        })
      );
      return snaps
        .filter((s) => s.exists())
        .map((snap) => Object.assign({ id: snap.id }, snap.data()) as Lot);
    },

    createPayment: createPayment,
    voidPayment: voidPayment,

    createPayout: createPayout,
    completePayout: completePayout,
    voidPayout: voidPayout,
    createBACSFile: createBACSFile,

    print: printJobs,

    createDraftInvoice: createDraftInvoice,
    issueInvoice: issueInvoice,
    voidInvoice: voidInvoice,

    importCustomers: importCustomers,

    sendInvoiceEmail: sendInvoiceEmail,

    moveInPreflight: moveInPreflight.bind(null, marketId),
    moveIn: moveIn.bind(null, marketId),
    moveOutPreflight: moveOutPreflight.bind(null, marketId),
    moveOut: moveOut.bind(null, marketId),

    amendMoveIn: amendMoveIn.bind(null, marketId),
    amendMoveOut: amendMoveOut.bind(null, marketId),

    deleteMoveIn: deleteMoveIn.bind(null, marketId),
    deleteMoveOut: deleteMoveOut.bind(null, marketId),

    setBidderNumber: setBidderNumber.bind(
      null,
      firestore,
      currentUid,
      marketId
    ),
    checkBidderNumberIsUnique: checkBidderNumberIsUnique.bind(
      null,
      firestore,
      marketId
    ),
    clearAllBidderNumbers: clearAllBidderNumbers.bind(null, marketId),

    executeInSaleContext,

    createCustomerInteraction: createCustomerInteraction.bind(
      null,
      firestore,
      currentUid,
      marketId
    ),
    updateCustomerInteraction: updateCustomerInteraction.bind(
      null,
      firestore,
      currentUid,
      marketId
    ),
    deleteCustomerInteraction: deleteCustomerInteraction.bind(
      null,
      firestore,
      currentUid,
      marketId
    ),
  } as const;
}

export default function useStudio() {
  let firestore = useFirestore();
  let currentUid = useCurrentUid();
  let marketId = useMarketId();
  let { executeInSaleContext, withSaleContext } = useContext(
    _InternalExecuteInSaleContext
  );

  return useMemo(() => {
    return constructStudioAPI(
      firestore,
      currentUid,
      marketId,
      executeInSaleContext,
      withSaleContext
    );
  }, [currentUid, executeInSaleContext, firestore, marketId, withSaleContext]);
}
