import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useStudioStream } from "../studio-react/useStudioStream";

import {
  ALLOWED_COLS_DROP_ID,
  isMediaAllowedForId,
  unlinkAssets,
} from "./multipart-upload";
import { toast } from "sonner";
import {
  createFakeMedia,
  MEDIA_MESSAGES,
  MediaWithAttribute,
  renameFile,
} from "./media.helpers";
import { Lot, Media } from "types";
import { callReorderMedia } from "../studio-react/actions/reorderMedia";
import { GridColumn, Item } from "@glideapps/glide-data-grid";
import {
  MediaTypes,
  MediaUploaderQueue,
  UploadFile,
  UploadFiles,
} from "./mediaQueue";
import { getApplicableAttributes } from "./useAssetsOnLot.hook";

interface MediaUploader {
  reOrderMediaItems: (d: {
    newOrder: Media[];
    type: MediaTypes;
    lotId: string;
    marketId: string;
    saleId: string;
  }) => Promise<void>;
  onDropZoneDrop: (d: UploadFiles) => Promise<void>;
  deleteAsset: (filePath: string) => Promise<void>;
  onGridDrop: (
    cell: Item,
    dataTransfer: DataTransfer | null,
    columns: GridColumn[],
    lots: Lot[]
  ) => Promise<void>;
  tempUploadedItems: OptimisticMediaUpload;
}

type OptimisticMediaUpload = Map<
  string,
  {
    mediaset?: MediaWithAttribute[];
    media?: MediaWithAttribute[];
  }
>;

const MediaUploaderContext = React.createContext<MediaUploader>({
  onDropZoneDrop: () => Promise.resolve(),
  deleteAsset: () => Promise.resolve(),
  reOrderMediaItems: () => Promise.resolve(),
  onGridDrop: () => Promise.resolve(),
  tempUploadedItems: new Map(),
});

export function useMediaUploader() {
  return React.useContext(MediaUploaderContext);
}

const mediaUploaderQueue = new MediaUploaderQueue();

/**
 * The idea here would be that the the Uploader Would Take a temp file and display it here - Keep track of that Lot item if that lot items changes comes in react no that -
 * IF compared with Mobile you will notice some key differences -
 * In Mobile the Media Provider is wrapped around an Lot on an Sale
 * here on web we have a MediaProvider that is wrapped around a Sale thus we cant limit the attributes here to the lot.
 * So we know the attribute types media and mediaset - We persist them with the object so they can be user in the UI to display the correct attributes - this should only we an thing to keep in mind for Optimistic UI
 */

export function MediaUploaderProvider(props: { children: React.ReactNode }) {
  let lotInfo = useStudioStream("sale:lots-info");
  let sale = useStudioStream("sale:sale");

  let heicToPackage = useMemo(async () => {
    // Only import in browser environment
    if (typeof window !== "undefined") {
      return await import("heic-to");
    }
    return null;
  }, []);

  // // Add Item to Upload Queue
  const [tempUploadedItems, setTempUploadedItems] =
    useState<OptimisticMediaUpload>(new Map());

  const setLocalMediaItem = (
    mediaItem: MediaWithAttribute,
    lotId: string,
    attributeId: string,
    attributeType: MediaTypes
  ) => {
    setTempUploadedItems((ps) => {
      const newMap = new Map(ps);
      if (newMap.has(lotId)) {
        const existing = newMap.get(lotId);

        if (existing && attributeType === "media") {
          existing.media = [mediaItem];
          newMap.set(lotId, existing);
          return newMap;
        }

        if (existing?.mediaset && attributeType === "mediaset") {
          const alreadyPasedMedia = existing.mediaset.find(
            (m) => m.fileName === mediaItem.fileName
          );
          if (!alreadyPasedMedia) {
            existing.mediaset.push(mediaItem);
          }

          if (alreadyPasedMedia?.variants.thumbnail?.isLocal) {
            const indexOfThumbnail = existing.mediaset.findIndex(
              (m) => m.fileName === mediaItem.fileName
            );
            existing.mediaset[indexOfThumbnail] = mediaItem;
          }

          newMap.set(lotId, existing);

          return newMap;
        }
      } else {
        newMap.set(lotId, { [attributeType]: [mediaItem] });
      }
      return newMap;
    });
  };

  // Subscribes to the CurrentUpload Progress of an Item in the Queue
  useEffect(() => {
    const uploadProgressSubscription =
      mediaUploaderQueue.currentProgress$.subscribe((progress) => {
        if (progress) {
          if (progress.uploadFile.saleId !== sale?.id) {
            return;
          }
          const tempMedia = createFakeMedia(
            progress.uploadFile.file,
            progress.uploadFile.attributeId,
            progress.uploadFile.attributeType,
            {
              state: progress.state,
              progress: progress.progress,
              total: progress.total,
            }
          );

          setLocalMediaItem(
            tempMedia,
            progress.uploadFile.lotId,
            progress.uploadFile.attributeId,
            progress.uploadFile.attributeType
          );
        }
      });

    return () => {
      uploadProgressSubscription.unsubscribe(); // Properly unsubscribe to clean resources
    };
  }, [sale?.id]);

  const addItemToLocal = useCallback(
    async (
      file: File,
      lotId: string,
      attributeId: string,
      attributeType: MediaTypes
    ) => {
      const tempMedia = createFakeMedia(file, attributeId, attributeType);
      setLocalMediaItem(tempMedia, lotId, attributeId, attributeType);
    },
    [setLocalMediaItem, createFakeMedia, heicToPackage]
  );

  // Removes Local Items for Local Uploads
  useEffect(() => {
    for (let [key, lotInOptimistic] of tempUploadedItems) {
      const lot = lotInfo.data.find((lot) => lot.id === key);

      if (!lot) {
        continue;
      }

      const atributeTypes = Object.keys(lotInOptimistic);

      for (let attributeType of atributeTypes) {
        const mediaInOptimistics = lotInOptimistic[
          attributeType as keyof typeof lotInOptimistic
        ] as MediaWithAttribute[];

        for (let mediaInOptimistic of mediaInOptimistics) {
          const optimisticAttributeId = mediaInOptimistic.attributeId;

          const removeFileFromOptimistic = lot?.attributes?.[
            optimisticAttributeId
          ]?.find(
            (m: Media) => m.fileName === mediaInOptimistic.fileName
          )?.fileName;

          if (removeFileFromOptimistic) {
            setTempUploadedItems((ps) => {
              const newMap = new Map(ps);
              const existing = newMap.get(key);
              if (
                existing &&
                existing[attributeType as keyof typeof existing]
              ) {
                existing[attributeType as keyof typeof existing] = existing?.[
                  attributeType as keyof typeof existing
                ]?.filter(
                  (m: MediaWithAttribute) =>
                    m.fileName !== removeFileFromOptimistic
                );
              }
              return newMap;
            });
          }
        }
      }
    }
  }, [lotInfo.data, tempUploadedItems, setTempUploadedItems]);

  const convertHeicToJpg = useCallback(
    async (file: File) => {
      try {
        const packageDynamic = await heicToPackage;
        let nFile = file;
        const isHeicFile = await packageDynamic?.isHeic(nFile);
        // Check if file is heic
        if (isHeicFile) {
          let tempFile = await packageDynamic?.heicTo({
            blob: nFile,
            type: "image/jpeg",
            quality: 1,
          });
          // If we cant convert t we let the cloud sort it
          if (tempFile) {
            const cleanFileName = file.name.split(".")[0];
            // Create a new file from the blob
            nFile = new File([tempFile], `${cleanFileName}.jpg`, {
              type: "image/jpeg",
            });
          }
        }

        return nFile;
      } catch (error) {
        console.error("Error converting heic to jpg", error);
        return file;
      }
    },
    [heicToPackage]
  );

  const onDropZoneDrop = useCallback(
    async ({
      files,
      attributeId,
      lotId,
      saleId,
      marketId,
      attributeType,
    }: UploadFiles) => {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const renamedFile = renameFile(file);

        const convertedFile = await convertHeicToJpg(renamedFile);
        // Optimistic UI
        addItemToLocal(convertedFile, lotId, attributeId, attributeType);

        const uFile: UploadFile = {
          file: convertedFile,
          marketId,
          attributeId,
          attributeType,
          lotId,
          saleId,
        };
        mediaUploaderQueue.add(uFile);
      }
    },
    [addItemToLocal, renameFile, convertHeicToJpg]
  );

  const onGridDrop = useCallback(
    async (
      cell: Item,
      dataTransfer: DataTransfer | null,
      columns: GridColumn[],
      lots: Lot[]
    ) => {
      const droppedCol = columns[cell[0]];
      if (!droppedCol?.id) {
        return;
      }
      // THIS IS HARD CODED TO MEDIA AS WE DONT WANT USERS TO UPLOAD MAIN IMAGES A THEY ARE GENERATED FROM THE MEDIASET
      if (droppedCol?.id && !ALLOWED_COLS_DROP_ID.includes(droppedCol?.id)) {
        toast.error(`Please drop the file in the appropriate Column`);
        return;
      }

      if (dataTransfer === null) {
        // TODO :: How Would this happen?
        toast.error(`Empty Data Object`);
        return;
      }

      const { files } = dataTransfer;
      // This only supports one image, for simplicity.
      if (files.length < 1) {
        toast.error(`No File found`);
        return;
      }

      let lot = lots[cell[1]];

      const att = sale?.attributeSetByProductCode[lot.productCode] || [];
      if (!att.includes(droppedCol.id)) {
        toast.error(
          `Product Code: ${lot.productCode} cannot have ${droppedCol.id}`
        );
        return;
      }

      const { supportedTypes, maxFiles } = isMediaAllowedForId(
        droppedCol.id as any
      );
      if (files.length > maxFiles) {
        toast.error(`Too Many Files`);
        return;
      }

      if (droppedCol.id === "mainImage") {
        if (lots[cell[1]].attributes["mainImage"]) {
          toast.error(
            `Main Image Already Exists, remove the current one before uploading a new one`
          );
          return;
        }
      }
      // Get the attribute type for the dropped column
      const applicableAttributes = getApplicableAttributes(
        sale!,
        lot.productCode
      )?.filter((a) => a.id === droppedCol.id);

      if (applicableAttributes?.length === 0) {
        toast.error(
          `Attribute ${droppedCol.id} is not applicable for this product code`
        );
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (!supportedTypes.includes(file.type)) {
          toast.error(`File Type Not Supported`);
          return;
        }

        let lot = lots[cell[1]];
        const renamedFile = renameFile(file);
        const convertedFile = await convertHeicToJpg(renamedFile);

        const uFile: UploadFile = {
          file: convertedFile,
          marketId: lot.marketId,
          attributeId: droppedCol.id,
          attributeType: applicableAttributes?.[0].type as MediaTypes,
          lotId: lot.id,
          saleId: lot.saleId,
        };
        addItemToLocal(
          convertedFile,
          lot.id,
          droppedCol.id,
          applicableAttributes?.[0].type as MediaTypes
        );
        mediaUploaderQueue.add(uFile);
      }
    },
    [lotInfo.data, addItemToLocal, mediaUploaderQueue, convertHeicToJpg]
  );

  /**
   * Deletes the asset remote storage
   */
  const deleteAsset = useCallback(
    async (filePath: string): Promise<void> => {
      try {
        // TODO:: DO LOCAL UNLINKING HERE
        toast.info(`Removing Media `, {});
        await unlinkAssets(filePath);
        toast.info(`Media Removed`);
      } catch (error) {
        throw error;
      }
    },
    [unlinkAssets, lotInfo.data]
  );

  const reOrderMediaItems = useCallback(
    async ({
      newOrder,
      lotId,
      marketId,
      saleId,
      type,
    }: {
      newOrder: Media[];
      type: MediaTypes;
      lotId: string;
      marketId: string;
      saleId: string;
    }) => {
      const itemsNewOrder = newOrder.map((i) => i.fileName);
      try {
        await callReorderMedia({
          items: itemsNewOrder,
          marketId: marketId,
          attributeId: type,
          saleId: saleId,
          lotId: lotId,
        });
      } catch (error) {
        toast.error(MEDIA_MESSAGES.REORDER_FAILED);
      }
    },
    [lotInfo.data, callReorderMedia]
  );

  return (
    <MediaUploaderContext.Provider
      value={{
        reOrderMediaItems,
        onDropZoneDrop,
        onGridDrop,
        deleteAsset,
        tempUploadedItems,
      }}
    >
      {props.children}
    </MediaUploaderContext.Provider>
  );
}
