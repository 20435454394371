import { EyeIcon, StarIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { StarIcon as StarIconOutline } from "@heroicons/react/24/outline";
import { Button } from "../Buttons";
import { CSS } from "@dnd-kit/utilities";
import { Media } from "types";
import { mediaVariantSelector } from "@/data/media/media.helpers";
import { useState, useEffect, useRef } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { MediaDisplay } from "./Media.ImageDisplay";
import { MediaViewerWrapper } from "./Media.Viewer";
import { motion } from "framer-motion";

const contextMenuMotion = {
  rest: { opacity: 0, ease: "easeOut", duration: 0.2, type: "tween" },
  hover: {
    opacity: 1,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeIn",
    },
  },
};

export const MediaListItem = ({
  value,
  disableReorder,
  deleteAsset,
  displayMain,
  index,
  setMainImage,
  allMedia,
}: {
  value: Media;
  disableReorder: boolean;
  deleteAsset: (filePath: string) => Promise<void>;
  displayMain?: boolean;
  index?: number;
  setMainImage?: (media: Media) => void;
  allMedia?: Media[];
}) => {
  const selectImageVariant = mediaVariantSelector(value, "compressed");
  const [isLoading, setIsLoading] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const contextMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target as Node)
      ) {
        setShowContextMenu(false);
      }
    };

    if (showContextMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showContextMenu]);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: value.url,
    disabled: disableReorder || !!selectImageVariant?.isLocal || isLoading,
  });

  const removeFile = async (value: Media) => {
    try {
      setIsLoading(true);
      await deleteAsset(value.url);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
    setShowContextMenu(true);
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <MediaViewerWrapper
      media={value}
      mediaList={allMedia}
      currentIndex={typeof index === "number" ? index : 0}
    >
      <motion.div
        initial="rest"
        whileHover="hover"
        animate="rest"
        className={`flex rounded-lg relative hover:shadow-lg w-full cursor-pointer ${
          !disableReorder ? "h-[120px]" : ""
        }`}
        ref={selectImageVariant?.isLocal ? undefined : setNodeRef}
        style={{ ...style, zIndex: isDragging ? 9999 : undefined }}
        onContextMenu={handleContextMenu}
        onClick={(e) => e.stopPropagation()}
      >
        {!disableReorder && (
          <motion.div
            variants={contextMenuMotion}
            className="absolute top-2 left-2 z-[999] opacity-0"
          >
            <div
              {...listeners}
              {...attributes}
              className="flex items-center gap-2"
              onClick={(e) => e.stopPropagation()}
            >
              <button className="flex items-center justify-center rounded border-none bg-white p-2 text-gray-700 outline-none focus-visible:ring-2 focus-visible:ring-martEye-400 focus-visible:ring-offset-2 group-[.is-dragging]:bg-martEye-400 group-[.is-dragging]:text-martEye-100 cursor-grab w-10">
                <svg
                  viewBox="0 0 20 20"
                  className="h-4 w-4 overflow-visible fill-current"
                >
                  <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
                </svg>
              </button>
            </div>
          </motion.div>
        )}

        <div className="w-full h-full p-1">
          <MediaDisplay
            value={value}
            showInitialIcon={displayMain && index === 0}
          />
        </div>

        <div className="absolute bottom-[4px] left-0 w-full flex items-center justify-center">
          <span className="capitalize text-xs font-medium text-gray-900 w-full px-2">
            {value.uploadProgress?.state === "start" && (
              <progress value={0} max={1}></progress>
            )}
            {value.uploadProgress?.state === "progress" && (
              <progress
                value={value.uploadProgress.progress}
                max={value.uploadProgress.total}
              ></progress>
            )}
            {value.uploadProgress?.state === "end" && (
              <progress value={1} max={1}></progress>
            )}
          </span>
        </div>

        {setMainImage && (
          <motion.div
            variants={contextMenuMotion}
            className="absolute top-2 right-2 opacity-0 z-[999]"
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              variant="tertiary"
              type="button"
              onClick={() => setMainImage?.(value)}
              title="Set as Main"
              isDisabled={isLoading}
              isLoading={isLoading}
              icon={<StarIconOutline className="h-4 w-4" />}
            ></Button>
          </motion.div>
        )}

        {showContextMenu && (
          <div
            ref={contextMenuRef}
            className="fixed bg-white rounded-lg shadow-lg py-1 z-[9999]"
            style={{
              left: contextMenuPosition.x,
              top: contextMenuPosition.y,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100"
              onClick={() => {
                removeFile(value);
                setShowContextMenu(false);
              }}
            >
              Remove Image
            </button>
          </div>
        )}
      </motion.div>
    </MediaViewerWrapper>
  );
};
