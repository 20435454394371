import { getMarketBranding } from "documents/utils";
import { type jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { InvoicePDFProps } from "../../../_importDocs";
import header from "../../sheep-tag-list/parts/header";
import footer from "../parts/footer";
import table from "../parts/table";

export default function Invoice(doc: jsPDF, props: InvoicePDFProps): jsPDF {
  const { market, invoice } = props;
  const isDraft = props.isDraft ?? false;
  const isVoid = props.invoice.status === "void";

  let title = invoice.clientType === "Seller" ? "Statement" : "Invoice";
  if (isDraft) {
    title = `Draft ${title}`;
  }

  if (isVoid) {
    title = `Void ${title}`;
  }
  const margin = 10;

  // Margin bottom 80mm

  const {
    dateFormat,
    textFillColor,
    buyerLegalText,
    sellerLegalText,
    tableHeaderFillColor,
    tableHeaderTextColor,
    tableStripeFillColor,
    tableStripeTextColor,
  } = getMarketBranding(market);

  header(doc, invoice, {
    output: false,
    dateFormat: dateFormat,
    title: title,
    margin: margin,
    textFillColor: textFillColor,
    logo: props.marketLogo,
    market: market,
  });

  footer(doc, invoice, {
    output: false,
    margin,
    textFillColor: textFillColor,
    legalText:
      invoice.clientType === "Buyer" ? buyerLegalText : sellerLegalText,
  });

  // Need to add margin bottom 80mm
  const pulloffHeight = 80;
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.getWidth();
  const footerEnd = doc.mainFooterPosition;
  const spaceAvailable = pulloffHeight - footerEnd;
  const startY = pageHeight - pulloffHeight;
  const endY = pageHeight - (startY + spaceAvailable);

  // Lets draw the text
  doc.mainFooterPosition = pulloffHeight;

  table(doc, invoice, {
    margin: margin,
    market,
    paymentLink: props.paymentLink,
    paymentQrCode: props.paymentQrCode,
    headerFillColor: tableHeaderFillColor,
    headerTextColor: tableHeaderTextColor,
    stripeFillColor: tableStripeFillColor,
    stripeTextColor: tableStripeTextColor,
  });

  passoutConfig(doc, props, {
    margin,
    startY,
    endY,
    textColor: tableHeaderFillColor,
  });

  header(doc, invoice, {
    output: true,
    dateFormat: dateFormat,
    title: title,
    margin: margin,
    textFillColor: textFillColor,
    logo: props.marketLogo,
    market: market,
  });

  footer(doc, invoice, {
    output: true,
    margin,
    textFillColor: textFillColor,
    legalText:
      invoice.clientType === "Buyer" ? buyerLegalText : sellerLegalText,
  });

  return doc;
}

// Parts that are only used on this document
interface PassoutConfigProps {
  margin: number;
  startY: number;
  endY: number;
  textColor: string;
}

const passoutConfig = (
  doc: jsPDF,
  props: InvoicePDFProps,
  config: PassoutConfigProps
) => {
  const invoice = props.invoice;
  const market = props.market;

  const margin = config.margin;
  const sales = invoice.status !== "draft" ? invoice?.sales : [];

  // Change the sales to a comma separated string
  const salesString = sales?.map((sale) => sale.name).join(", ");

  // Address
  const misc = [];
  const customerAddress = [
    invoice.address.company ? invoice.address.company : "",
    invoice.address.address1 ? invoice.address.address1 : "",
    invoice.address.address2 ? invoice.address.address2 : "",
    invoice.address.city ? invoice.address.city : "",
    invoice.address.province ? invoice.address.province : "",
    invoice.address.zip ? invoice.address.zip : "",
    invoice.address.country ? invoice.address.country : "",
  ];
  const usedCustomerAddress = customerAddress.filter(
    (line) => line !== "" && line !== "-"
  );
  const customerAddressLines = usedCustomerAddress.join(", ");
  misc.push(customerAddressLines);

  const usedMiscLines = misc.filter((line) => line !== "");
  const miscLines = usedMiscLines.join("\n");

  // Lot Numbers
  const lotNumbers = props.lots.map((lot) => lot.lotNumber).join(", ");

  // Eartags
  const eartags = getAllEarTags(props).join(", ");

  // Lets add the passout text
  autoTable(doc, {
    theme: "plain",
    head: [
      [
        {
          content: `${market.name}: Passout   |   Sale: ${salesString}`,
          styles: {
            textColor: config.textColor,
            fontStyle: "bold",
            fontSize: 12,
          },
          colSpan: 2,
        },
      ],
    ],
    body: [
      ["Name", invoice.name],
      ["Address", miscLines],
      ["Lot No.", lotNumbers],
      ["Eartags", eartags],
    ],
    startY: config.startY,
    styles: {
      cellWidth: "auto",
      overflow: "linebreak",
    },
    columnStyles: {
      0: { cellWidth: 30 },
      1: { cellWidth: "auto" },
    },
    headStyles: {},

    margin: {
      top: config.startY,
      right: margin,
      bottom: config.endY,
      left: margin,
    },

    willDrawCell: function (data) {
      if (data.section === "head") {
        doc.setFont("Inter", "bold");
      }

      if (data.section === "body") {
        doc.setFont("Inter", "normal");

        if (data.column.index === 0) {
          // Change it to be bold
          doc.setFont("Inter", "bold");
        }
      }
    },

    didDrawCell: function (data) {
      // We want to work only on the first column (index 0)
      if (
        data.column.index === 0 &&
        data.section === "body" &&
        data.pageNumber > 1
      ) {
        // Heuristic: if the cell's y coordinate is very close to the top margin,
        // then this cell is part of a row that continued from the previous page.
        if (data.cell.y <= data.settings.margin.top + 20) {
          // Manually draw the text for the first column on this page.
          // Adjust x and y offsets as needed.
          //@ts-ignore
          const text = data.cell.raw as string;
          const xPos = data.cell.x + 2; // small horizontal offset
          const yPos = data.cell.y + 5; // small vertical offset for alignment
          doc.text(`${text} Cont.`, xPos, yPos);
        }
      }
    },
  });
};

function getAllEarTags(props: InvoicePDFProps) {
  const earTags: string[] = [];

  // Ensure that props.lots is an array before iterating
  if (!Array.isArray(props.lots)) return earTags;

  props.lots.forEach((lot) => {
    // lot.itemMap should be an object
    if (lot.itemMap && typeof lot.itemMap === "object") {
      Object.keys(lot.itemMap).forEach((key) => {
        const item = lot.itemMap[key];
        // Check if attributes exist and contain "@eartag"
        if (item.attributes && item.attributes["@eartag"]) {
          earTags.push(item.attributes["@eartag"]);
        }
      });
    }
  });

  return earTags;
}
