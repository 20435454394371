import { Tooltip } from "@/components/Tootip";
import { formatAsCurrency } from "@/data/amounts";
import { classNames } from "@/data/classnames";
import { getDisplayName } from "@/data/customerUtils";
import {
  useFirestore,
  useLoadFirestoreQuery,
} from "@/data/studio-react/firebase/useFirestore";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import {
  ArrowsPointingOutIcon,
  ChatBubbleLeftRightIcon,
  CheckCircleIcon,
  CheckIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
  DocumentCurrencyPoundIcon,
  DocumentIcon,
  EnvelopeIcon,
  IdentificationIcon,
  MapPinIcon,
  PencilIcon,
  PhoneIcon,
  PlusIcon,
  TrashIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { format, formatDistanceToNow } from "date-fns";
import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import dynamic from "next/dynamic";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Address,
  BankDetails,
  Customer,
  Invoice,
  Market,
} from "../../../types";
import AddressEditorModal from "../../addresses/AddressEditorModal";
import { useInvoiceSheet } from "../../invoice-sheet/useInvoiceSheet";
import { useOpenPersonFlyout } from "../PersonFlyout";
import ActivityTimeline, {
  TimelineItem,
  TimelineRef,
} from "./ActivityTimeline";
// Import the RedTractorIcon
import { RedTractorIcon } from "../../quick-sheet/sections/RedTractorIcon";

// Google libphonenumber
const PhoneNumberUtil = require("google-libphonenumber").PhoneNumberUtil;
const PNF = require("google-libphonenumber").PhoneNumberFormat;
const phoneUtil = PhoneNumberUtil.getInstance();

// Import Headless UI Menu component
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BidderNumberPicker } from "../BidderNumberPicker";

// Import the BidderNumberPicker component at the top with other imports

interface SummaryTabProps {
  customer: Customer | null;
  marketId: string;
}

// Simple types for activity data
interface ActivityItem {
  id: string;
  type: string;
  date: Date;
  description: string;
  amount: number;
}

// Define a minimal type for lot data
interface LotData {
  id: string;
  name?: string;
  description?: string;
  createdAt?: { toDate: () => Date };
  hammerPriceInCents?: number;
}

// Utility function to calculate distance between two coordinates in km
function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  const R = 6371; // Radius of the Earth in km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km
  return distance;
}

// Utility function to format distance
function formatDistance(distance: number): string {
  if (distance < 1) {
    return `${Math.round(distance * 1000)} meters`;
  } else if (distance < 10) {
    return `${distance.toFixed(1)} km`;
  } else {
    return `${Math.round(distance)} km`;
  }
}

// Dynamically import the entire Leaflet Map component to avoid SSR issues
const LeafletMap = dynamic(() => import("../../../components/LeafletMap"), {
  ssr: false,
});

// Create a typed bank data object to avoid TypeScript errors
interface BankInfo {
  name: string;
  logo: string;
}

type BankDataType = {
  [key: string]: BankInfo;
};

// Database of common UK banks and their sort code prefixes
const BANK_DATA: BankDataType = {
  "04": { name: "Natwest", logo: "https://logo.clearbit.com/natwest.com" },
  "09": {
    name: "Santander",
    logo: "https://logo.clearbit.com/santander.co.uk",
  },
  "20": { name: "Barclays", logo: "https://logo.clearbit.com/barclays.co.uk" },
  "23": { name: "Barclays", logo: "https://logo.clearbit.com/barclays.co.uk" },
  "30": { name: "Lloyds", logo: "https://logo.clearbit.com/lloydsbank.com" },
  "40": { name: "HSBC", logo: "https://logo.clearbit.com/hsbc.co.uk" },
  "56": { name: "Halifax", logo: "https://logo.clearbit.com/halifax.co.uk" },
  "60": { name: "NatWest", logo: "https://logo.clearbit.com/natwest.com" },
  "77": { name: "Monzo", logo: "https://logo.clearbit.com/monzo.com" },
  "83": {
    name: "Nationwide",
    logo: "https://logo.clearbit.com/nationwide.co.uk",
  },
  "871": { name: "TSB", logo: "https://logo.clearbit.com/tsb.co.uk" },
};

// Farm Assurance Schemes options - add more as needed
const FARM_ASSURANCE_SCHEMES = [
  { value: "Red Tractor", label: "Red Tractor" },
  { value: "FABBL", label: "FABBL" },
  {
    value: "Scottish Quality Beef and Lamb (SQBLA)",
    label: "Scottish Quality Beef and Lamb (SQBLA)",
  },
  {
    value: "Farm Assured Welsh Livestock (FAWL)",
    label: "Farm Assured Welsh Livestock (FAWL)",
  },
  { value: "SAI Global", label: "SAI Global" },
  {
    value: "Quality Meat Scotland (QMS)",
    label: "Quality Meat Scotland (QMS)",
  },
  { value: "Red Tractor Dairy", label: "Red Tractor Dairy" },
  { value: "Other", label: "Other" },
];

// Species for Farm Assurance
const SPECIES = ["Cattle", "Sheep", "Pigs"];

// Function to check if a scheme is Red Tractor (includes various names)
const isRedTractor = (scheme?: string) => {
  if (!scheme) return false;
  const lcScheme = scheme.toLowerCase();
  return lcScheme.includes("red tractor") || lcScheme.includes("sai global");
};

// Function component for rendering the farm assurance section
function FarmAssuranceSection({
  customer,
  marketId,
  species,
  editingField,
  setEditingField,
  errorMessage,
  isSaving,
  saveField,
  cancelEditing,
  deleteFieldValue,
}: {
  customer: Customer | null;
  marketId: string;
  species: string;
  editingField: string | null;
  setEditingField: (field: string | null) => void;
  errorMessage: string | null;
  isSaving: boolean;
  saveField: (field: string) => void;
  cancelEditing: () => void;
  deleteFieldValue: (field: string) => void;
}) {
  const [faNumber, setFaNumber] = useState<string>("");
  const [faScheme, setFaScheme] = useState<string>("");
  const [faExpiry, setFaExpiry] = useState<string>("");
  const fieldPrefix = `farmAssurance${species}`;

  // Helper to get attribute value
  const getAttribute = (attributeKey: string): any => {
    if (!customer?.attributeDefaultsSeller) return null;
    const values = customer.attributeDefaultsSeller[attributeKey];
    return values && values.length > 0 ? values[0] : null;
  };

  // Get the farm assurance values for the current species
  useEffect(() => {
    if (customer) {
      const number = getAttribute(`farmAssuranceNumber${species}`);
      const scheme = getAttribute(`farmAssuranceScheme${species}`);
      const expiry = getAttribute(`farmAssuranceExpiry${species}`);

      setFaNumber(number || "");
      setFaScheme(scheme || "");

      // Convert timestamp to YYYY-MM-DD format for the date input
      if (expiry && typeof expiry.toDate === "function") {
        const date = expiry.toDate();
        setFaExpiry(format(date, "yyyy-MM-dd"));
      } else {
        setFaExpiry("");
      }
    }
  }, [customer, species]);

  // Check if expiry date has passed
  const isExpired = useMemo(() => {
    if (!faExpiry) return false;
    const expiryDate = new Date(faExpiry);
    return expiryDate < new Date();
  }, [faExpiry]);

  // Format expiry date for display
  const formattedExpiry = useMemo(() => {
    if (!faExpiry) return "";
    const expiryDate = new Date(faExpiry);
    return formatDistanceToNow(expiryDate, { addSuffix: true });
  }, [faExpiry]);

  // Determine if we should show the Red Tractor logo
  const showRedTractorLogo = isRedTractor(faScheme);

  // Handle save
  const handleSave = () => {
    saveField(`farmAssurance${species}`);
  };

  return (
    <div className="rounded-md bg-gray-50 p-3 group relative">
      <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
        <span className="flex items-center">
          <CheckCircleIcon className="mr-2 h-4 w-4 text-martEye-500" />
          Farm Assurance ({species})
        </span>
        <div className="flex space-x-1">
          {editingField !== fieldPrefix && (
            <button
              onClick={() => setEditingField(fieldPrefix)}
              className="text-gray-400 hover:text-martEye-500 focus:outline-none"
              title={`Edit ${species} Farm Assurance`}
            >
              <PencilIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      </h3>

      {editingField === fieldPrefix ? (
        <div className="mt-2 space-y-3">
          <div>
            <label
              htmlFor={`faNumber${species}`}
              className="block text-xs text-gray-500 mb-1"
            >
              Membership Number<span className="text-red-500">*</span>
            </label>
            <input
              id={`faNumber${species}`}
              type="text"
              value={faNumber}
              onChange={(e) => setFaNumber(e.target.value)}
              className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
              placeholder="Enter membership number"
            />
          </div>

          <div>
            <label
              htmlFor={`faScheme${species}`}
              className="block text-xs text-gray-500 mb-1"
            >
              Scheme
            </label>
            <select
              id={`faScheme${species}`}
              value={faScheme}
              onChange={(e) => setFaScheme(e.target.value)}
              className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
            >
              <option value="">Select scheme</option>
              {FARM_ASSURANCE_SCHEMES.map((scheme) => (
                <option key={scheme.value} value={scheme.value}>
                  {scheme.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor={`faExpiry${species}`}
              className="block text-xs text-gray-500 mb-1"
            >
              Expiry Date
            </label>
            <input
              id={`faExpiry${species}`}
              type="date"
              value={faExpiry}
              onChange={(e) => setFaExpiry(e.target.value)}
              className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
            />
          </div>

          {errorMessage && (
            <p className="text-xs text-red-500">{errorMessage}</p>
          )}

          <div className="flex justify-end mt-2 space-x-2">
            <div className="flex-grow text-left">
              <button
                onClick={() => deleteFieldValue(fieldPrefix)}
                className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                disabled={isSaving}
                title={`Remove ${species} Farm Assurance`}
              >
                <TrashIcon className="h-3 w-3" />
              </button>
            </div>
            <button
              onClick={cancelEditing}
              className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
              disabled={isSaving}
            >
              <XMarkIcon className="h-3 w-3 mr-1" />
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
              disabled={isSaving || !faNumber.trim()}
            >
              {isSaving ? (
                <span>Saving...</span>
              ) : (
                <>
                  <CheckIcon className="h-3 w-3 mr-1" />
                  Save
                </>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-1">
          {faNumber ? (
            <div className="flex flex-col">
              <div className="flex items-start">
                <div className="flex-grow">
                  <p className="text-base">
                    <span className="font-medium">Number:</span> {faNumber}
                  </p>
                  {faScheme && (
                    <p className="text-sm text-gray-600">
                      <span className="font-medium">Scheme:</span> {faScheme}
                    </p>
                  )}
                  {faExpiry && (
                    <p
                      className={`text-sm ${
                        isExpired ? "text-red-600 font-medium" : "text-gray-600"
                      }`}
                    >
                      <span className="font-medium">Expires:</span>{" "}
                      {formattedExpiry}
                    </p>
                  )}
                </div>
                {showRedTractorLogo && (
                  <div className="flex-shrink-0 ml-2">
                    <RedTractorIcon width={40} />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <p className="text-sm text-gray-600">
              No {species} Farm Assurance information provided.
            </p>
          )}
        </div>
      )}
    </div>
  );
}

// Replace the combined HerdFlockSection with separate sections for herd and flock numbers
function HerdNumberSection({
  customer,
  marketId,
  editingField,
  setEditingField,
  errorMessage,
  isSaving,
  saveField,
  cancelEditing,
  deleteFieldValue,
}: {
  customer: Customer | null;
  marketId: string;
  editingField: string | null;
  setEditingField: (field: string | null) => void;
  errorMessage: string | null;
  isSaving: boolean;
  saveField: (field: string) => void;
  cancelEditing: () => void;
  deleteFieldValue: (field: string) => void;
}) {
  const [herdNumber, setHerdNumber] = useState<string>("");

  // Helper to get attribute value from either buyer or seller defaults
  const getAttribute = (buyerKey: string, sellerKey: string): string => {
    if (!customer) return "";

    // Check seller attributes first
    if (
      customer.attributeDefaultsSeller &&
      customer.attributeDefaultsSeller[sellerKey]?.length > 0
    ) {
      return (customer.attributeDefaultsSeller[sellerKey][0] as string) || "";
    }

    // Then check buyer attributes
    if (
      customer.attributeDefaultsBuyer &&
      customer.attributeDefaultsBuyer[buyerKey]?.length > 0
    ) {
      return (customer.attributeDefaultsBuyer[buyerKey][0] as string) || "";
    }

    return "";
  };

  // Initialize values from customer data
  useEffect(() => {
    if (customer) {
      // Get herd number from either origin or destination
      const herd = getAttribute("herdNumberDestination", "herdNumberOrigin");
      setHerdNumber(herd);
    }
  }, [customer]);

  // Handle save button click
  const handleSave = () => {
    saveField("herdNumber");
  };

  // Add a handler for the Enter key
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave();
    }
  };

  return (
    <div className="rounded-md bg-gray-50 p-3 group relative">
      <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
        <span className="flex items-center">
          <IdentificationIcon className="mr-2 h-4 w-4 text-martEye-500" />
          Herd Number
        </span>
        <div className="flex space-x-1">
          {editingField !== "herdNumber" && (
            <button
              onClick={() => setEditingField("herdNumber")}
              className="text-gray-400 hover:text-martEye-500 focus:outline-none"
              title="Edit herd number"
            >
              <PencilIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      </h3>

      {editingField === "herdNumber" ? (
        <div className="mt-2 space-y-3">
          <div>
            <input
              id="herdNumber"
              type="text"
              value={herdNumber}
              onChange={(e) => setHerdNumber(e.target.value)}
              onKeyDown={handleKeyDown}
              className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
              placeholder="Enter herd number"
              autoFocus
            />
          </div>

          {errorMessage && (
            <p className="text-xs text-red-500">{errorMessage}</p>
          )}

          <div className="flex justify-end mt-2 space-x-2">
            <div className="flex-grow text-left">
              <button
                onClick={() => deleteFieldValue("herdNumber")}
                className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                disabled={isSaving}
                title="Remove herd number"
              >
                <TrashIcon className="h-3 w-3" />
              </button>
            </div>
            <button
              onClick={cancelEditing}
              className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
              disabled={isSaving}
            >
              <XMarkIcon className="h-3 w-3 mr-1" />
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
              disabled={isSaving}
            >
              {isSaving ? (
                <span>Saving...</span>
              ) : (
                <>
                  <CheckIcon className="h-3 w-3 mr-1" />
                  Save
                </>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-1">
          {herdNumber ? (
            <p className="text-base">{herdNumber}</p>
          ) : (
            <p className="text-sm text-gray-600">No herd number provided.</p>
          )}
        </div>
      )}
    </div>
  );
}

function FlockNumberSection({
  customer,
  marketId,
  editingField,
  setEditingField,
  errorMessage,
  isSaving,
  saveField,
  cancelEditing,
  deleteFieldValue,
}: {
  customer: Customer | null;
  marketId: string;
  editingField: string | null;
  setEditingField: (field: string | null) => void;
  errorMessage: string | null;
  isSaving: boolean;
  saveField: (field: string) => void;
  cancelEditing: () => void;
  deleteFieldValue: (field: string) => void;
}) {
  const [flockNumber, setFlockNumber] = useState<string>("");

  // Helper to get attribute value from either buyer or seller defaults
  const getAttribute = (buyerKey: string, sellerKey: string): string => {
    if (!customer) return "";

    // Check seller attributes first
    if (
      customer.attributeDefaultsSeller &&
      customer.attributeDefaultsSeller[sellerKey]?.length > 0
    ) {
      return (customer.attributeDefaultsSeller[sellerKey][0] as string) || "";
    }

    // Then check buyer attributes
    if (
      customer.attributeDefaultsBuyer &&
      customer.attributeDefaultsBuyer[buyerKey]?.length > 0
    ) {
      return (customer.attributeDefaultsBuyer[buyerKey][0] as string) || "";
    }

    return "";
  };

  // Initialize values from customer data
  useEffect(() => {
    if (customer) {
      // Get flock number from either origin or destination
      const flock = getAttribute("flockNumberDestination", "flockNumberOrigin");
      setFlockNumber(flock);
    }
  }, [customer]);

  // Handle save button click
  const handleSave = () => {
    saveField("flockNumber");
  };

  // Add a handler for the Enter key
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave();
    }
  };

  return (
    <div className="rounded-md bg-gray-50 p-3 group relative">
      <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
        <span className="flex items-center">
          <IdentificationIcon className="mr-2 h-4 w-4 text-martEye-500" />
          Flock Number
        </span>
        <div className="flex space-x-1">
          {editingField !== "flockNumber" && (
            <button
              onClick={() => setEditingField("flockNumber")}
              className="text-gray-400 hover:text-martEye-500 focus:outline-none"
              title="Edit flock number"
            >
              <PencilIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      </h3>

      {editingField === "flockNumber" ? (
        <div className="mt-2 space-y-3">
          <div>
            <input
              id="flockNumber"
              type="text"
              value={flockNumber}
              onChange={(e) => setFlockNumber(e.target.value)}
              onKeyDown={handleKeyDown}
              className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
              placeholder="Enter flock number"
              autoFocus
            />
          </div>

          {errorMessage && (
            <p className="text-xs text-red-500">{errorMessage}</p>
          )}

          <div className="flex justify-end mt-2 space-x-2">
            <div className="flex-grow text-left">
              <button
                onClick={() => deleteFieldValue("flockNumber")}
                className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                disabled={isSaving}
                title="Remove flock number"
              >
                <TrashIcon className="h-3 w-3" />
              </button>
            </div>
            <button
              onClick={cancelEditing}
              className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
              disabled={isSaving}
            >
              <XMarkIcon className="h-3 w-3 mr-1" />
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
              disabled={isSaving}
            >
              {isSaving ? (
                <span>Saving...</span>
              ) : (
                <>
                  <CheckIcon className="h-3 w-3 mr-1" />
                  Save
                </>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-1">
          {flockNumber ? (
            <p className="text-base">{flockNumber}</p>
          ) : (
            <p className="text-sm text-gray-600">No flock number provided.</p>
          )}
        </div>
      )}
    </div>
  );
}

// Add a CPH Number Section - similar to Herd and Flock Number sections
function CphNumberSection({
  customer,
  marketId,
  editingField,
  setEditingField,
  errorMessage,
  isSaving,
  saveField,
  cancelEditing,
  deleteFieldValue,
}: {
  customer: Customer | null;
  marketId: string;
  editingField: string | null;
  setEditingField: (field: string | null) => void;
  errorMessage: string | null;
  isSaving: boolean;
  saveField: (field: string) => void;
  cancelEditing: () => void;
  deleteFieldValue: (field: string) => void;
}) {
  const [cphNumber, setCphNumber] = useState<string>("");
  const [cphError, setCphError] = useState<string | null>(null);

  // Format CPH number with slashes (XX/YYY/ZZZZ)
  const formatCphNumber = (cph: string): string => {
    // Remove any non-digit characters
    const digits = cph.replace(/\D/g, "");

    // Format with slashes based on the number of digits
    if (digits.length <= 2) {
      return digits;
    } else if (digits.length <= 5) {
      return `${digits.slice(0, 2)}/${digits.slice(2)}`;
    } else {
      return `${digits.slice(0, 2)}/${digits.slice(2, 5)}/${digits.slice(
        5,
        9
      )}`;
    }
  };

  // Validate CPH number format (XX/YYY/ZZZZ)
  const isValidCphFormat = (cph: string): boolean => {
    // Check if we have the correct format after removing non-digits
    const digits = cph.replace(/\D/g, "");

    // Need exactly 9 digits (2 for county, 3 for parish, 4 for holding)
    if (digits.length !== 9) {
      return false;
    }

    // Check if the formatting is correct (XX/YYY/ZZZZ)
    const pattern = /^\d{2}\/\d{3}\/\d{4}$/;
    return pattern.test(formatCphNumber(digits));
  };

  // Handle CPH number input with auto-formatting
  const handleCphInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const formattedValue = formatCphNumber(input);
    setCphNumber(formattedValue);

    // Clear any previous error
    setCphError(null);
  };

  // Handle key press events (for Enter key)
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      validateAndSave();
    }
  };

  // Validate and save the CPH number
  const validateAndSave = () => {
    // Validate the CPH number before saving
    if (cphNumber && !isValidCphFormat(cphNumber)) {
      setCphError(
        "CPH number must be in the format XX/YYY/ZZZZ (9 digits total)"
      );
      return;
    }

    saveField("cphNumber");
  };

  // Helper to get attribute value from either buyer or seller defaults
  const getAttribute = (buyerKey: string, sellerKey: string): string => {
    if (!customer) return "";

    // Check seller attributes first
    if (
      customer.attributeDefaultsSeller &&
      customer.attributeDefaultsSeller[sellerKey]?.length > 0
    ) {
      return (customer.attributeDefaultsSeller[sellerKey][0] as string) || "";
    }

    // Then check buyer attributes
    if (
      customer.attributeDefaultsBuyer &&
      customer.attributeDefaultsBuyer[buyerKey]?.length > 0
    ) {
      return (customer.attributeDefaultsBuyer[buyerKey][0] as string) || "";
    }

    return "";
  };

  // Initialize values from customer data
  useEffect(() => {
    if (customer) {
      // Get CPH number from either origin or destination
      const cph = getAttribute("cphNumberDestination", "cphNumberOrigin");
      if (cph) {
        // Format it properly when loading
        setCphNumber(formatCphNumber(cph));
      } else {
        setCphNumber("");
      }
    }
  }, [customer]);

  // Handle save button click
  const handleSave = () => {
    validateAndSave();
  };

  return (
    <div className="rounded-md bg-gray-50 p-3 group relative">
      <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
        <span className="flex items-center">
          <MapPinIcon className="mr-2 h-4 w-4 text-martEye-500" />
          CPH Number
        </span>
        <div className="flex space-x-1">
          {editingField !== "cphNumber" && (
            <button
              onClick={() => setEditingField("cphNumber")}
              className="text-gray-400 hover:text-martEye-500 focus:outline-none"
              title="Edit CPH number"
            >
              <PencilIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      </h3>

      {editingField === "cphNumber" ? (
        <div className="mt-2 space-y-3">
          <div>
            <input
              id="cphNumber"
              type="text"
              value={cphNumber}
              onChange={handleCphInput}
              onKeyDown={handleKeyDown}
              className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
              placeholder="XX/YYY/ZZZZ"
              maxLength={12} // Account for 9 digits plus slashes
              autoFocus
            />
          </div>

          {cphError && <p className="text-xs text-red-500 mt-1">{cphError}</p>}

          {errorMessage && !cphError && (
            <p className="text-xs text-red-500 mt-1">{errorMessage}</p>
          )}

          <div className="flex justify-end mt-2 space-x-2">
            <div className="flex-grow text-left">
              <button
                onClick={() => deleteFieldValue("cphNumber")}
                className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                disabled={isSaving}
                title="Remove CPH number"
              >
                <TrashIcon className="h-3 w-3" />
              </button>
            </div>
            <button
              onClick={cancelEditing}
              className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
              disabled={isSaving}
            >
              <XMarkIcon className="h-3 w-3 mr-1" />
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
              disabled={
                isSaving || (!!cphNumber && !isValidCphFormat(cphNumber))
              }
            >
              {isSaving ? (
                <span>Saving...</span>
              ) : (
                <>
                  <CheckIcon className="h-3 w-3 mr-1" />
                  Save
                </>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-1">
          {cphNumber ? (
            <p className="text-base">{cphNumber}</p>
          ) : (
            <p className="text-sm text-gray-600">No CPH number provided.</p>
          )}
        </div>
      )}
    </div>
  );
}

// Add a function component for the Vet Attestation section
function VetAttestationSection({
  customer,
  marketId,
  editingField,
  setEditingField,
  errorMessage,
  isSaving,
  saveField,
  cancelEditing,
  deleteFieldValue,
}: {
  customer: Customer | null;
  marketId: string;
  editingField: string | null;
  setEditingField: (field: string | null) => void;
  errorMessage: string | null;
  isSaving: boolean;
  saveField: (field: string) => void;
  cancelEditing: () => void;
  deleteFieldValue: (field: string) => void;
}) {
  const [vanNumber, setVanNumber] = useState<string>("");
  const [vanExpiry, setVanExpiry] = useState<string>("");
  const fieldPrefix = "vetAttestation";

  // Helper to get attribute value
  const getAttributeValue = (attributeKey: string): any => {
    if (!customer?.attributeDefaultsSeller) return null;
    const values = customer.attributeDefaultsSeller[attributeKey];
    return values && values.length > 0 ? values[0] : null;
  };

  // Initialize values from customer data
  useEffect(() => {
    if (customer) {
      const vanNumberValue = getAttributeValue("vanNumber");
      setVanNumber(vanNumberValue || "");

      // Convert timestamp to YYYY-MM-DD format for the date input
      const expiry = getAttributeValue("vanNumberExpiry");
      if (expiry && typeof expiry.toDate === "function") {
        const date = expiry.toDate();
        setVanExpiry(format(date, "yyyy-MM-dd"));
      } else {
        setVanExpiry("");
      }
    }
  }, [customer]);

  // Check if expiry date has passed
  const isExpired = useMemo(() => {
    if (!vanExpiry) return false;
    const expiryDate = new Date(vanExpiry);
    return expiryDate < new Date();
  }, [vanExpiry]);

  // Format expiry date for display
  const formattedExpiry = useMemo(() => {
    if (!vanExpiry) return "";
    const expiryDate = new Date(vanExpiry);
    return formatDistanceToNow(expiryDate, { addSuffix: true });
  }, [vanExpiry]);

  // Handle Enter key for saving
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave();
    }
  };

  // Handle save button click
  const handleSave = () => {
    saveField(fieldPrefix);
  };

  return (
    <div className="rounded-md bg-gray-50 p-3 group relative">
      <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
        <span className="flex items-center">
          <DocumentIcon className="mr-2 h-4 w-4 text-martEye-500" />
          Vet Attestation
        </span>
        <div className="flex space-x-1">
          {editingField !== fieldPrefix && (
            <button
              onClick={() => setEditingField(fieldPrefix)}
              className="text-gray-400 hover:text-martEye-500 focus:outline-none"
              title="Edit Vet Attestation"
            >
              <PencilIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      </h3>

      {editingField === fieldPrefix ? (
        <div className="mt-2 space-y-3">
          <div>
            <label
              htmlFor="vanNumber"
              className="block text-xs text-gray-500 mb-1"
            >
              VAN Number<span className="text-red-500">*</span>
            </label>
            <input
              id="vanNumber"
              type="text"
              value={vanNumber}
              onChange={(e) => setVanNumber(e.target.value)}
              onKeyDown={handleKeyDown}
              className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
              placeholder="Enter VAN number"
              autoFocus
            />
          </div>

          <div>
            <label
              htmlFor="vanExpiry"
              className="block text-xs text-gray-500 mb-1"
            >
              Expiry Date
            </label>
            <input
              id="vanExpiry"
              type="date"
              value={vanExpiry}
              onChange={(e) => setVanExpiry(e.target.value)}
              className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
            />
          </div>

          {errorMessage && (
            <p className="text-xs text-red-500 mt-1">{errorMessage}</p>
          )}

          <div className="flex justify-end mt-2 space-x-2">
            <div className="flex-grow text-left">
              <button
                onClick={() => deleteFieldValue(fieldPrefix)}
                className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                disabled={isSaving}
                title="Remove Vet Attestation"
              >
                <TrashIcon className="h-3 w-3" />
              </button>
            </div>
            <button
              onClick={cancelEditing}
              className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
              disabled={isSaving}
            >
              <XMarkIcon className="h-3 w-3 mr-1" />
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
              disabled={isSaving || !vanNumber.trim()}
            >
              {isSaving ? (
                <span>Saving...</span>
              ) : (
                <>
                  <CheckIcon className="h-3 w-3 mr-1" />
                  Save
                </>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-1">
          {vanNumber ? (
            <div className="flex flex-col">
              <div className="flex items-start">
                <div className="flex-grow">
                  <p className="text-base">
                    <span className="font-medium">Number:</span> {vanNumber}
                  </p>
                  {vanExpiry && (
                    <p
                      className={`text-sm ${
                        isExpired ? "text-red-600 font-medium" : "text-gray-600"
                      }`}
                    >
                      <span className="font-medium">Expires:</span>{" "}
                      {formattedExpiry}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <p className="text-sm text-gray-600">
              No Vet Attestation information provided.
            </p>
          )}
        </div>
      )}
    </div>
  );
}

// Add a function component for the Pig Slap Mark section
function SlapMarkSection({
  customer,
  marketId,
  editingField,
  setEditingField,
  errorMessage,
  isSaving,
  saveField,
  cancelEditing,
  deleteFieldValue,
}: {
  customer: Customer | null;
  marketId: string;
  editingField: string | null;
  setEditingField: (field: string | null) => void;
  errorMessage: string | null;
  isSaving: boolean;
  saveField: (field: string) => void;
  cancelEditing: () => void;
  deleteFieldValue: (field: string) => void;
}) {
  const [slapMark, setSlapMark] = useState<string>("");

  // Helper to get attribute value from either buyer or seller defaults
  const getAttribute = (buyerKey: string, sellerKey: string): string => {
    if (!customer) return "";

    // Check seller attributes first
    if (
      customer.attributeDefaultsSeller &&
      customer.attributeDefaultsSeller[sellerKey]?.length > 0
    ) {
      return (customer.attributeDefaultsSeller[sellerKey][0] as string) || "";
    }

    // Then check buyer attributes
    if (
      customer.attributeDefaultsBuyer &&
      customer.attributeDefaultsBuyer[buyerKey]?.length > 0
    ) {
      return (customer.attributeDefaultsBuyer[buyerKey][0] as string) || "";
    }

    return "";
  };

  // Initialize values from customer data
  useEffect(() => {
    if (customer) {
      // Get slap mark from either origin or destination
      const mark = getAttribute("slapMarkDestination", "slapMarkOrigin");
      setSlapMark(mark);
    }
  }, [customer]);

  // Handle Enter key to save
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave();
    }
  };

  // Handle save button click
  const handleSave = () => {
    saveField("slapMark");
  };

  return (
    <div className="rounded-md bg-gray-50 p-3 group relative">
      <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
        <span className="flex items-center">
          <IdentificationIcon className="mr-2 h-4 w-4 text-martEye-500" />
          Pig Slap Mark
        </span>
        <div className="flex space-x-1">
          {editingField !== "slapMark" && (
            <button
              onClick={() => setEditingField("slapMark")}
              className="text-gray-400 hover:text-martEye-500 focus:outline-none"
              title="Edit pig slap mark"
            >
              <PencilIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      </h3>

      {editingField === "slapMark" ? (
        <div className="mt-2 space-y-3">
          <div>
            <input
              id="slapMark"
              type="text"
              value={slapMark}
              onChange={(e) => setSlapMark(e.target.value)}
              onKeyDown={handleKeyDown}
              className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
              placeholder="Enter pig slap mark"
              autoFocus
            />
          </div>

          {errorMessage && (
            <p className="text-xs text-red-500 mt-1">{errorMessage}</p>
          )}

          <div className="flex justify-end mt-2 space-x-2">
            <div className="flex-grow text-left">
              <button
                onClick={() => deleteFieldValue("slapMark")}
                className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                disabled={isSaving}
                title="Remove pig slap mark"
              >
                <TrashIcon className="h-3 w-3" />
              </button>
            </div>
            <button
              onClick={cancelEditing}
              className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
              disabled={isSaving}
            >
              <XMarkIcon className="h-3 w-3 mr-1" />
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
              disabled={isSaving}
            >
              {isSaving ? (
                <span>Saving...</span>
              ) : (
                <>
                  <CheckIcon className="h-3 w-3 mr-1" />
                  Save
                </>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-1">
          {slapMark ? (
            <p className="text-base">{slapMark}</p>
          ) : (
            <p className="text-sm text-gray-600">No pig slap mark provided.</p>
          )}
        </div>
      )}
    </div>
  );
}

// Add a function component for the Movement Reporting section
function MovementReportingSection({
  customer,
  marketId,
  editingField,
  setEditingField,
  errorMessage,
  isSaving,
  saveField,
  cancelEditing,
  deleteFieldValue,
}: {
  customer: Customer | null;
  marketId: string;
  editingField: string | null;
  setEditingField: (field: string | null) => void;
  errorMessage: string | null;
  isSaving: boolean;
  saveField: (field: string) => void;
  cancelEditing: () => void;
  deleteFieldValue: (field: string) => void;
}) {
  const [reportOffFarm, setReportOffFarm] = useState<boolean>(false);
  const [reportOnFarm, setReportOnFarm] = useState<boolean>(false);

  // Helper to get attribute value from either buyer or seller defaults as boolean
  const getBoolAttribute = (buyerKey: string, sellerKey: string): boolean => {
    if (!customer) return false;

    // Check seller attributes first
    if (
      customer.attributeDefaultsSeller &&
      customer.attributeDefaultsSeller[sellerKey]?.length > 0
    ) {
      return Boolean(customer.attributeDefaultsSeller[sellerKey][0]);
    }

    // Then check buyer attributes
    if (
      customer.attributeDefaultsBuyer &&
      customer.attributeDefaultsBuyer[buyerKey]?.length > 0
    ) {
      return Boolean(customer.attributeDefaultsBuyer[buyerKey][0]);
    }

    return false;
  };

  // Initialize values from customer data
  useEffect(() => {
    if (customer) {
      // Get movement reporting preferences
      const offFarm = getBoolAttribute("", "shouldReportMovementsFromOrigin");
      const onFarm = getBoolAttribute("shouldReportMovementsToDestination", "");
      setReportOffFarm(offFarm);
      setReportOnFarm(onFarm);
    }
  }, [customer]);

  // Handle save button click
  const handleSave = () => {
    saveField("movementReporting");
  };

  return (
    <div className="rounded-md bg-gray-50 p-3 group relative">
      <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
        <span className="flex items-center">
          <MapPinIcon className="mr-2 h-4 w-4 text-martEye-500" />
          Movement Reporting
        </span>
        <div className="flex space-x-1">
          {editingField !== "movementReporting" && (
            <button
              onClick={() => setEditingField("movementReporting")}
              className="text-gray-400 hover:text-martEye-500 focus:outline-none"
              title="Edit movement reporting preferences"
            >
              <PencilIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      </h3>

      {editingField === "movementReporting" ? (
        <div className="mt-2 space-y-3">
          <div className="space-y-2">
            <div className="flex items-center">
              <input
                id="reportOffFarm"
                type="checkbox"
                checked={reportOffFarm}
                onChange={(e) => setReportOffFarm(e.target.checked)}
                className="h-4 w-4 rounded border-gray-300 text-martEye-600 focus:ring-martEye-500"
              />
              <label
                htmlFor="reportOffFarm"
                className="ml-2 block text-sm text-gray-700"
              >
                Report movements Off Farm
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="reportOnFarm"
                type="checkbox"
                checked={reportOnFarm}
                onChange={(e) => setReportOnFarm(e.target.checked)}
                className="h-4 w-4 rounded border-gray-300 text-martEye-600 focus:ring-martEye-500"
              />
              <label
                htmlFor="reportOnFarm"
                className="ml-2 block text-sm text-gray-700"
              >
                Report movements On Farm
              </label>
            </div>
          </div>

          {errorMessage && (
            <p className="text-xs text-red-500 mt-1">{errorMessage}</p>
          )}

          <div className="flex justify-end mt-2 space-x-2">
            <div className="flex-grow text-left">
              <button
                onClick={() => deleteFieldValue("movementReporting")}
                className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                disabled={isSaving}
                title="Reset movement reporting preferences"
              >
                <TrashIcon className="h-3 w-3" />
              </button>
            </div>
            <button
              onClick={cancelEditing}
              className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
              disabled={isSaving}
            >
              <XMarkIcon className="h-3 w-3 mr-1" />
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
              disabled={isSaving}
            >
              {isSaving ? (
                <span>Saving...</span>
              ) : (
                <>
                  <CheckIcon className="h-3 w-3 mr-1" />
                  Save
                </>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-1">
          <div className="flex flex-col">
            <div className="flex items-start">
              <div className="flex-grow">
                <p className="text-sm text-gray-600">
                  {reportOffFarm ? (
                    <span className="text-green-600">✓ </span>
                  ) : (
                    <span className="text-gray-400">✗ </span>
                  )}
                  Report movements Off Farm
                </p>
                <p className="text-sm text-gray-600">
                  {reportOnFarm ? (
                    <span className="text-green-600">✓ </span>
                  ) : (
                    <span className="text-gray-400">✗ </span>
                  )}
                  Report movements On Farm
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export function SummaryTab(props: SummaryTabProps) {
  let { customer, marketId } = props;
  let firestore = useFirestore();
  let customerUid = customer?.id || null;
  let defaults = useStudioStream("marketDefaultSettings", marketId);
  let currency = defaults?.defaultCurrency || "GBP";
  let { openPersonFlyout } = useOpenPersonFlyout();
  let { openInvoiceSheet } = useInvoiceSheet();

  // For copying different information
  let [copiedField, setCopiedField] = useState<string | null>(null);

  // For editing fields
  let [editingField, setEditingField] = useState<string | null>(null);
  let [nameValue, setNameValue] = useState<string>("");
  let [emailValue, setEmailValue] = useState<string>("");
  let [phoneValue, setPhoneValue] = useState<string>("");
  let [vatValue, setVatValue] = useState<string>("");
  let [accountValue, setAccountValue] = useState<string>("");
  let [accountNumberValue, setAccountNumberValue] = useState<string>("");
  let [sortCodeValue, setSortCodeValue] = useState<string>("");
  let [accountNameValue, setAccountNameValue] = useState<string>("");
  let [bankName, setBankName] = useState<string>("");
  let [bankLogo, setBankLogo] = useState<string>("");
  let [isSaving, setIsSaving] = useState(false);
  let [errorMessage, setErrorMessage] = useState<string | null>(null);

  // For market info
  let [marketDetails, setMarketDetails] = useState<Market | null>(null);
  let [distance, setDistance] = useState<number | null>(null);

  // For leaflet map
  let [mapLoaded, setMapLoaded] = useState(false);

  // For getting market countryCode
  let market = useStudioStream("market", marketId);

  // Add state for AddressEditorModal
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  // Format sort code with dashes
  const formatSortCode = (code: string): string => {
    // Remove any non-digit characters
    const digits = code.replace(/\D/g, "");

    // Format with dashes if we have enough digits
    if (digits.length <= 2) {
      return digits;
    } else if (digits.length <= 4) {
      return `${digits.slice(0, 2)}-${digits.slice(2)}`;
    } else {
      return `${digits.slice(0, 2)}-${digits.slice(2, 4)}-${digits.slice(
        4,
        6
      )}`;
    }
  };

  // Detect bank from sort code
  const detectBankFromSortCode = (sortCode: string): void => {
    // Remove dashes for comparison
    const cleanCode = sortCode.replace(/-/g, "");

    if (cleanCode.length < 2) {
      setBankName("");
      setBankLogo("");
      return;
    }

    // Try to match with known banks
    const firstTwoDigits = cleanCode.substring(0, 2);
    const firstThreeDigits = cleanCode.substring(0, 3);

    if (BANK_DATA[firstThreeDigits]) {
      setBankName(BANK_DATA[firstThreeDigits].name);
      setBankLogo(BANK_DATA[firstThreeDigits].logo);
    } else if (BANK_DATA[firstTwoDigits]) {
      setBankName(BANK_DATA[firstTwoDigits].name);
      setBankLogo(BANK_DATA[firstTwoDigits].logo);
    } else {
      setBankName("");
      setBankLogo("");
    }
  };

  // Handle sort code input with auto-formatting
  const handleSortCodeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const formattedValue = formatSortCode(input);
    setSortCodeValue(formattedValue);
    detectBankFromSortCode(formattedValue);
  };

  // Set initial values when customer changes
  useEffect(() => {
    if (customer) {
      setNameValue(getDisplayName(customer) || "");
      setEmailValue(customer.email?.email || "");
      setPhoneValue(customer.phoneNumber?.phoneNumber || "");
      setVatValue(customer.vatNumber || "");
      setAccountValue(customer.accountNumber || "");
      // Initialize bank details
      setAccountNumberValue(customer.bankDetails?.accountNumber || "");
      setSortCodeValue(customer.bankDetails?.sortCode || "");
      setAccountNameValue(
        customer.bankDetails?.accountName || getDisplayName(customer) || ""
      );

      // Determine bank name and logo if sort code exists
      if (customer.bankDetails?.sortCode) {
        const formattedSortCode = formatSortCode(customer.bankDetails.sortCode);
        setSortCodeValue(formattedSortCode);
        detectBankFromSortCode(formattedSortCode);
      }
    }
  }, [customer]);

  // Load Leaflet CSS
  useEffect(() => {
    // Only run in the browser
    if (typeof window !== "undefined") {
      // Add Leaflet CSS if not already present
      if (!document.querySelector('link[href*="leaflet.css"]')) {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = "https://unpkg.com/leaflet@1.9.4/dist/leaflet.css";
        link.integrity = "sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=";
        link.crossOrigin = "";
        document.head.appendChild(link);
      }

      setMapLoaded(true);
    }
  }, []);

  // Fetch market details
  useEffect(() => {
    async function fetchMarketDetails() {
      if (!marketId) return;

      try {
        const marketRef = doc(firestore, `markets/${marketId}`);
        const marketSnap = await getDoc(marketRef);

        if (marketSnap.exists()) {
          setMarketDetails(marketSnap.data() as Market);
        }
      } catch (error) {
        console.error("Error fetching market details:", error);
      }
    }

    fetchMarketDetails();
  }, [marketId, firestore]);

  // Calculate distance when both market and customer coordinates are available
  useEffect(() => {
    if (!marketDetails || !customer) return;

    //@ts-ignore
    const customerCoords = customer.address?.coordinates;
    const marketCoords = marketDetails.address?.coordinates;

    if (!customerCoords || !marketCoords) return;

    const dist = calculateDistance(
      customerCoords.latitude,
      customerCoords.longitude,
      marketCoords.latitude,
      marketCoords.longitude
    );

    setDistance(dist);
  }, [marketDetails, customer]);

  // Get customer's invoices (limited to 5 most recent)
  let invoicesQuery = useMemo(() => {
    if (!customerUid) {
      return null;
    }
    return query(
      collection(firestore, `markets/${marketId}/invoices`),
      where(`customerId`, `==`, customerUid),
      orderBy("createdAt", "desc"),
      limit(5)
    );
  }, [marketId, customerUid, firestore]);

  let invoicesLoadInfo = useLoadFirestoreQuery<Invoice>(invoicesQuery, {
    idField: "id",
  });

  // Get customer balances
  let cxTrdReceivable =
    useStudioStream("balance", marketId, {
      account: customerUid && `${customerUid}:asset:trade receivable`,
    }) || 0;

  let cxTrdPayable =
    useStudioStream("balance", marketId, {
      account: customerUid && `${customerUid}:liability:trade payable`,
    }) || 0;

  let customerBalances =
    cxTrdReceivable &&
    cxTrdPayable &&
    !cxTrdReceivable.loading &&
    !cxTrdPayable.loading
      ? {
          tradeReceivable: cxTrdReceivable.data!,
          tradePayable: cxTrdPayable.data!,
        }
      : null;

  let currentBalance =
    (customerBalances?.tradeReceivable ?? 0) +
    (customerBalances?.tradePayable ?? 0);

  // Get buying/selling info
  let boughtLotsQuery = useMemo(() => {
    if (!customerUid) {
      return null;
    }
    return query(
      collection(firestore, `markets/${marketId}/lots`),
      where(`buyerId`, `==`, customerUid),
      limit(50)
    );
  }, [marketId, customerUid, firestore]);

  let boughtLotsInfo = useLoadFirestoreQuery<LotData>(boughtLotsQuery, {
    idField: "id",
  });

  let soldLotsQuery = useMemo(() => {
    if (!customerUid) {
      return null;
    }
    return query(
      collection(firestore, `markets/${marketId}/lots`),
      where(`sellerId`, `==`, customerUid),
      limit(50)
    );
  }, [marketId, customerUid, firestore]);

  let soldLotsInfo = useLoadFirestoreQuery<LotData>(soldLotsQuery, {
    idField: "id",
  });

  let boughtLots = boughtLotsInfo.data || [];
  let soldLots = soldLotsInfo.data || [];

  // Example buying activity data to show when no real data is available
  let exampleBuyingActivity: ActivityItem[] = [
    {
      id: "b1",
      type: "Cattle",
      date: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
      description: "Purchased 5 Aberdeen Angus",
      amount: 2500,
    },
    {
      id: "b2",
      type: "Equipment",
      date: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000),
      description: "Farming equipment",
      amount: 1200,
    },
    {
      id: "b3",
      type: "Sheep",
      date: new Date(Date.now() - 21 * 24 * 60 * 60 * 1000),
      description: "Purchased 10 Suffolk lambs",
      amount: 850,
    },
  ];

  // Example selling activity data
  let exampleSellingActivity: ActivityItem[] = [
    {
      id: "s1",
      type: "Sheep",
      date: new Date(Date.now() - 3 * 24 * 60 * 60 * 1000),
      description: "Sold 15 Dorset ewes",
      amount: 1800,
    },
    {
      id: "s2",
      type: "Cattle",
      date: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
      description: "Sold 3 Hereford steers",
      amount: 3200,
    },
  ];

  // Copy text to clipboard with feedback
  const copyToClipboard = (text: string | undefined, fieldName: string) => {
    if (!text) return;

    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedField(fieldName);
        setTimeout(() => {
          setCopiedField(null);
        }, 1500);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  // Helper to format address as a single string for copying
  const getFormattedAddress = (): string => {
    if (!customer?.address?.address) return "";

    const addr = customer.address.address;
    const parts = [
      addr.company,
      addr.address1,
      addr.address2,
      addr.city,
      addr.province,
      addr.zip,
    ].filter(Boolean);

    return parts.join(", ");
  };

  // Format a date showing how long ago it was
  const formatRelativeDate = (date: Date) => {
    return formatDistanceToNow(date, { addSuffix: true });
  };

  // Format a currency amount
  const formatCurrency = (amount: number) => {
    return formatAsCurrency(currency, amount);
  };

  // Get status color for invoices
  const getStatusColor = (status: string | undefined) => {
    if (!status) return "bg-gray-100 text-gray-800";

    switch (status.toLowerCase()) {
      case "paid":
        return "bg-green-100 text-green-800";
      case "overdue":
        return "bg-red-100 text-red-800";
      case "void":
        return "bg-gray-100 text-gray-800";
      case "draft":
      case "issued":
      default:
        return "bg-yellow-100 text-yellow-800";
    }
  };

  // Format address for display
  const formatAddress = (address?: Address): string => {
    if (!address) return "—";

    const parts = [
      address.address1,
      address.address2,
      address.city,
      address.province,
      address.zip,
    ].filter(Boolean);

    return parts.join(", ");
  };

  // Create Google Maps URL from address
  const getGoogleMapsUrl = (): string => {
    if (!customer?.address?.address) return "";

    const formattedAddress = getFormattedAddress();
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      formattedAddress
    )}`;
  };

  // Get coordinates for map
  const customerCoords = customer?.address?.address?.coordinates;
  const marketCoords = marketDetails?.address?.coordinates;

  // Prepare map data
  const mapData = useMemo(() => {
    const data = {
      customerLocation: customerCoords
        ? {
            lat: customerCoords.latitude,
            lng: customerCoords.longitude,
            name: getDisplayName(customer) || "Customer",
          }
        : null,
      marketLocation: marketCoords
        ? {
            lat: marketCoords.latitude,
            lng: marketCoords.longitude,
            name: marketDetails?.name || "Market",
          }
        : null,
    };

    // Calculate a default center and zoom level
    let center = [54.5, -3.0]; // Default to center of UK
    let zoom = 6;

    if (data.customerLocation && data.marketLocation) {
      // Center between the two points
      center = [
        (data.customerLocation.lat + data.marketLocation.lat) / 2,
        (data.customerLocation.lng + data.marketLocation.lng) / 2,
      ];

      // Adjust zoom based on distance
      const dist = calculateDistance(
        data.customerLocation.lat,
        data.customerLocation.lng,
        data.marketLocation.lat,
        data.marketLocation.lng
      );

      if (dist > 50) zoom = 8;
      else if (dist > 20) zoom = 9;
      else if (dist > 10) zoom = 10;
      else if (dist > 5) zoom = 11;
      else zoom = 12;
    } else if (data.customerLocation) {
      center = [data.customerLocation.lat, data.customerLocation.lng];
      zoom = 13;
    } else if (data.marketLocation) {
      center = [data.marketLocation.lat, data.marketLocation.lng];
      zoom = 13;
    }

    return {
      ...data,
      center,
      zoom,
    };
  }, [customer, customerCoords, marketCoords, marketDetails]);

  // Helper function to get country code
  const getCountryCode = () => {
    // Try to get country from customer address
    const addressCountry = customer?.address?.address?.country;
    if (addressCountry) {
      // Handle subregions like GB-ENG, GB-WLS, etc.
      if (addressCountry.startsWith("GB-")) {
        return "GB";
      }
      return addressCountry.substring(0, 2);
    }

    // Fall back to market's country code
    if (market?.countryCode) {
      // Handle subregions like GB-ENG, GB-WLS, etc.
      if (market.countryCode.startsWith("GB-")) {
        return "GB";
      }
      // For IE and other country codes
      if (market.countryCode === "IE") {
        return "IE";
      }
      return market.countryCode.substring(0, 2);
    }

    // Default to GB if nothing else available
    return "GB";
  };

  // Format phone number for display
  const formatPhoneForDisplay = (phoneNumber: string | undefined): string => {
    if (!phoneNumber) return "—";

    try {
      // Parse the phone number using the util
      const countryCode = getCountryCode();
      const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);

      // Check if the number is valid
      if (phoneUtil.isValidNumber(number)) {
        return phoneUtil.format(number, PNF.INTERNATIONAL);
      }

      // If parsing fails, return the original
      return phoneNumber;
    } catch (error) {
      console.warn("Phone number format error:", error);
      return phoneNumber;
    }
  };

  // Format phone as user types - basic implementation
  const formatPhoneAsTyped = (input: string): string => {
    return input;
  };

  // Validate phone number
  const isValidPhone = (phoneNumber: string): boolean => {
    if (!phoneNumber.trim()) return false;

    try {
      const countryCode = getCountryCode();
      const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
      return phoneUtil.isValidNumber(number);
    } catch (error) {
      console.warn("Phone validation error:", error);
      return false;
    }
  };

  // Handle phone input with formatting
  const handlePhoneInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneValue(formatPhoneAsTyped(e.target.value));
  };

  // Handle account number input - convert to uppercase and filter invalid chars
  const handleAccountInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // First convert to uppercase, then only allow letters and numbers
    const uppercaseValue = e.target.value.toUpperCase();
    const filteredValue = uppercaseValue.replace(/[^A-Z0-9]/g, "");
    setAccountValue(filteredValue);
  };

  // Cancel editing
  const cancelEditing = () => {
    setEditingField(null);
    setErrorMessage(null);

    // Reset values
    if (customer) {
      setNameValue(getDisplayName(customer) || "");
      setEmailValue(customer.email?.email || "");
      setPhoneValue(customer.phoneNumber?.phoneNumber || "");
      setVatValue(customer.vatNumber || "");
      setAccountValue(customer.accountNumber || "");
      // Initialize bank details
      setAccountNumberValue(customer.bankDetails?.accountNumber || "");
      setSortCodeValue(customer.bankDetails?.sortCode || "");
      setAccountNameValue(
        customer.bankDetails?.accountName || getDisplayName(customer) || ""
      );

      // Determine bank name and logo if sort code exists
      if (customer.bankDetails?.sortCode) {
        const formattedSortCode = formatSortCode(customer.bankDetails.sortCode);
        setSortCodeValue(formattedSortCode);
        detectBankFromSortCode(formattedSortCode);
      }
    }
  };

  // Add a new function to delete fields (before saveField function)
  const deleteFieldValue = async (field: string) => {
    if (!customerUid) return;

    setIsSaving(true);
    setErrorMessage(null);

    try {
      const customerRef = doc(
        firestore,
        `markets/${marketId}/customers/${customerUid}`
      );

      if (field === "name") {
        // Cannot delete the name, just clear it
        await updateDoc(customerRef, {
          displayName: "",
          updatedAt: new Date(),
        });
      } else if (field === "email") {
        await updateDoc(customerRef, {
          email: deleteField(),
          updatedAt: new Date(),
        });
      } else if (field === "phone") {
        await updateDoc(customerRef, {
          phoneNumber: deleteField(),
          updatedAt: new Date(),
        });
      } else if (field === "vat") {
        await updateDoc(customerRef, {
          vatNumber: deleteField(),
          updatedAt: new Date(),
        });
      } else if (field === "account") {
        await updateDoc(customerRef, {
          accountNumber: deleteField(),
          updatedAt: new Date(),
        });
      } else if (field === "bankDetails") {
        await updateDoc(customerRef, {
          bankDetails: deleteField(),
          updatedAt: new Date(),
        });
      } else if (field.startsWith("farmAssurance")) {
        const species = field.replace("farmAssurance", "");
        const updateData: any = {};

        // Delete all farm assurance fields for the species
        updateData[`attributeDefaultsSeller.farmAssuranceNumber${species}`] =
          deleteField();
        updateData[`attributeDefaultsSeller.farmAssuranceScheme${species}`] =
          deleteField();
        updateData[`attributeDefaultsSeller.farmAssuranceExpiry${species}`] =
          deleteField();

        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      } else if (field === "herdNumber") {
        const updateData: any = {};

        // Delete herd number fields
        updateData["attributeDefaultsSeller.herdNumberOrigin"] = deleteField();
        updateData["attributeDefaultsBuyer.herdNumberDestination"] =
          deleteField();

        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      } else if (field === "flockNumber") {
        const updateData: any = {};

        // Delete flock number fields
        updateData["attributeDefaultsSeller.flockNumberOrigin"] = deleteField();
        updateData["attributeDefaultsBuyer.flockNumberDestination"] =
          deleteField();

        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      } else if (field === "cphNumber") {
        const updateData: any = {};

        // Delete CPH number fields
        updateData["attributeDefaultsSeller.cphNumberOrigin"] = deleteField();
        updateData["attributeDefaultsBuyer.cphNumberDestination"] =
          deleteField();

        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      } else if (field === "vetAttestation") {
        const updateData: any = {};

        // Delete VAN number and expiry
        updateData["attributeDefaultsSeller.vanNumber"] = deleteField();
        updateData["attributeDefaultsSeller.vanNumberExpiry"] = deleteField();

        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      } else if (field === "notes") {
        // Simply update the notes field
        await updateDoc(customerRef, {
          notes: notesValue.trim(),
          updatedAt: new Date(),
        });
      } else if (field === "slapMark") {
        const updateData: any = {};

        // Delete slap mark fields
        updateData["attributeDefaultsSeller.slapMarkOrigin"] = deleteField();
        updateData["attributeDefaultsBuyer.slapMarkDestination"] =
          deleteField();

        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      } else if (field === "movementReporting") {
        const updateData: any = {};

        // Delete movement reporting fields
        updateData["attributeDefaultsSeller.shouldReportMovementsFromOrigin"] =
          deleteField();
        updateData[
          "attributeDefaultsBuyer.shouldReportMovementsToDestination"
        ] = deleteField();

        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      }

      // Reset field values
      if (field === "name") {
        setNameValue("");
      } else if (field === "email") {
        setEmailValue("");
      } else if (field === "phone") {
        setPhoneValue("");
      } else if (field === "vat") {
        setVatValue("");
      } else if (field === "account") {
        setAccountValue("");
      } else if (field === "bankDetails") {
        setAccountNumberValue("");
        setSortCodeValue("");
        setAccountNameValue("");
        setBankName("");
        setBankLogo("");
      }

      // Clear editing state
      setEditingField(null);
    } catch (err) {
      console.error("Error deleting field:", err);
      setErrorMessage("Failed to delete. Please try again.");
    }

    setIsSaving(false);
  };

  // Add a function to handle keyboard events for input fields
  const handleInputKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveField(field);
    } else if (e.key === "Escape") {
      e.preventDefault();
      cancelEditing();
    }
  };

  // Validate account number - UK account numbers are typically 8 digits
  const isValidAccountNumber = (accountNumber: string): boolean => {
    const digits = accountNumber.replace(/\D/g, "");
    return digits.length === 8 && /^\d+$/.test(digits);
  };

  // Validate sort code - UK sort codes are 6 digits, often shown as XX-XX-XX
  const isValidSortCode = (sortCode: string): boolean => {
    const digits = sortCode.replace(/\D/g, "");
    return digits.length === 6 && /^\d+$/.test(digits);
  };

  // Handle account number input - only allow digits
  const handleAccountNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    // Allow only digits
    const onlyDigits = input.replace(/\D/g, "");
    // Limit to 8 digits
    const trimmed = onlyDigits.substring(0, 8);
    setAccountNumberValue(trimmed);
  };

  // Modified saveField function to handle bank details
  const saveField = async (field: string) => {
    if (!customerUid || !customer) return;

    setIsSaving(true);
    setErrorMessage(null);

    try {
      const customerRef = doc(
        firestore,
        `markets/${marketId}/customers/${customerUid}`
      );

      // Process different fields
      if (field === "name") {
        // Check if name is empty for deletion
        if (!nameValue.trim()) {
          // Display name is required, so we can't delete it, just clear it
          await updateDoc(customerRef, {
            displayName: "",
            updatedAt: new Date(),
          });
        } else {
          // Regular name update
          await updateDoc(customerRef, {
            displayName: nameValue.trim(),
            updatedAt: new Date(),
          });
        }
      } else if (field === "email") {
        // Check if email is empty for deletion
        if (!emailValue.trim()) {
          await updateDoc(customerRef, {
            email: deleteField(),
            updatedAt: new Date(),
          });
        } else {
          // Validate email
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(emailValue.trim())) {
            setErrorMessage("Please enter a valid email address");
            setIsSaving(false);
            return;
          }

          // Update email with wrapper structure
          await updateDoc(customerRef, {
            email: {
              email: emailValue.trim(),
              isVerified: customer.email?.isVerified || false,
              createdAt: customer.email?.createdAt || new Date(),
            },
            updatedAt: new Date(),
          });
        }
      } else if (field === "phone") {
        // Check if phone is empty for deletion
        if (!phoneValue.trim()) {
          await updateDoc(customerRef, {
            phoneNumber: deleteField(),
            updatedAt: new Date(),
          });
        } else {
          // Validate phone with libphonenumber
          if (!isValidPhone(phoneValue)) {
            setErrorMessage("Please enter a valid phone number");
            setIsSaving(false);
            return;
          }

          // Format for storage in E.164 format
          let formattedPhone = phoneValue;
          try {
            const countryCode = getCountryCode();
            const number = phoneUtil.parseAndKeepRawInput(
              phoneValue,
              countryCode
            );

            if (phoneUtil.isValidNumber(number)) {
              formattedPhone = phoneUtil.format(number, PNF.E164);
            }
          } catch (error) {
            console.warn("Phone formatting error:", error);
            // Use the input value if formatting fails
          }

          // Update phone with wrapper structure
          await updateDoc(customerRef, {
            phoneNumber: {
              phoneNumber: formattedPhone,
              isVerified: customer.phoneNumber?.isVerified || false,
              createdAt: customer.phoneNumber?.createdAt || new Date(),
            },
            updatedAt: new Date(),
          });
        }
      } else if (field === "vat") {
        // Check if VAT is empty for deletion
        if (!vatValue.trim()) {
          await updateDoc(customerRef, {
            vatNumber: deleteField(),
            updatedAt: new Date(),
          });
        } else {
          // Simple VAT number validation - could be enhanced with proper VAT validation
          if (vatValue.trim().length < 3) {
            setErrorMessage("Please enter a valid VAT number");
            setIsSaving(false);
            return;
          }

          // Update VAT number
          await updateDoc(customerRef, {
            vatNumber: vatValue.trim(),
            updatedAt: new Date(),
          });
        }
      } else if (field === "account") {
        // Check if account number is empty for deletion

        // If it's empty, we can't delete it
        if (!accountValue.trim()) {
          setErrorMessage("Account number is required");
          setIsSaving(false);
          return;
        }

        // Ensure account number only contains uppercase letters and numbers
        const trimmedAccountNumber = accountValue.trim();
        const validFormat = /^[A-Z0-9]+$/.test(trimmedAccountNumber);

        if (!validFormat) {
          setErrorMessage(
            "Account number can only contain uppercase letters and numbers"
          );
          setIsSaving(false);
          return;
        }

        // Check for duplicate account numbers
        if (trimmedAccountNumber !== customer.accountNumber) {
          const customersRef = collection(
            firestore,
            `markets/${marketId}/customers`
          );
          const q = query(
            customersRef,
            where("accountNumber", "==", trimmedAccountNumber),
            limit(1)
          );

          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            setErrorMessage(
              "This account number is already in use. Please choose a different one."
            );
            setIsSaving(false);
            return;
          }
        }

        // Update account number
        await updateDoc(customerRef, {
          accountNumber: trimmedAccountNumber,
          updatedAt: new Date(),
        });
      } else if (field === "bankDetails") {
        // If both account number and sort code are empty, delete bank details
        if (!accountNumberValue.trim() && !sortCodeValue.trim()) {
          await updateDoc(customerRef, {
            bankDetails: deleteField(),
            updatedAt: new Date(),
          });
        } else {
          // Validate account number and sort code
          const cleanAccountNumber = accountNumberValue.replace(/\D/g, "");
          const cleanSortCode = sortCodeValue.replace(/\D/g, "");

          if (cleanAccountNumber && !isValidAccountNumber(cleanAccountNumber)) {
            setErrorMessage("Account number must be 8 digits");
            setIsSaving(false);
            return;
          }

          if (cleanSortCode && !isValidSortCode(cleanSortCode)) {
            setErrorMessage("Sort code must be 6 digits");
            setIsSaving(false);
            return;
          }

          // Both are required
          if (!cleanAccountNumber || !cleanSortCode) {
            setErrorMessage("Both account number and sort code are required");
            setIsSaving(false);
            return;
          }

          // Update bank details
          const bankDetails: BankDetails = {
            accountNumber: cleanAccountNumber,
            sortCode: cleanSortCode,
          };

          // Add accountName if provided
          if (accountNameValue.trim()) {
            bankDetails.accountName = accountNameValue.trim();
          }

          await updateDoc(customerRef, {
            bankDetails,
            updatedAt: new Date(),
          });
        }
      } else if (field.startsWith("farmAssurance")) {
        const species = field.replace("farmAssurance", "");

        // Get the current state values from the component state
        // This would be from component state variables, for example:
        const faNumber = document.getElementById(
          `faNumber${species}`
        ) as HTMLInputElement;
        const faScheme = document.getElementById(
          `faScheme${species}`
        ) as HTMLSelectElement;
        const faExpiry = document.getElementById(
          `faExpiry${species}`
        ) as HTMLInputElement;

        if (!faNumber || !faNumber.value.trim()) {
          setErrorMessage("Membership number is required");
          setIsSaving(false);
          return;
        }

        const updateData: any = {};

        // Update farm assurance number (required)
        updateData[`attributeDefaultsSeller.farmAssuranceNumber${species}`] = [
          faNumber.value.trim(),
        ];

        // Update scheme if provided
        if (faScheme && faScheme.value) {
          updateData[`attributeDefaultsSeller.farmAssuranceScheme${species}`] =
            [faScheme.value];
        } else {
          // Remove if empty
          updateData[`attributeDefaultsSeller.farmAssuranceScheme${species}`] =
            deleteField();
        }

        // Update expiry if provided
        if (faExpiry && faExpiry.value) {
          const expiryDate = new Date(faExpiry.value);
          updateData[`attributeDefaultsSeller.farmAssuranceExpiry${species}`] =
            [Timestamp.fromDate(expiryDate)];
        } else {
          // Remove if empty
          updateData[`attributeDefaultsSeller.farmAssuranceExpiry${species}`] =
            deleteField();
        }

        // Update customer record
        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      } else if (field === "herdNumber") {
        // Get the herd number value from the form
        const herdNum =
          (document.getElementById("herdNumber") as HTMLInputElement)?.value ||
          "";

        const updateData: any = {};

        // If herd number provided, update both origin and destination
        if (herdNum.trim()) {
          updateData["attributeDefaultsSeller.herdNumberOrigin"] = [
            herdNum.trim(),
          ];
          updateData["attributeDefaultsBuyer.herdNumberDestination"] = [
            herdNum.trim(),
          ];
        } else {
          // Clear the fields if empty
          updateData["attributeDefaultsSeller.herdNumberOrigin"] =
            deleteField();
          updateData["attributeDefaultsBuyer.herdNumberDestination"] =
            deleteField();
        }

        // Update customer record
        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      } else if (field === "flockNumber") {
        // Get the flock number value from the form
        const flockNum =
          (document.getElementById("flockNumber") as HTMLInputElement)?.value ||
          "";

        const updateData: any = {};

        // If flock number provided, update both origin and destination
        if (flockNum.trim()) {
          updateData["attributeDefaultsSeller.flockNumberOrigin"] = [
            flockNum.trim(),
          ];
          updateData["attributeDefaultsBuyer.flockNumberDestination"] = [
            flockNum.trim(),
          ];
        } else {
          // Clear the fields if empty
          updateData["attributeDefaultsSeller.flockNumberOrigin"] =
            deleteField();
          updateData["attributeDefaultsBuyer.flockNumberDestination"] =
            deleteField();
        }

        // Update customer record
        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      } else if (field === "cphNumber") {
        // Get the CPH number value from the form
        const cphNum =
          (document.getElementById("cphNumber") as HTMLInputElement)?.value ||
          "";

        const updateData: any = {};

        // If CPH number provided, validate and update both origin and destination
        if (cphNum.trim()) {
          // Clean and validate the CPH number
          const cleanCphNum = cphNum.replace(/\D/g, "");

          // Ensure we have exactly 9 digits
          if (cleanCphNum.length !== 9) {
            setErrorMessage("CPH number must contain exactly 9 digits");
            setIsSaving(false);
            return;
          }

          updateData["attributeDefaultsSeller.cphNumberOrigin"] = [
            cleanCphNum.trim(),
          ];
          updateData["attributeDefaultsBuyer.cphNumberDestination"] = [
            cleanCphNum.trim(),
          ];
        } else {
          // Clear the fields if empty
          updateData["attributeDefaultsSeller.cphNumberOrigin"] = deleteField();
          updateData["attributeDefaultsBuyer.cphNumberDestination"] =
            deleteField();
        }

        // Update customer record
        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      } else if (field === "vetAttestation") {
        // Get the Vet Attestation values from the form
        const vanNum =
          (document.getElementById("vanNumber") as HTMLInputElement)?.value ||
          "";
        const vanExp =
          (document.getElementById("vanExpiry") as HTMLInputElement)?.value ||
          "";

        const updateData: any = {};

        // VAN number is required
        if (!vanNum.trim()) {
          setErrorMessage("VAN number is required");
          setIsSaving(false);
          return;
        }

        // Update vanNumber
        updateData["attributeDefaultsSeller.vanNumber"] = [vanNum.trim()];

        // Update expiry if provided
        if (vanExp) {
          const expiryDate = new Date(vanExp);
          updateData["attributeDefaultsSeller.vanNumberExpiry"] = [
            Timestamp.fromDate(expiryDate),
          ];
        } else {
          // Clear the expiry if empty
          updateData["attributeDefaultsSeller.vanNumberExpiry"] = deleteField();
        }

        // Update customer record
        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      } else if (field === "notes") {
        // Simply update the notes field
        await updateDoc(customerRef, {
          notes: notesValue.trim(),
          updatedAt: new Date(),
        });
      } else if (field === "slapMark") {
        // Get the Slap Mark value from the form
        const markValue =
          (document.getElementById("slapMark") as HTMLInputElement)?.value ||
          "";

        const updateData: any = {};

        // If a slap mark is provided, update both origin and destination
        if (markValue.trim()) {
          updateData["attributeDefaultsSeller.slapMarkOrigin"] = [
            markValue.trim(),
          ];
          updateData["attributeDefaultsBuyer.slapMarkDestination"] = [
            markValue.trim(),
          ];
        } else {
          // Clear the fields if empty
          updateData["attributeDefaultsSeller.slapMarkOrigin"] = deleteField();
          updateData["attributeDefaultsBuyer.slapMarkDestination"] =
            deleteField();
        }

        // Update customer record
        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      } else if (field === "movementReporting") {
        // Get the checkbox values
        const offFarm =
          (document.getElementById("reportOffFarm") as HTMLInputElement)
            ?.checked || false;
        const onFarm =
          (document.getElementById("reportOnFarm") as HTMLInputElement)
            ?.checked || false;

        const updateData: any = {};

        // Update movement reporting preferences
        updateData["attributeDefaultsSeller.shouldReportMovementsFromOrigin"] =
          [offFarm];
        updateData[
          "attributeDefaultsBuyer.shouldReportMovementsToDestination"
        ] = [onFarm];

        // Update customer record
        await updateDoc(customerRef, {
          ...updateData,
          updatedAt: new Date(),
        });
      }

      // Success - turn off editing mode
      setEditingField(null);
      setIsSaving(false);
    } catch (error) {
      console.error("Error saving field:", error);
      setErrorMessage("Failed to save changes");
      setIsSaving(false);
    }
  };

  // Create timeline items for the activity timeline
  const buildTimelineItems = (): TimelineItem[] => {
    const items: TimelineItem[] = [];

    // Account created
    if (customer?.createdAt) {
      items.push({
        id: "account-created",
        type: "account_created",
        date: customer.createdAt.toDate(),
        title: "Account Created",
      });
    }

    // Email updated
    if (
      customer?.email?.createdAt &&
      customer?.createdAt &&
      customer.email.createdAt.toDate().getTime() !==
        customer.createdAt.toDate().getTime()
    ) {
      items.push({
        id: "email-updated",
        type: "email_updated",
        date: customer.email.createdAt.toDate(),
        title: "Email Updated",
        newValue: customer.email.email,
        oldValue: "previous@example.com", // In a real app, you'd store history
        updatedBy: "Mark Smith",
      });
    }

    // Phone updated
    if (
      customer?.phoneNumber?.createdAt &&
      customer?.createdAt &&
      customer.phoneNumber.createdAt.toDate().getTime() !==
        customer.createdAt.toDate().getTime()
    ) {
      items.push({
        id: "phone-updated",
        type: "phone_updated",
        date: customer.phoneNumber.createdAt.toDate(),
        title: "Phone Number Updated",
        newValue: formatPhoneForDisplay(customer.phoneNumber.phoneNumber),
        oldValue: "+44 7700 900123", // In a real app, you'd store history
        updatedBy: "Jenny Wilson",
      });
    }

    // Process lots sold with grouping by date
    processSoldLots();

    // Process lots bought with grouping by date
    processBoughtLots();

    // Add example timeline items when we don't have enough real data
    if (items.length < 15) {
      // Payment received
      items.push({
        id: "payment-received-1",
        type: "payment_received",
        date: new Date(Date.now() - 10 * 24 * 60 * 60 * 1000),
        title: "Payment Received",
        subtitle: "Invoice #INV-2023-001",
        amount: 1250,
      });

      // Payout sent
      items.push({
        id: "payout-sent-1",
        type: "payout_sent",
        date: new Date(Date.now() - 15 * 24 * 60 * 60 * 1000),
        title: "Payout Sent",
        subtitle: "Sale earnings via bank transfer",
        amount: 3450,
      });

      // Invoice voided
      items.push({
        id: "invoice-voided-1",
        type: "invoice_voided",
        date: new Date(Date.now() - 45 * 24 * 60 * 60 * 1000),
        title: "Invoice Voided",
        subtitle: "Invoice #INV-2023-002",
        amount: 750,
      });

      // Credit note
      items.push({
        id: "credit-note-1",
        type: "credit_note",
        date: new Date(Date.now() - 60 * 24 * 60 * 60 * 1000),
        title: "Credit Note Raised",
        subtitle: "Credit Note #CN-2023-001",
        amount: 320,
      });

      // Additional 10 timeline entries for testing a longer timeline

      // 1. Another lot sold
      items.push({
        id: "lot-sold-extra-1",
        type: "lot_sold",
        date: new Date(Date.now() - 25 * 24 * 60 * 60 * 1000),
        title: "Lot Sold",
        subtitle: "10x Hereford Heifers",
        amount: 8500,
      });

      // 2. Another lot purchased
      items.push({
        id: "lot-bought-extra-1",
        type: "lot_purchased",
        date: new Date(Date.now() - 32 * 24 * 60 * 60 * 1000),
        title: "Lot Purchased",
        subtitle: "Farming Equipment - John Deere Parts",
        amount: 1800,
      });

      // 3. Another payment received
      items.push({
        id: "payment-received-2",
        type: "payment_received",
        date: new Date(Date.now() - 50 * 24 * 60 * 60 * 1000),
        title: "Payment Received",
        subtitle: "Invoice #INV-2023-005",
        amount: 2750,
      });

      // 4. Email updated again
      items.push({
        id: "email-updated-extra",
        type: "email_updated",
        date: new Date(Date.now() - 75 * 24 * 60 * 60 * 1000),
        title: "Email Updated",
        newValue: "newbusiness@example.com",
        oldValue: "oldbusiness@example.com",
        updatedBy: "Thomas Wilson",
      });

      // 5. Another credit note
      items.push({
        id: "credit-note-2",
        type: "credit_note",
        date: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
        title: "Credit Note Raised",
        subtitle: "Credit Note #CN-2023-002",
        amount: 175,
      });

      // 6. Phone number updated again
      items.push({
        id: "phone-updated-extra",
        type: "phone_updated",
        date: new Date(Date.now() - 110 * 24 * 60 * 60 * 1000),
        title: "Phone Number Updated",
        newValue: "+44 7700 900456",
        oldValue: "+44 7700 900789",
        updatedBy: "Susan Miller",
      });

      // 7. Another invoice voided
      items.push({
        id: "invoice-voided-2",
        type: "invoice_voided",
        date: new Date(Date.now() - 125 * 24 * 60 * 60 * 1000),
        title: "Invoice Voided",
        subtitle: "Invoice #INV-2023-008",
        amount: 450,
      });

      // 8. Another lot sold
      items.push({
        id: "lot-sold-extra-2",
        type: "lot_sold",
        date: new Date(Date.now() - 140 * 24 * 60 * 60 * 1000),
        title: "Lot Sold",
        subtitle: "15x Suffolk Lambs",
        amount: 3600,
      });

      // 9. Another payout sent
      items.push({
        id: "payout-sent-2",
        type: "payout_sent",
        date: new Date(Date.now() - 160 * 24 * 60 * 60 * 1000),
        title: "Payout Sent",
        subtitle: "Sale earnings via check",
        amount: 2850,
      });

      // 10. Another lot purchased from a long time ago
      items.push({
        id: "lot-bought-extra-2",
        type: "lot_purchased",
        date: new Date(Date.now() - 200 * 24 * 60 * 60 * 1000),
        title: "Lot Purchased",
        subtitle: "5x Aberdeen Angus Bulls",
        amount: 12500,
      });
    }

    return items;

    // Helper function to process sold lots with grouping
    function processSoldLots() {
      // Group sold lots by date (YYYY-MM-DD format)
      const lotsByDate: Record<string, LotData[]> = {};

      soldLots.forEach((lot) => {
        if (lot.createdAt) {
          const date = lot.createdAt.toDate();
          const dateKey = format(date, "yyyy-MM-dd");

          if (!lotsByDate[dateKey]) {
            lotsByDate[dateKey] = [];
          }

          lotsByDate[dateKey].push(lot);
        }
      });

      // Create timeline items from grouped lots
      Object.entries(lotsByDate).forEach(([dateKey, lots]) => {
        const date = new Date(dateKey);
        const totalAmount = lots.reduce(
          (sum, lot) =>
            sum + (lot.hammerPriceInCents ? lot.hammerPriceInCents / 100 : 0),
          0
        );

        if (lots.length === 1) {
          // Just one lot on this day, add as a regular item
          const lot = lots[0];
          if (lot.createdAt) {
            items.push({
              id: `lot-sold-${lot.id}`,
              type: "lot_sold",
              date: lot.createdAt.toDate(),
              title: "Lot Sold",
              subtitle: lot.name || "Item sold",
              amount: lot.hammerPriceInCents
                ? lot.hammerPriceInCents / 100
                : undefined,
            });
          }
        } else {
          // Multiple lots on this day, group them
          items.push({
            id: `lots-sold-group-${dateKey}`,
            type: "lots_sold_group",
            date: date,
            title: "Multiple Lots Sold",
            amount: totalAmount,
            count: lots.length,
            items: lots.map((lot) => ({
              name: lot.name || "Unnamed lot",
              category: lot.name?.includes("Sheep")
                ? "Sheep"
                : lot.name?.includes("Cattle")
                ? "Cattle"
                : lot.name?.includes("Bull")
                ? "Bulls"
                : lot.name?.includes("Equipment")
                ? "Equipment"
                : "Other",
              amount: lot.hammerPriceInCents
                ? lot.hammerPriceInCents / 100
                : undefined,
            })),
          });
        }
      });
    }

    // Helper function to process bought lots with grouping
    function processBoughtLots() {
      // Group bought lots by date (YYYY-MM-DD format)
      const lotsByDate: Record<string, LotData[]> = {};

      boughtLots.forEach((lot) => {
        if (lot.createdAt) {
          const date = lot.createdAt.toDate();
          const dateKey = format(date, "yyyy-MM-dd");

          if (!lotsByDate[dateKey]) {
            lotsByDate[dateKey] = [];
          }

          lotsByDate[dateKey].push(lot);
        }
      });

      // Create timeline items from grouped lots
      Object.entries(lotsByDate).forEach(([dateKey, lots]) => {
        const date = new Date(dateKey);
        const totalAmount = lots.reduce(
          (sum, lot) =>
            sum + (lot.hammerPriceInCents ? lot.hammerPriceInCents / 100 : 0),
          0
        );

        if (lots.length === 1) {
          // Just one lot on this day, add as a regular item
          const lot = lots[0];
          if (lot.createdAt) {
            items.push({
              id: `lot-bought-${lot.id}`,
              type: "lot_purchased",
              date: lot.createdAt.toDate(),
              title: "Lot Purchased",
              subtitle: lot.name || "Item purchased",
              amount: lot.hammerPriceInCents
                ? lot.hammerPriceInCents / 100
                : undefined,
            });
          }
        } else {
          // Multiple lots on this day, group them
          items.push({
            id: `lots-bought-group-${dateKey}`,
            type: "lots_purchased_group",
            date: date,
            title: "Multiple Lots Purchased",
            amount: totalAmount,
            count: lots.length,
            items: lots.map((lot) => ({
              name: lot.name || "Unnamed lot",
              category: lot.name?.includes("Sheep")
                ? "Sheep"
                : lot.name?.includes("Cattle")
                ? "Cattle"
                : lot.name?.includes("Bull")
                ? "Bulls"
                : lot.name?.includes("Equipment")
                ? "Equipment"
                : "Other",
              amount: lot.hammerPriceInCents
                ? lot.hammerPriceInCents / 100
                : undefined,
            })),
          });
        }
      });
    }
  };

  const timelineItems = buildTimelineItems();

  // Create ref to access timeline component methods
  const timelineRef = useRef<TimelineRef>(null);

  // Function to maximize the timeline
  const handleMaximizeTimeline = () => {
    if (timelineRef.current) {
      timelineRef.current.maximize();
    }
  };

  // Add a function to open the address modal
  const openAddressModal = () => {
    setIsAddressModalOpen(true);
  };

  // Function to check if a field should be shown
  const shouldShowKeeperDetail = (type: string): boolean => {
    if (!customer) return false;

    // Always show the field if it's currently being edited
    if (editingField === type) return true;

    switch (type) {
      case "herdNumber":
        return !!getAttribute(
          "herdNumberDestination",
          "herdNumberOrigin",
          customer
        );
      case "flockNumber":
        return !!getAttribute(
          "flockNumberDestination",
          "flockNumberOrigin",
          customer
        );
      case "cphNumber":
        return !!getAttribute(
          "cphNumberDestination",
          "cphNumberOrigin",
          customer
        );
      case "movementReporting":
        // Only show movement reporting if CPH exists
        return !!getAttribute(
          "cphNumberDestination",
          "cphNumberOrigin",
          customer
        );
      case "slapMark":
        return !!getAttribute(
          "slapMarkDestination",
          "slapMarkOrigin",
          customer
        );
      case "vetAttestation":
        return !!getAttributeValue("vanNumber", customer);
      case "farmAssuranceSheep":
        return !!getAttributeValue("farmAssuranceNumberSheep", customer);
      case "farmAssuranceCattle":
        return !!getAttributeValue("farmAssuranceNumberCattle", customer);
      case "farmAssurancePigs":
        return !!getAttributeValue("farmAssuranceNumberPigs", customer);
      default:
        return false;
    }
  };

  // Helper function to get attribute values from customer
  const getAttribute = (
    buyerKey: string,
    sellerKey: string,
    customer: Customer | null
  ): string => {
    if (!customer) return "";

    // Check seller attributes first
    if (
      customer.attributeDefaultsSeller &&
      customer.attributeDefaultsSeller[sellerKey]?.length > 0
    ) {
      return (customer.attributeDefaultsSeller[sellerKey][0] as string) || "";
    }

    // Then check buyer attributes
    if (
      customer.attributeDefaultsBuyer &&
      customer.attributeDefaultsBuyer[buyerKey]?.length > 0
    ) {
      return (customer.attributeDefaultsBuyer[buyerKey][0] as string) || "";
    }

    return "";
  };

  // Helper function to get attribute from seller defaults
  const getAttributeValue = (key: string, customer: Customer | null): any => {
    if (!customer?.attributeDefaultsSeller) return null;
    const values = customer.attributeDefaultsSeller[key];
    return values && values.length > 0 ? values[0] : null;
  };

  // Function to handle selecting an option from the dropdown
  const handleAddKeeperDetail = (type: string) => {
    // Set the editing field to the selected type
    setEditingField(type);

    // Make sure any error messages are cleared
    setErrorMessage(null);
  };

  // Function to check if any keeper details should be shown
  const hasAnyKeeperDetails = (): boolean => {
    return (
      shouldShowKeeperDetail("herdNumber") ||
      shouldShowKeeperDetail("flockNumber") ||
      shouldShowKeeperDetail("cphNumber") ||
      shouldShowKeeperDetail("movementReporting") ||
      shouldShowKeeperDetail("slapMark") ||
      shouldShowKeeperDetail("vetAttestation") ||
      shouldShowKeeperDetail("farmAssuranceSheep") ||
      shouldShowKeeperDetail("farmAssuranceCattle") ||
      shouldShowKeeperDetail("farmAssurancePigs")
    );
  };

  // Add state for notes
  const [notesValue, setNotesValue] = useState<string>("");

  // Add to the useEffect for setting initial values
  useEffect(() => {
    if (customer) {
      setNameValue(getDisplayName(customer) || "");
      // ... existing code ...
      setNotesValue(customer.notes || "");
    }
  }, [customer]);

  // Add a function to handle notes input
  const handleNotesInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotesValue(e.target.value);
  };

  // Add a handler for Enter key in notes (Ctrl+Enter to save)
  const handleNotesKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Use Ctrl+Enter to save notes since Enter creates a new line
    if (e.key === "Enter" && e.ctrlKey) {
      e.preventDefault();
      saveField("notes");
    }
  };

  return (
    <div className="flex flex-col gap-6 p-4">
      {/* Main Customer Information */}
      <div className="rounded-lg bg-white p-4 shadow">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-lg font-medium text-martEye-700">
              Customer Information
            </h2>
            {customer?.createdAt && (
              <p className="text-xs text-gray-500">
                Created:{" "}
                <Tooltip text={format(customer.createdAt.toDate(), "PPP p")}>
                  <span className="cursor-help">
                    {formatRelativeDate(customer.createdAt.toDate())}
                  </span>
                </Tooltip>
              </p>
            )}
          </div>
          <div className="flex space-x-2">{/* Removed Edit All button */}</div>
        </div>

        <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-3">
          {/* Customer details - 2/3 width */}
          <div className="md:col-span-2 grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div className="rounded-md bg-gray-50 p-3 group relative">
              <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
                <span className="flex items-center">
                  <UserIcon className="mr-2 h-4 w-4 text-martEye-500" />
                  Name
                </span>
                <div className="flex space-x-1">
                  {editingField !== "name" && (
                    <>
                      <button
                        onClick={() =>
                          copyToClipboard(getDisplayName(customer), "name")
                        }
                        className="text-gray-400 hover:text-martEye-500 focus:outline-none"
                        title="Copy name"
                      >
                        {copiedField === "name" ? (
                          <ClipboardDocumentCheckIcon className="h-4 w-4 text-green-500" />
                        ) : (
                          <ClipboardDocumentIcon className="h-4 w-4" />
                        )}
                      </button>
                      <button
                        onClick={() => {
                          setEditingField("name");
                          setNameValue(getDisplayName(customer) || "");
                        }}
                        className="text-gray-400 hover:text-martEye-500 focus:outline-none ml-2"
                        title="Edit name"
                      >
                        <PencilIcon className="h-4 w-4" />
                      </button>
                    </>
                  )}
                </div>
              </h3>

              {editingField === "name" ? (
                <div className="mt-1">
                  <input
                    type="text"
                    value={nameValue}
                    onChange={(e) => setNameValue(e.target.value)}
                    onKeyDown={(e) => handleInputKeyDown(e, "name")}
                    className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
                    placeholder="Enter name"
                    autoFocus
                  />
                  {errorMessage && (
                    <p className="text-xs text-red-500 mt-1">{errorMessage}</p>
                  )}
                  <div className="flex justify-end mt-2 space-x-2">
                    <div className="flex-grow text-left">
                      <button
                        onClick={() => deleteFieldValue("name")}
                        className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                        disabled={isSaving}
                        title="Clear name"
                      >
                        <TrashIcon className="h-3 w-3" />
                      </button>
                    </div>
                    <button
                      onClick={cancelEditing}
                      className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
                      disabled={isSaving}
                    >
                      <XMarkIcon className="h-3 w-3 mr-1" />
                      Cancel
                    </button>
                    <button
                      onClick={() => saveField("name")}
                      className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
                      disabled={isSaving}
                    >
                      {isSaving ? (
                        <span>Saving...</span>
                      ) : (
                        <>
                          <CheckIcon className="h-3 w-3 mr-1" />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <p className="mt-1 text-base">
                  {getDisplayName(customer) || "—"}
                </p>
              )}
            </div>

            <div className="rounded-md bg-gray-50 p-3 group relative">
              <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
                <span className="flex items-center">
                  <IdentificationIcon className="mr-2 h-4 w-4 text-martEye-500" />
                  Account Number
                </span>
                <div className="flex space-x-1">
                  {editingField !== "account" && (
                    <>
                      {customer?.accountNumber && (
                        <button
                          onClick={() =>
                            copyToClipboard(
                              customer!.accountNumber,
                              "accountNumber"
                            )
                          }
                          className="text-gray-400 hover:text-martEye-500 focus:outline-none"
                          title="Copy account number"
                        >
                          {copiedField === "accountNumber" ? (
                            <ClipboardDocumentCheckIcon className="h-4 w-4 text-green-500" />
                          ) : (
                            <ClipboardDocumentIcon className="h-4 w-4" />
                          )}
                        </button>
                      )}
                      <button
                        onClick={() => {
                          setEditingField("account");
                          setAccountValue(customer?.accountNumber || "");
                        }}
                        className="text-gray-400 hover:text-martEye-500 focus:outline-none"
                        title="Edit account number"
                      >
                        <PencilIcon className="h-4 w-4" />
                      </button>
                    </>
                  )}
                </div>
              </h3>

              {editingField === "account" ? (
                <div className="mt-1">
                  <input
                    type="text"
                    value={accountValue}
                    onChange={handleAccountInput}
                    onKeyDown={(e) => handleInputKeyDown(e, "account")}
                    className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
                    placeholder="Enter account number"
                    autoFocus
                  />
                  {errorMessage && (
                    <p className="text-xs text-red-500 mt-1">{errorMessage}</p>
                  )}
                  <div className="flex justify-end mt-2 space-x-2">
                    <div className="flex-grow text-left">
                      <button
                        onClick={() => deleteFieldValue("account")}
                        className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                        disabled={isSaving}
                        title="Clear account number"
                      >
                        <TrashIcon className="h-3 w-3" />
                      </button>
                    </div>
                    <button
                      onClick={cancelEditing}
                      className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
                      disabled={isSaving}
                    >
                      <XMarkIcon className="h-3 w-3 mr-1" />
                      Cancel
                    </button>
                    <button
                      onClick={() => saveField("account")}
                      className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
                      disabled={isSaving}
                    >
                      {isSaving ? (
                        <span>Saving...</span>
                      ) : (
                        <>
                          <CheckIcon className="h-3 w-3 mr-1" />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <p className="mt-1 text-base">
                  {customer?.accountNumber || "—"}
                </p>
              )}
            </div>
            <div className="rounded-md bg-gray-50 p-3 group relative">
              <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
                <span className="flex items-center">
                  <EnvelopeIcon className="mr-2 h-4 w-4 text-martEye-500" />
                  Email
                </span>
                <div className="flex space-x-1">
                  {editingField !== "email" && (
                    <>
                      <button
                        onClick={() =>
                          copyToClipboard(customer?.email?.email, "email")
                        }
                        className="text-gray-400 hover:text-martEye-500 focus:outline-none"
                        title="Copy email"
                      >
                        {copiedField === "email" ? (
                          <ClipboardDocumentCheckIcon className="h-4 w-4 text-green-500" />
                        ) : (
                          <ClipboardDocumentIcon className="h-4 w-4" />
                        )}
                      </button>
                      <button
                        onClick={() => {
                          setEditingField("email");
                          setEmailValue(customer?.email?.email || "");
                        }}
                        className="text-gray-400 hover:text-martEye-500 focus:outline-none ml-2"
                        title="Edit email"
                      >
                        <PencilIcon className="h-4 w-4" />
                      </button>
                    </>
                  )}
                </div>
              </h3>

              {editingField === "email" ? (
                <div className="mt-1">
                  <input
                    type="email"
                    value={emailValue}
                    onChange={(e) => setEmailValue(e.target.value)}
                    onKeyDown={(e) => handleInputKeyDown(e, "email")}
                    className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
                    placeholder="Enter email address"
                    autoFocus
                  />
                  {errorMessage && (
                    <p className="text-xs text-red-500 mt-1">{errorMessage}</p>
                  )}
                  <div className="flex justify-end mt-2 space-x-2">
                    <div className="flex-grow text-left">
                      <button
                        onClick={() => deleteFieldValue("email")}
                        className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                        disabled={isSaving}
                        title="Remove email"
                      >
                        <TrashIcon className="h-3 w-3" />
                      </button>
                    </div>
                    <button
                      onClick={cancelEditing}
                      className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
                      disabled={isSaving}
                    >
                      <XMarkIcon className="h-3 w-3 mr-1" />
                      Cancel
                    </button>
                    <button
                      onClick={() => saveField("email")}
                      className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
                      disabled={isSaving}
                    >
                      {isSaving ? (
                        <span>Saving...</span>
                      ) : (
                        <>
                          <CheckIcon className="h-3 w-3 mr-1" />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <p className="mt-1 text-base overflow-hidden text-ellipsis">
                  {customer?.email ? customer.email.email : "—"}
                </p>
              )}
            </div>
            <div className="rounded-md bg-gray-50 p-3 group relative">
              <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
                <span className="flex items-center">
                  <PhoneIcon className="mr-2 h-4 w-4 text-martEye-500" />
                  Phone Number
                </span>
                <div className="flex space-x-1">
                  {editingField !== "phone" && (
                    <>
                      <button
                        onClick={() =>
                          customer?.phoneNumber?.phoneNumber
                            ? copyToClipboard(
                                customer.phoneNumber.phoneNumber,
                                "phone"
                              )
                            : null
                        }
                        className={`${
                          customer?.phoneNumber?.phoneNumber
                            ? "text-gray-400 hover:text-martEye-500"
                            : "text-gray-200"
                        } focus:outline-none`}
                        title={
                          customer?.phoneNumber?.phoneNumber
                            ? "Copy phone number"
                            : "No phone number available"
                        }
                        disabled={!customer?.phoneNumber?.phoneNumber}
                      >
                        {copiedField === "phone" ? (
                          <ClipboardDocumentCheckIcon className="h-4 w-4 text-green-500" />
                        ) : (
                          <ClipboardDocumentIcon className="h-4 w-4" />
                        )}
                      </button>
                      <button
                        onClick={() => {
                          setEditingField("phone");
                          setPhoneValue(
                            customer?.phoneNumber?.phoneNumber || ""
                          );
                        }}
                        className="text-gray-400 hover:text-martEye-500 focus:outline-none ml-2"
                        title="Edit phone number"
                      >
                        <PencilIcon className="h-4 w-4" />
                      </button>
                    </>
                  )}
                </div>
              </h3>

              {editingField === "phone" ? (
                <div className="mt-1">
                  <input
                    type="tel"
                    value={phoneValue}
                    onChange={handlePhoneInput}
                    onKeyDown={(e) => handleInputKeyDown(e, "phone")}
                    className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
                    placeholder={`Enter phone number`}
                    autoFocus
                  />
                  {errorMessage && (
                    <p className="text-xs text-red-500 mt-1">{errorMessage}</p>
                  )}
                  <div className="flex justify-end mt-2 space-x-2">
                    <div className="flex-grow text-left">
                      <button
                        onClick={() => deleteFieldValue("phone")}
                        className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                        disabled={isSaving}
                        title="Remove phone number"
                      >
                        <TrashIcon className="h-3 w-3" />
                      </button>
                    </div>
                    <button
                      onClick={cancelEditing}
                      className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
                      disabled={isSaving}
                    >
                      <XMarkIcon className="h-3 w-3 mr-1" />
                      Cancel
                    </button>
                    <button
                      onClick={() => saveField("phone")}
                      className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
                      disabled={isSaving}
                    >
                      {isSaving ? (
                        <span>Saving...</span>
                      ) : (
                        <>
                          <CheckIcon className="h-3 w-3 mr-1" />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <p className="mt-1 text-base">
                  {customer?.phoneNumber
                    ? formatPhoneForDisplay(customer.phoneNumber.phoneNumber)
                    : "—"}
                </p>
              )}
            </div>

            <div className="rounded-md bg-gray-50 p-3 group relative flex flex-col min-h-[100px]">
              <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
                <span className="flex items-center">
                  <MapPinIcon className="mr-2 h-4 w-4 text-martEye-500" />
                  Address
                </span>
                <div className="flex space-x-1">
                  <button
                    onClick={openAddressModal}
                    className="text-gray-400 hover:text-martEye-500 focus:outline-none"
                    title="Edit address"
                  >
                    <PencilIcon className="h-4 w-4" />
                  </button>
                  {customer?.address?.address?.address1 && (
                    <button
                      onClick={() =>
                        copyToClipboard(getFormattedAddress(), "address")
                      }
                      className="text-gray-400 hover:text-martEye-500 focus:outline-none"
                      title="Copy address"
                    >
                      {copiedField === "address" ? (
                        <ClipboardDocumentCheckIcon className="h-4 w-4 text-green-500" />
                      ) : (
                        <ClipboardDocumentIcon className="h-4 w-4" />
                      )}
                    </button>
                  )}
                </div>
              </h3>
              <div className="flex flex-col flex-grow justify-between">
                <a
                  href={getGoogleMapsUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-1 text-sm text-gray-600 hover:text-martEye-600 group"
                  title="Open in Google Maps"
                >
                  {customer?.address?.address?.company && (
                    <span className="block font-medium group-hover:underline">
                      {customer.address.address.company}
                    </span>
                  )}
                  <span className="block group-hover:underline">
                    {customer?.address?.address?.address1 || "—"}
                  </span>
                  {customer?.address?.address?.address2 && (
                    <span className="block group-hover:underline">
                      {customer.address.address.address2}
                    </span>
                  )}
                  <span className="block group-hover:underline">
                    {[
                      customer?.address?.address?.city,
                      customer?.address?.address?.province,
                      customer?.address?.address?.zip,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </span>
                </a>
                {marketDetails && distance !== null && (
                  <p className="text-xs text-gray-500 mt-1">
                    Distance from market: {formatDistance(distance)}
                  </p>
                )}
              </div>
            </div>

            {/* Add BidderNumberPicker */}
            <BidderNumberPicker customer={customer} />

            <div className="rounded-md bg-gray-50 p-3 group relative md:col-span-1">
              <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
                <span className="flex items-center">
                  <DocumentCurrencyPoundIcon className="mr-2 h-4 w-4 text-martEye-500" />
                  Payment Details
                </span>
                <div className="flex space-x-1">
                  {editingField !== "bankDetails" && (
                    <button
                      onClick={() => {
                        setEditingField("bankDetails");
                        setAccountNumberValue(
                          customer?.bankDetails?.accountNumber || ""
                        );
                        setSortCodeValue(
                          formatSortCode(customer?.bankDetails?.sortCode || "")
                        );
                        setAccountNameValue(
                          customer?.bankDetails?.accountName ||
                            getDisplayName(customer) ||
                            ""
                        );
                        if (customer?.bankDetails?.sortCode) {
                          detectBankFromSortCode(
                            formatSortCode(customer.bankDetails.sortCode)
                          );
                        }
                      }}
                      className="text-gray-400 hover:text-martEye-500 focus:outline-none"
                      title="Edit bank details"
                    >
                      <PencilIcon className="h-4 w-4" />
                    </button>
                  )}
                </div>
              </h3>

              {editingField === "bankDetails" ? (
                <div className="mt-2 space-y-3">
                  <div>
                    <label
                      htmlFor="accountName"
                      className="block text-xs text-gray-500 mb-1"
                    >
                      Account Name
                    </label>
                    <input
                      id="accountName"
                      type="text"
                      value={accountNameValue}
                      onChange={(e) => setAccountNameValue(e.target.value)}
                      className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
                      placeholder="Account holder name"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="accountNumber"
                      className="block text-xs text-gray-500 mb-1"
                    >
                      Account Number (8 digits)
                    </label>
                    <input
                      id="accountNumber"
                      type="text"
                      value={accountNumberValue}
                      onChange={handleAccountNumberInput}
                      className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
                      placeholder="12345678"
                      maxLength={8}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="sortCode"
                      className="block text-xs text-gray-500 mb-1"
                    >
                      Sort Code
                    </label>
                    <div className="relative">
                      <input
                        id="sortCode"
                        type="text"
                        value={sortCodeValue}
                        onChange={(e) => handleSortCodeInput(e)}
                        className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
                        placeholder="00-00-00"
                        maxLength={8} // Allow for two dashes
                      />
                      {bankLogo && (
                        <div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center">
                          <img
                            src={bankLogo}
                            alt={bankName}
                            className="h-4 w-4 mr-1"
                            onError={(e) =>
                              (e.currentTarget.style.display = "none")
                            }
                          />
                          <span className="text-xs text-gray-500">
                            {bankName}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  {errorMessage && (
                    <p className="text-xs text-red-500">{errorMessage}</p>
                  )}

                  <div className="flex justify-end mt-2 space-x-2">
                    <div className="flex-grow text-left">
                      <button
                        onClick={() => deleteFieldValue("bankDetails")}
                        className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                        disabled={isSaving}
                        title="Remove bank details"
                      >
                        <TrashIcon className="h-3 w-3" />
                      </button>
                    </div>
                    <button
                      onClick={cancelEditing}
                      className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
                      disabled={isSaving}
                    >
                      <XMarkIcon className="h-3 w-3 mr-1" />
                      Cancel
                    </button>
                    <button
                      onClick={() => saveField("bankDetails")}
                      className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
                      disabled={isSaving}
                    >
                      {isSaving ? (
                        <span>Saving...</span>
                      ) : (
                        <>
                          <CheckIcon className="h-3 w-3 mr-1" />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mt-1">
                  {customer?.bankDetails?.accountNumber ? (
                    <div className="flex flex-col">
                      <p className="text-sm text-gray-600 font-medium flex items-center">
                        <CheckCircleIcon className="h-4 w-4 mr-1 text-green-600" />
                        This person will be paid via BACS
                      </p>
                      <p className="text-xs text-gray-500 mt-1">
                        Bank details are stored securely and only visible when
                        editing
                      </p>
                    </div>
                  ) : (
                    <p className="text-sm text-gray-600">
                      No bank details provided. Add bank details to enable BACS
                      payments.
                    </p>
                  )}
                </div>
              )}
            </div>
            <div className="rounded-md bg-gray-50 p-3 group relative flex flex-col min-h-[100px]">
              <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
                <span className="flex items-center">
                  <DocumentCurrencyPoundIcon className="mr-2 h-4 w-4 text-martEye-500" />
                  VAT Number
                </span>
                <div className="flex space-x-1">
                  {editingField !== "vat" && customer?.vatNumber && (
                    <>
                      <button
                        onClick={() =>
                          copyToClipboard(customer?.vatNumber, "vat")
                        }
                        className="text-gray-400 hover:text-martEye-500 focus:outline-none"
                        title="Copy VAT number"
                      >
                        {copiedField === "vat" ? (
                          <ClipboardDocumentCheckIcon className="h-4 w-4 text-green-500" />
                        ) : (
                          <ClipboardDocumentIcon className="h-4 w-4" />
                        )}
                      </button>
                    </>
                  )}
                  <button
                    onClick={() => {
                      setEditingField("vat");
                      setVatValue(customer?.vatNumber || "");
                    }}
                    className="text-gray-400 hover:text-martEye-500 focus:outline-none ml-2"
                    title="Edit VAT number"
                  >
                    <PencilIcon className="h-4 w-4" />
                  </button>
                </div>
              </h3>

              {editingField === "vat" ? (
                <div className="mt-1">
                  <input
                    type="text"
                    value={vatValue}
                    onChange={(e) => setVatValue(e.target.value)}
                    onKeyDown={(e) => handleInputKeyDown(e, "vat")}
                    className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
                    placeholder="Enter VAT number"
                    autoFocus
                  />
                  {errorMessage && (
                    <p className="text-xs text-red-500 mt-1">{errorMessage}</p>
                  )}
                  <div className="flex justify-end mt-2 space-x-2">
                    <div className="flex-grow text-left">
                      <button
                        onClick={() => deleteFieldValue("vat")}
                        className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                        disabled={isSaving}
                        title="Remove VAT number"
                      >
                        <TrashIcon className="h-3 w-3" />
                      </button>
                    </div>
                    <button
                      onClick={cancelEditing}
                      className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
                      disabled={isSaving}
                    >
                      <XMarkIcon className="h-3 w-3 mr-1" />
                      Cancel
                    </button>
                    <button
                      onClick={() => saveField("vat")}
                      className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
                      disabled={isSaving}
                    >
                      {isSaving ? (
                        <span>Saving...</span>
                      ) : (
                        <>
                          <CheckIcon className="h-3 w-3 mr-1" />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col flex-grow justify-between">
                  <p className="mt-1 text-base">
                    {customer?.vatNumber || "Not registered"}
                  </p>
                  {customer?.vatNumber && (
                    <p className="text-xs text-gray-500 mt-1 flex items-center">
                      <CheckCircleIcon className="mr-1 h-3 w-3 text-green-500" />
                      VAT registered when invoicing
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Map Visualization - 1/3 width */}
          <div className="flex items-center justify-center md:col-span-1">
            {mapLoaded ? (
              <div className="h-full w-full min-h-[200px]">
                {customerCoords || marketCoords ? (
                  <div className="relative h-full w-full min-h-[200px] rounded-lg overflow-hidden">
                    <LeafletMap
                      customerLocation={mapData.customerLocation}
                      marketLocation={mapData.marketLocation}
                      center={mapData.center as [number, number]}
                      zoom={mapData.zoom}
                    />
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full w-full min-h-[200px] bg-gray-100 rounded-md">
                    <p className="text-gray-500 text-sm">No location data</p>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center h-full w-full min-h-[200px] bg-gray-100 rounded-md">
                <p className="text-gray-500 text-sm">Loading map...</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Keeper Details Section - Now Dynamic */}
      <div className="rounded-lg bg-white p-4 shadow">
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-lg font-medium text-martEye-700">
            Keeper Details
          </h2>
          <div className="relative">
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button
                className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100"
                title="Add keeper detail"
              >
                <PlusIcon className="h-5 w-5" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                  <div className="px-1 py-1">
                    {/* Only show Sheep Farm Assurance option if it doesn't already exist */}
                    {!shouldShowKeeperDetail("farmAssuranceSheep") && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={() =>
                              handleAddKeeperDetail("farmAssuranceSheep")
                            }
                          >
                            Add Sheep Farm Assurance
                          </button>
                        )}
                      </Menu.Item>
                    )}

                    {/* Only show Cattle Farm Assurance option if it doesn't already exist */}
                    {!shouldShowKeeperDetail("farmAssuranceCattle") && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={() =>
                              handleAddKeeperDetail("farmAssuranceCattle")
                            }
                          >
                            Add Cattle Farm Assurance
                          </button>
                        )}
                      </Menu.Item>
                    )}

                    {/* Only show Pigs Farm Assurance option if it doesn't already exist */}
                    {!shouldShowKeeperDetail("farmAssurancePigs") && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={() =>
                              handleAddKeeperDetail("farmAssurancePigs")
                            }
                          >
                            Add Pigs Farm Assurance
                          </button>
                        )}
                      </Menu.Item>
                    )}

                    {/* Only show Herd Number option if it doesn't already exist */}
                    {!shouldShowKeeperDetail("herdNumber") && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={() => handleAddKeeperDetail("herdNumber")}
                          >
                            Add Herd Number
                          </button>
                        )}
                      </Menu.Item>
                    )}

                    {/* Only show Flock Number option if it doesn't already exist */}
                    {!shouldShowKeeperDetail("flockNumber") && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={() => handleAddKeeperDetail("flockNumber")}
                          >
                            Add Flock Number
                          </button>
                        )}
                      </Menu.Item>
                    )}

                    {/* Only show CPH Number option if it doesn't already exist */}
                    {!shouldShowKeeperDetail("cphNumber") && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={() => handleAddKeeperDetail("cphNumber")}
                          >
                            Add CPH Number
                          </button>
                        )}
                      </Menu.Item>
                    )}

                    {/* Only show Vet Attestation option if it doesn't already exist */}
                    {!shouldShowKeeperDetail("vetAttestation") && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={() =>
                              handleAddKeeperDetail("vetAttestation")
                            }
                          >
                            Add Vet Attestation
                          </button>
                        )}
                      </Menu.Item>
                    )}

                    {/* Show a message if all fields are already added */}
                    {shouldShowKeeperDetail("farmAssuranceSheep") &&
                      shouldShowKeeperDetail("farmAssuranceCattle") &&
                      shouldShowKeeperDetail("farmAssurancePigs") &&
                      shouldShowKeeperDetail("herdNumber") &&
                      shouldShowKeeperDetail("flockNumber") &&
                      shouldShowKeeperDetail("cphNumber") &&
                      shouldShowKeeperDetail("vetAttestation") && (
                        <div className="text-gray-500 text-sm px-2 py-2 italic text-center">
                          All keeper details already added
                        </div>
                      )}

                    {/* Only show Slap Mark option if it doesn't already exist */}
                    {!shouldShowKeeperDetail("slapMark") && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={() => handleAddKeeperDetail("slapMark")}
                          >
                            Add Pig Slap Mark
                          </button>
                        )}
                      </Menu.Item>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        {/* Rest of the Keeper Details section */}
        {!hasAnyKeeperDetails() ? (
          <p className="text-sm text-gray-500 italic">
            No keeper details added yet. Click the + button to add details.
          </p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {shouldShowKeeperDetail("cphNumber") && (
              <CphNumberSection
                customer={customer}
                marketId={marketId}
                editingField={editingField}
                setEditingField={setEditingField}
                errorMessage={errorMessage}
                isSaving={isSaving}
                saveField={saveField}
                cancelEditing={cancelEditing}
                deleteFieldValue={deleteFieldValue}
              />
            )}

            {/* Put Movement Reporting right after CPH */}
            {shouldShowKeeperDetail("movementReporting") && (
              <MovementReportingSection
                customer={customer}
                marketId={marketId}
                editingField={editingField}
                setEditingField={setEditingField}
                errorMessage={errorMessage}
                isSaving={isSaving}
                saveField={saveField}
                cancelEditing={cancelEditing}
                deleteFieldValue={deleteFieldValue}
              />
            )}

            {shouldShowKeeperDetail("herdNumber") && (
              <HerdNumberSection
                customer={customer}
                marketId={marketId}
                editingField={editingField}
                setEditingField={setEditingField}
                errorMessage={errorMessage}
                isSaving={isSaving}
                saveField={saveField}
                cancelEditing={cancelEditing}
                deleteFieldValue={deleteFieldValue}
              />
            )}

            {shouldShowKeeperDetail("flockNumber") && (
              <FlockNumberSection
                customer={customer}
                marketId={marketId}
                editingField={editingField}
                setEditingField={setEditingField}
                errorMessage={errorMessage}
                isSaving={isSaving}
                saveField={saveField}
                cancelEditing={cancelEditing}
                deleteFieldValue={deleteFieldValue}
              />
            )}

            {shouldShowKeeperDetail("farmAssuranceSheep") && (
              <FarmAssuranceSection
                key="Sheep"
                customer={customer}
                marketId={marketId}
                species="Sheep"
                editingField={editingField}
                setEditingField={setEditingField}
                errorMessage={errorMessage}
                isSaving={isSaving}
                saveField={saveField}
                cancelEditing={cancelEditing}
                deleteFieldValue={deleteFieldValue}
              />
            )}

            {shouldShowKeeperDetail("farmAssuranceCattle") && (
              <FarmAssuranceSection
                key="Cattle"
                customer={customer}
                marketId={marketId}
                species="Cattle"
                editingField={editingField}
                setEditingField={setEditingField}
                errorMessage={errorMessage}
                isSaving={isSaving}
                saveField={saveField}
                cancelEditing={cancelEditing}
                deleteFieldValue={deleteFieldValue}
              />
            )}

            {shouldShowKeeperDetail("farmAssurancePigs") && (
              <FarmAssuranceSection
                key="Pigs"
                customer={customer}
                marketId={marketId}
                species="Pigs"
                editingField={editingField}
                setEditingField={setEditingField}
                errorMessage={errorMessage}
                isSaving={isSaving}
                saveField={saveField}
                cancelEditing={cancelEditing}
                deleteFieldValue={deleteFieldValue}
              />
            )}

            {shouldShowKeeperDetail("vetAttestation") && (
              <VetAttestationSection
                customer={customer}
                marketId={marketId}
                editingField={editingField}
                setEditingField={setEditingField}
                errorMessage={errorMessage}
                isSaving={isSaving}
                saveField={saveField}
                cancelEditing={cancelEditing}
                deleteFieldValue={deleteFieldValue}
              />
            )}

            {shouldShowKeeperDetail("slapMark") && (
              <SlapMarkSection
                customer={customer}
                marketId={marketId}
                editingField={editingField}
                setEditingField={setEditingField}
                errorMessage={errorMessage}
                isSaving={isSaving}
                saveField={saveField}
                cancelEditing={cancelEditing}
                deleteFieldValue={deleteFieldValue}
              />
            )}
          </div>
        )}
      </div>

      {/* Invoices Section */}
      <div className="rounded-lg bg-white p-4 shadow">
        <h2 className="mb-4 text-lg font-medium text-martEye-700">
          Recent Invoices
        </h2>
        {invoicesLoadInfo.loading ? (
          <div className="py-4 text-center text-gray-500">
            Loading invoices...
          </div>
        ) : invoicesLoadInfo.data && invoicesLoadInfo.data.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Invoice #
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Sale Type
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Qty
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {invoicesLoadInfo.data.map((invoice) => (
                  <tr
                    key={invoice.id}
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => openInvoiceSheet({ invoiceId: invoice.id })}
                  >
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div className="text-martEye-600 flex items-center underline">
                        <DocumentIcon className="h-4 w-4 mr-1" />
                        {invoice.invoiceNumber}
                      </div>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {invoice.issuedAt
                        ? format(invoice.issuedAt.toDate(), "PP")
                        : "—"}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {invoice.superTypes || "—"}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {invoice.lineItems.reduce(
                        (acc, item) => acc + item.quantity,
                        0
                      )}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium">
                      {new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: invoice.currency,
                      }).format(invoice.finalTotalInCents / 100)}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <span
                        className={classNames(
                          "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
                          getStatusColor(invoice.status)
                        )}
                      >
                        {invoice.status?.toUpperCase() || "—"}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="py-4 text-center text-gray-500">
            No invoices found
          </div>
        )}
      </div>

      {/* Buying and Selling Patterns */}
      {false && (
        <div className="rounded-lg bg-white p-4 shadow">
          <h2 className="mb-4 text-lg font-medium text-martEye-700">
            Activity Summary
          </h2>

          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            {/* Buying Patterns */}
            <div className="rounded-md bg-green-50 p-4">
              <h3 className="mb-3 font-medium text-green-800">
                Buying Activity
              </h3>
              {boughtLotsInfo.loading ? (
                <div className="py-2 text-center text-gray-500 text-sm">
                  Loading...
                </div>
              ) : boughtLots && boughtLots.length > 0 ? (
                <div className="space-y-3">
                  <div className="flex justify-between border-b border-green-200 pb-1">
                    <span className="text-sm text-green-700">
                      Total Lots Bought
                    </span>
                    <span className="font-medium text-green-800">
                      {boughtLots.length}
                    </span>
                  </div>
                  <div className="max-h-60 overflow-y-auto">
                    {boughtLots.slice(0, 4).map((lot) => (
                      <div
                        key={lot.id}
                        className="border-b border-green-100 py-2"
                      >
                        <div className="text-sm font-medium text-green-800">
                          {lot.description || lot.name || "Lot"}
                        </div>
                        <div className="flex justify-between items-center mt-1">
                          <span className="text-xs text-green-600">
                            {lot.createdAt
                              ? formatRelativeDate(lot.createdAt.toDate())
                              : ""}
                          </span>
                          <span className="text-sm font-medium">
                            {lot.hammerPriceInCents
                              ? formatCurrency(lot.hammerPriceInCents / 100)
                              : "—"}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="space-y-3">
                  <p className="text-sm text-gray-500 mb-2">
                    No buying activity recorded. Example data shown:
                  </p>
                  <div className="max-h-60 overflow-y-auto">
                    {exampleBuyingActivity.map((activity) => (
                      <div
                        key={activity.id}
                        className="border-b border-green-100 py-2"
                      >
                        <div className="text-sm font-medium text-green-800">
                          {activity.description}
                        </div>
                        <div className="flex justify-between items-center mt-1">
                          <span className="text-xs text-green-600">
                            {formatRelativeDate(activity.date)}
                          </span>
                          <span className="text-sm font-medium">
                            {formatCurrency(activity.amount)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Selling Patterns */}
            <div className="rounded-md bg-blue-50 p-4">
              <h3 className="mb-3 font-medium text-blue-800">
                Selling Activity
              </h3>
              {soldLotsInfo.loading ? (
                <div className="py-2 text-center text-gray-500 text-sm">
                  Loading...
                </div>
              ) : soldLots && soldLots.length > 0 ? (
                <div className="space-y-3">
                  <div className="flex justify-between border-b border-blue-200 pb-1">
                    <span className="text-sm text-blue-700">
                      Total Lots Sold
                    </span>
                    <span className="font-medium text-blue-800">
                      {soldLots.length}
                    </span>
                  </div>
                  <div className="max-h-60 overflow-y-auto">
                    {soldLots.slice(0, 4).map((lot) => (
                      <div
                        key={lot.id}
                        className="border-b border-blue-100 py-2"
                      >
                        <div className="text-sm font-medium text-blue-800">
                          {lot.description || lot.name || "Lot"}
                        </div>
                        <div className="flex justify-between items-center mt-1">
                          <span className="text-xs text-blue-600">
                            {lot.createdAt
                              ? formatRelativeDate(lot.createdAt.toDate())
                              : ""}
                          </span>
                          <span className="text-sm font-medium">
                            {lot.hammerPriceInCents
                              ? formatCurrency(lot.hammerPriceInCents / 100)
                              : "—"}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="space-y-3">
                  <p className="text-sm text-gray-500 mb-2">
                    No selling activity recorded. Example data shown:
                  </p>
                  <div className="max-h-60 overflow-y-auto">
                    {exampleSellingActivity.map((activity) => (
                      <div
                        key={activity.id}
                        className="border-b border-blue-100 py-2"
                      >
                        <div className="text-sm font-medium text-blue-800">
                          {activity.description}
                        </div>
                        <div className="flex justify-between items-center mt-1">
                          <span className="text-xs text-blue-600">
                            {formatRelativeDate(activity.date)}
                          </span>
                          <span className="text-sm font-medium">
                            {formatCurrency(activity.amount)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Activity Timeline */}
      {false && (
        <div className="rounded-lg bg-white p-4 shadow">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-medium text-martEye-700">
              Activity Timeline
            </h2>
            <button
              onClick={handleMaximizeTimeline}
              className="p-1 text-gray-500 hover:text-gray-700 rounded-md hover:bg-gray-100 transition-colors"
              aria-label="Maximize timeline"
            >
              <ArrowsPointingOutIcon className="h-5 w-5" />
            </button>
          </div>
          <ActivityTimeline
            ref={timelineRef}
            items={timelineItems}
            formatCurrency={formatCurrency}
          />
        </div>
      )}

      {/* Add the AddressEditorModal at the end */}
      <AddressEditorModal
        open={isAddressModalOpen}
        onClose={() => setIsAddressModalOpen(false)}
        customerId={customerUid || ""}
        address={customer?.address || null}
        isPrimaryAddress={true}
      />

      {/* Notes Section */}
      <div className="rounded-lg bg-white p-4 shadow">
        <div className="bg-gray-50 p-3 rounded-md">
          <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
            <span className="flex items-center">
              <ChatBubbleLeftRightIcon className="mr-2 h-4 w-4 text-martEye-500" />
              Customer Notes
            </span>
            <div className="flex space-x-1">
              {editingField !== "notes" && (
                <button
                  onClick={() => setEditingField("notes")}
                  className="text-gray-400 hover:text-martEye-500 focus:outline-none"
                  title="Edit notes"
                >
                  <PencilIcon className="h-4 w-4" />
                </button>
              )}
            </div>
          </h3>

          {editingField === "notes" ? (
            <div className="mt-2 space-y-3">
              <div>
                <textarea
                  id="customerNotes"
                  value={notesValue}
                  onChange={handleNotesInput}
                  onKeyDown={handleNotesKeyDown}
                  className="w-full px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-martEye-500"
                  placeholder="Enter notes about this customer"
                  rows={5}
                  autoFocus
                />
                <p className="text-xs text-gray-500 mt-1">
                  Press Ctrl+Enter to save
                </p>
              </div>

              {errorMessage && (
                <p className="text-xs text-red-500 mt-1">{errorMessage}</p>
              )}

              <div className="flex justify-end mt-2 space-x-2">
                <div className="flex-grow text-left">
                  <button
                    onClick={() => {
                      setNotesValue("");
                      saveField("notes");
                    }}
                    className="text-gray-500 hover:text-red-600 text-xs px-2 py-1 rounded-md flex items-center"
                    disabled={isSaving}
                    title="Clear notes"
                  >
                    <TrashIcon className="h-3 w-3" />
                  </button>
                </div>
                <button
                  onClick={cancelEditing}
                  className="text-gray-500 hover:text-gray-700 text-xs px-2 py-1 rounded-md flex items-center"
                  disabled={isSaving}
                >
                  <XMarkIcon className="h-3 w-3 mr-1" />
                  Cancel
                </button>
                <button
                  onClick={() => saveField("notes")}
                  className="bg-martEye-500 text-white text-xs px-2 py-1 rounded-md flex items-center"
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <span>Saving...</span>
                  ) : (
                    <>
                      <CheckIcon className="h-3 w-3 mr-1" />
                      Save
                    </>
                  )}
                </button>
              </div>
            </div>
          ) : (
            <div className="mt-1">
              {notesValue ? (
                <div className="text-sm whitespace-pre-wrap">{notesValue}</div>
              ) : (
                <p className="text-sm text-gray-600 italic">
                  No notes added yet.
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
