import jsPDF from "jspdf";
import { DraftInvoice, Invoice } from "types";
import { PTSCONVERSION } from "../../../utils";

interface FooterProps {
  output: boolean;
  margin: number;
  textFillColor: string;
  legalText: string;
}

export default function footer(
  doc: jsPDF,
  invoice: Invoice | DraftInvoice,
  config: FooterProps
) {
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageCount = doc.getNumberOfPages();

  // Want to calculate the height of the legal text
  const legalHeight = legalTextHeight(doc, invoice, {
    ...config,
    output: false,
  });

  const safeZone = footerLineBreak(
    doc,
    {
      ...config,
      output: false,
    },
    legalHeight
  );

  if (!config.output) {
    return safeZone;
  }

  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);

    pageNumbers(doc, config, legalHeight);

    legalTextHeight(doc, invoice, config);

    footerLineBreak(doc, config, legalHeight);
  }
}

function legalTextHeight(
  doc: jsPDF,
  invoice: Invoice | DraftInvoice,
  config: FooterProps
) {
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.getWidth();

  const pageNumberWidth = doc.getTextWidth("Page 100 of 1000");

  // Set the total tags read and total tags to the page
  doc.setFontSize(8);
  doc.setFont("Inter", "normal");

  const legalTextLines = doc.splitTextToSize(
    config.legalText,
    pageWidth - config.margin * 3 - pageNumberWidth
  );

  doc.setTextColor(config.textFillColor);

  const legalTextHeight =
    doc.getLineHeight() * legalTextLines.length * PTSCONVERSION;

  if (config.output) {
    doc.text(
      legalTextLines,
      config.margin,
      pageHeight - config.margin / 2 - legalTextHeight,
      {
        align: "left",
        baseline: "top",
      }
    );
  }

  return legalTextHeight;
}

function pageNumbers(doc: jsPDF, config: FooterProps, legalTextHeight: number) {
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageCount = doc.getNumberOfPages();
  const currentPage = doc.getCurrentPageInfo().pageNumber;
  // Set Page Number to the current page
  doc.setFont("Inter", "normal");
  doc.setFontSize(8);
  doc.text(
    "Page " + String(currentPage) + " of " + String(pageCount),
    pageWidth - config.margin,
    pageHeight - config.margin / 2 - legalTextHeight,
    {
      align: "right",
      baseline: "top",
      maxWidth: pageWidth - config.margin * 2,
    }
  );
}

function footerLineBreak(
  doc: jsPDF,
  config: FooterProps,
  legalTextHeight: number
) {
  // If we are not outputting the document, then we don't need to add the line
  const linePosition =
    doc.internal.pageSize.getHeight() - config.margin / 2 - 5 - legalTextHeight;
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Now safe zone for the bottom of each page is the line position - 5
  const safeZone = linePosition - 5;
  doc.mainFooterPosition = pageHeight - safeZone;

  if (!config.output) {
    return safeZone;
  }

  // Now add a line to separate the footer from the content
  doc.setLineWidth(0.5);
  doc.setDrawColor(config.textFillColor);
  doc.line(
    config.margin,
    linePosition,
    pageWidth - config.margin,
    linePosition
  );
}
