import { Tooltip } from "@/components/Tootip";
import {
  ArrowsPointingInIcon,
  BanknotesIcon,
  CreditCardIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  PhoneIcon,
  ReceiptRefundIcon,
  ShoppingBagIcon,
  TagIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { format, formatDistanceToNow } from "date-fns";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

export interface TimelineItem {
  id: string;
  type:
    | "account_created"
    | "email_updated"
    | "phone_updated"
    | "lot_sold"
    | "lot_purchased"
    | "payment_received"
    | "payout_sent"
    | "invoice_voided"
    | "credit_note"
    | "lots_sold_group"
    | "lots_purchased_group";
  date: Date;
  title: string;
  subtitle?: string;
  description?: string;
  amount?: number;
  oldValue?: string;
  newValue?: string;
  updatedBy?: string;
  count?: number;
  items?: Array<{
    name?: string;
    description?: string;
    amount?: number;
    category?: string;
  }>;
}

// Define the ref interface
export interface TimelineRef {
  maximize: () => void;
  minimize: () => void;
  isMaximized: boolean;
}

interface ActivityTimelineProps {
  items: TimelineItem[];
  formatCurrency: (amount: number) => string | null;
  onMaximize?: () => void; // Optional callback for parent to handle maximize UI
}

const ActivityTimeline = forwardRef<TimelineRef, ActivityTimelineProps>(
  function ActivityTimeline({ items, formatCurrency, onMaximize }, ref) {
    const sortedItems = [...items].sort(
      (a, b) => b.date.getTime() - a.date.getTime()
    );
    const [isMaximized, setIsMaximized] = useState(false);
    const timelineRef = useRef<HTMLDivElement>(null);
    const [timelineRect, setTimelineRect] = useState<DOMRect | null>(null);

    // Capture the position and dimensions of the timeline when maximizing
    const handleMaximize = () => {
      if (timelineRef.current && !isMaximized) {
        setTimelineRect(timelineRef.current.getBoundingClientRect());
      }
      setIsMaximized(true);

      // Call parent's onMaximize if provided
      if (onMaximize) {
        onMaximize();
      }
    };

    const handleMinimize = () => {
      setIsMaximized(false);
    };

    // Expose maximize/minimize functions for external use
    useImperativeHandle(
      ref,
      () => ({
        maximize: handleMaximize,
        minimize: handleMinimize,
        isMaximized,
      }),
      [isMaximized]
    );

    // Close modal when clicking outside
    const handleBackdropClick = (e: React.MouseEvent) => {
      if (e.target === e.currentTarget) {
        handleMinimize();
      }
    };

    // Prevent body scrolling when modal is open
    useEffect(() => {
      if (isMaximized) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }

      return () => {
        document.body.style.overflow = "";
      };
    }, [isMaximized]);

    const formatRelativeDate = (date: Date) => {
      return formatDistanceToNow(date, { addSuffix: true });
    };

    const getIconForType = (type: TimelineItem["type"]) => {
      switch (type) {
        case "account_created":
          return <UserIcon className="h-5 w-5 text-martEye-500" />;
        case "email_updated":
          return <EnvelopeIcon className="h-5 w-5 text-blue-500" />;
        case "phone_updated":
          return <PhoneIcon className="h-5 w-5 text-blue-500" />;
        case "lot_sold":
          return <TagIcon className="h-5 w-5 text-green-500" />;
        case "lots_sold_group":
          return <TagIcon className="h-5 w-5 text-green-500" />;
        case "lot_purchased":
          return <ShoppingBagIcon className="h-5 w-5 text-yellow-500" />;
        case "lots_purchased_group":
          return <ShoppingBagIcon className="h-5 w-5 text-yellow-500" />;
        case "payment_received":
          return <BanknotesIcon className="h-5 w-5 text-purple-500" />;
        case "payout_sent":
          return <CreditCardIcon className="h-5 w-5 text-indigo-500" />;
        case "invoice_voided":
          return <DocumentTextIcon className="h-5 w-5 text-red-500" />;
        case "credit_note":
          return <ReceiptRefundIcon className="h-5 w-5 text-orange-500" />;
        default:
          return <UserIcon className="h-5 w-5 text-gray-500" />;
      }
    };

    const getSmallIconForType = (type: TimelineItem["type"]) => {
      switch (type) {
        case "account_created":
          return <UserIcon className="h-3 w-3 text-martEye-500" />;
        case "email_updated":
          return <EnvelopeIcon className="h-3 w-3 text-blue-500" />;
        case "phone_updated":
          return <PhoneIcon className="h-3 w-3 text-blue-500" />;
        case "lot_sold":
          return <TagIcon className="h-3 w-3 text-green-500" />;
        case "lots_sold_group":
          return <TagIcon className="h-3 w-3 text-green-500" />;
        case "lot_purchased":
          return <ShoppingBagIcon className="h-3 w-3 text-yellow-500" />;
        case "lots_purchased_group":
          return <ShoppingBagIcon className="h-3 w-3 text-yellow-500" />;
        case "payment_received":
          return <BanknotesIcon className="h-3 w-3 text-purple-500" />;
        case "payout_sent":
          return <CreditCardIcon className="h-3 w-3 text-indigo-500" />;
        case "invoice_voided":
          return <DocumentTextIcon className="h-3 w-3 text-red-500" />;
        case "credit_note":
          return <ReceiptRefundIcon className="h-3 w-3 text-orange-500" />;
        default:
          return <UserIcon className="h-3 w-3 text-gray-500" />;
      }
    };

    // Format currency safely
    const safeFormatCurrency = (amount: number): string => {
      const formatted = formatCurrency(amount);
      return formatted || `£${amount.toFixed(2)}`;
    };

    // Render grouped content differently
    const renderGroupedContent = (item: TimelineItem) => {
      // Calculate categories if items is provided
      let categories: Record<string, number> = {};
      let totalAmount = 0;

      if (item.items && item.items.length > 0) {
        item.items.forEach((lotItem) => {
          if (lotItem.category) {
            categories[lotItem.category] =
              (categories[lotItem.category] || 0) + 1;
          }
          if (lotItem.amount) {
            totalAmount += lotItem.amount;
          }
        });
      }

      // Use provided amount if available, otherwise use calculated total
      const displayAmount =
        item.amount !== undefined ? item.amount : totalAmount;

      // Create category summary text
      const categoryText = Object.entries(categories)
        .map(([category, count]) => `${count} ${category}`)
        .join(", ");

      return (
        <>
          <div className="text-sm font-medium">{item.title}</div>
          <Tooltip text={format(item.date, "PPP p")}>
            <div className="text-xs text-gray-500 cursor-help">
              {formatRelativeDate(item.date)}
            </div>
          </Tooltip>

          <div className="text-xs text-gray-600 mt-0.5">
            <div className="font-medium">
              {item.count} {item.count === 1 ? "Lot" : "Lots"}{" "}
              {item.type.includes("sold") ? "Sold" : "Purchased"}
            </div>
            {categoryText && <div>{categoryText}</div>}
            {displayAmount !== 0 && (
              <div className="font-medium text-gray-700 mt-1">
                {safeFormatCurrency(displayAmount)}
              </div>
            )}
          </div>

          {/* Show detailed items if available and we're in maximized view */}
          {isMaximized && item.items && item.items.length > 0 && (
            <div className="mt-2 border-t border-gray-100 pt-2">
              <div className="text-xs font-medium text-gray-600 mb-1">
                Items:
              </div>
              <div className="space-y-1 max-h-40 overflow-y-auto pr-2">
                {item.items.map((lotItem, idx) => (
                  <div
                    key={idx}
                    className="text-xs text-gray-600 flex justify-between"
                  >
                    <div>
                      {lotItem.name || lotItem.description || `Item ${idx + 1}`}
                    </div>
                    {lotItem.amount !== undefined && (
                      <div>{safeFormatCurrency(lotItem.amount)}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      );
    };

    // Timeline content to be reused in both normal and maximized views
    const TimelineContent = () => (
      <div className="relative min-h-full pb-4">
        {/* Vertical timeline line */}
        <div className="absolute left-1/2 top-0 bottom-0 w-0.5 bg-gray-200 -ml-px z-0"></div>

        {/* Timeline items */}
        <div className="relative">
          {sortedItems.map((item, index) => {
            const isLeft = index % 2 === 0; // Alternate left/right

            return (
              <div key={item.id} className="relative pb-8">
                {/* Timeline dot */}
                <div className="absolute left-1/2 -translate-x-1/2 mt-1.5 flex items-center justify-center w-5 h-5 rounded-full border-2 border-white bg-gray-100 z-10">
                  {getSmallIconForType(item.type)}
                </div>

                {/* Card - alternates between left and right */}
                <div
                  className={`relative ${
                    isLeft
                      ? "mr-[calc(50%+20px)] pr-6"
                      : "ml-[calc(50%+20px)] pl-6"
                  }`}
                >
                  {/* Connector line */}
                  <div
                    className={`absolute top-4 ${
                      isLeft ? "right-0 w-6" : "left-0 w-6"
                    } h-px bg-gray-200`}
                    style={{ [isLeft ? "right" : "left"]: 0 }}
                  ></div>

                  {/* Card content */}
                  <div className="bg-gray-50 border border-gray-100 rounded-lg p-3 shadow-sm">
                    <div className="flex items-start">
                      <div className="mr-2 flex-shrink-0">
                        {getIconForType(item.type)}
                      </div>
                      <div className="min-w-0 flex-1">
                        {/* Render grouped items differently */}
                        {item.type === "lots_sold_group" ||
                        item.type === "lots_purchased_group" ? (
                          renderGroupedContent(item)
                        ) : (
                          <>
                            <div className="text-sm font-medium">
                              {item.title}
                            </div>
                            <Tooltip text={format(item.date, "PPP p")}>
                              <div className="text-xs text-gray-500 cursor-help">
                                {formatRelativeDate(item.date)}
                              </div>
                            </Tooltip>

                            {item.subtitle && (
                              <div className="text-xs text-gray-600 mt-0.5">
                                {item.subtitle}
                              </div>
                            )}

                            {item.amount !== undefined && (
                              <div className="text-xs font-medium text-gray-600">
                                {safeFormatCurrency(item.amount)}
                              </div>
                            )}

                            {(item.oldValue || item.newValue) && (
                              <div className="mt-1 text-xs text-gray-600">
                                {item.newValue && (
                                  <div>
                                    Changed to:{" "}
                                    <span className="font-medium">
                                      {item.newValue}
                                    </span>
                                  </div>
                                )}
                                {item.oldValue && (
                                  <div>
                                    Changed from:{" "}
                                    <span className="font-medium">
                                      {item.oldValue}
                                    </span>
                                  </div>
                                )}
                                {item.updatedBy && (
                                  <div className="mt-1 text-gray-500 italic">
                                    Updated by {item.updatedBy}
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );

    // Normal (minimized) view
    if (!isMaximized) {
      return (
        <div className="px-4 py-2 relative" ref={timelineRef}>
          {/* Added max-height and scrollable container */}
          <div className="relative w-full max-h-[400px] overflow-y-auto pr-2">
            <TimelineContent />
          </div>
        </div>
      );
    }

    // Maximized view (modal)
    return (
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-[9999] flex items-center justify-center p-4 transition-opacity duration-300 ease-in-out"
        onClick={handleBackdropClick}
      >
        <div
          className="bg-white rounded-lg shadow-xl overflow-hidden flex flex-col transition-all duration-300 ease-in-out z-[10000]"
          style={{
            width: timelineRect ? timelineRect.width : "auto",
            maxHeight: "90vh",
            transform: "scale(1)",
            opacity: 1,
          }}
        >
          {/* Modal header */}
          <div className="p-4 border-b border-gray-200 flex justify-between items-center">
            <h3 className="text-lg font-medium text-martEye-700">
              Activity Timeline
            </h3>
            <button
              onClick={handleMinimize}
              className="p-1 text-gray-500 hover:text-gray-700 rounded-md hover:bg-gray-100 transition-colors"
              aria-label="Minimize timeline"
            >
              <ArrowsPointingInIcon className="h-5 w-5" />
            </button>
          </div>

          {/* Modal body with timeline */}
          <div className="flex-1 overflow-y-auto p-4">
            <TimelineContent />
          </div>
        </div>
      </div>
    );
  }
);

export default ActivityTimeline;
